import HelperClass from "../../CustomHook/HelperClass";

export default function DcEnergyMeterReport({ loading, get_data, nextPage,request }){
    return (
        <>
        <div className="ms-1 me-1" id="dc_energy_history">
                <div className="table-responsive bg-white">
                    <table width="100%" border={1} cellPadding={0} cellSpacing={0} className="table table-bordered mt-2 text-center">
                        <thead className="text-center">
                            <tr>
                                <th height={20} colSpan={20} align="center" valign="center" className="col" style={{ fontSize: '18px', fontWeight: 'bold' }}> DC Energy Meter Report
                                </th>
                            </tr>
                            <tr>
                                <th height={20} colSpan={20} align="center" valign="center" className="col" style={{ fontSize: '13px', fontWeight: 'bold' }}> Date: {request?.duration_type==1?request?.start_date:`${request?.start_date} To ${request?.end_date}`}
                                </th>
                            </tr>
                           
                            <tr>
            <th align="center" valign="center" class="col1" >Datetime</th>
            <th align="center" valign="center" class="col1" >{HelperClass().CUSTOMER_SITE} Site ID</th>
            <th align="center" valign="center" class="col1" >Site ID</th>
            <th align="center" valign="center" class="col1" >Site Name</th>
            <th align="center" valign="center" class="col1" >Cluster</th>
            <th align="center" valign="center" class="col1" >Zone</th>
            <th align="center" valign="center" class="col1" >Tenant Name</th>
            <th align="center" valign="center" class="col1" >CC(A)</th>
            <th align="center" valign="center" class="col1" >NC(A)</th>
            <th align="center" valign="center" class="col1" >Current(A)</th>
            <th align="center" valign="center" class="col1" >Voltage(V)</th>
            <th align="center" valign="center" class="col1" >kW</th>
            <th align="center" valign="center" class="col1" >kWh</th>
            <th align="center" valign="center" class="col1" >Temperature(°C)</th>

                                
            </tr>
                        </thead>

                        <tbody>
                        {!loading && get_data?.data?.length>0 && get_data?.data?.map((site, index) => (
                            <tr>
                                <td><p className="p-0 m-0" style={{width:'100px'}}>{site.date}</p></td>
                                <td>{site.easi_site_id}</td>
                                <td>{site.site_id}</td>
                                <td>{site.site_name}</td>
                                <td>{site.cluster_name}</td>
                                <td>{site.zone_name}</td>
                                <td>{site.tenant_name}</td>
                                <td>{site.acur}</td>
                                <td>{site.bcur}</td>
                                <td>{site.current}</td>
                                <td>{site.avlt}</td>
                                <td>{site.pw}</td>
                                <td>{site.kwh}</td>
                                <td>{site.temperature}</td>
                   


                            </tr>
                             ))}
                        </tbody>
                    </table>
                </div>
                {get_data?.has_page ?
                        <div class="float-md-end mb-1 mt-1">
                            <div class="btn-group ">
                                {get_data?.first_page ?
                                    <a class="btn btn-primary btn-sm" style={{ cursor: 'no-drop' }}>Prev</a>
                                    :
                                    <a class="btn btn-primary btn-sm " onClick={() => nextPage(get_data?.prv_page_url)}>Prev</a>
                                }

                                {get_data?.has_more_page ?
                                    <a class="btn btn-info btn-sm " onClick={() => nextPage(get_data?.next_page_url)}>Next</a>
                                    :
                                    <a class="btn btn-info btn-sm " style={{ cursor: 'no-drop' }}>Next</a>

                                }
                            </div>
                        </div> : ''
                    }
            </div>
        </>
    )
}