import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import Paginate from "../CustomHook/Paginate";
import { RMS_STATION_FETCH_ERROR, RMS_STATION_FETCH_SUCCESS } from "../Reducer/RmsStationSlice";
import API from "../Network/API";
import RmsStationSearch from "../Component/Rms/RmsStationSearch";
import ContentLoader from "react-content-loader";

export default function RmsStationTable() {
  const [nxtPage, setNextPage] = useState(null);
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.RmsStationReducer)

  const paginate = (pageNumber) => {
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    API.get('/v1/oauth/rms_station?page=' + pageNumber).then((response) => {
      if (loaderElement && !loaderElement.hasAttribute('hidden')) {
        loaderElement.setAttribute('hidden', 'true');
      }
      dispatch(RMS_STATION_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
      dispatch(RMS_STATION_FETCH_ERROR(error))
    });
  };

  useEffect(() => {
    if (nxtPage != null) {
      const loaderElement = document.querySelector(".loading-mask");
      loaderElement.removeAttribute('hidden');
      loaderElement.setAttribute('visible', 'true');
      API.get(nxtPage).then((response) => {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
        dispatch(RMS_STATION_FETCH_SUCCESS(response.data.data))

      }).catch((error) => {
        dispatch(RMS_STATION_FETCH_ERROR(error))
      });
    }

  }, [nxtPage]);

  const previousPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const nextPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const search = (site_code) => {
    API.get('/v1/oauth/rms_station?site_id=' + site_code).then((response) => {
      dispatch(RMS_STATION_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
      dispatch(RMS_STATION_FETCH_ERROR(error))
    });
  }
  return (
    <>
      <RmsStationSearch search={search} />
      
      <table className="table  m-0 table-sm table-custom dcEnergy text-center font-weight-bold bg-white table-striped table-sticky">
        <thead className="text-uppercase table-sticky-thead">
          <tr>
            <th width="10%" className="toi-primary col1">Updated</th>
            <th width="15%" className="text-center toi-primary col2">SITE ID</th>
            <th width="15%" className="text-center toi-primary col3">Easi SITE ID</th>
            <th width="8%" className="text-center toi-primary col4">Network</th>
            <th width="8%" className="text-center toi-primary col5">Door Open</th>
            <th width="8%" className="text-center toi-primary col6">Module Fault</th>
            <th width="8%" className="text-center toi-primary col7">Mains Fail</th>
            <th width="8%" className="text-center toi-primary col8">MDB Fault</th>
            <th width="8%" className="text-center toi-primary col9">Phase Fault</th>
            <th width="8%" className="text-center toi-primary col10">DC Low</th>
            <th className="text-center toi-primary col11">LLVD</th>
            <th width="8%" className="text-center toi-primary col12" title="High Temperature">ODC Temp</th>
            <th className="text-center toi-primary col13">Fan Fault</th>
            <th className="text-center toi-primary col14">Smoke</th>
            <th className="text-center toi-primary col15">Water Leakage</th>
            <th className="text-center toi-primary col16">Aviation Light </th>
            <th className="text-center toi-primary col17">Fuse Fault</th>
            <th className="text-center toi-primary col18">PG</th>
          </tr>
        </thead>
        <tbody>
        

          {!selector?.loading && selector?.data?.sites?.map((site, index) => (
            <tr>

              <td style={{ fontSize: '9px' }} className={`col1`}>
                {site.update_time}                              </td>
              <td className={`text-center col2`} width="15%">
                <a className="text-success font-bold" href="#">{site.site_id}</a>
              </td>
              <td className={`text-center col3`} width="15%">
                <a href="#"> {site.customer_site_id}</a>
              </td>
              <td className={`text-center col4`}>
                {site.device_live_status === 1 ?
                  <div className="signal-bars mt1 sizing-box good four-bars">
                    <span>
                      <img src="/assets/icon/6.svg" className="tower-height" alt="" />
                    </span>
                    <div className={`first-bar bar ${site.network_val >= 1 ? '' : 'null-color'}`}>
                    </div>
                    <div className={`second-bar bar ${site.network_val >= 2 ? '' : 'null-color'}`}>
                    </div>
                    <div className={`third-bar bar ${site.network_val >= 3 ? '' : 'null-color'}`}>
                    </div>
                    <div className={`fourth-bar bar ${site.network_val >= 4 ? '' : 'null-color'}`}>
                    </div>
                    <div className={`fifth-bar bar ${site.network_val >= 5 ? '' : 'null-color'}`}>
                    </div>
                  </div>
                  :
                  <i class="fa-solid fa-wifi-slash offline-rms-icon" title="Site not updating" />
                }
              </td>
              <td className={`text-center col5`}>
                <div className={`alarm_icon ${site.device_live_status === 1 ? `${site.door_open === 1 ? 'A3' : 'A1'}` : 'A4'} `}>
                  &nbsp;
                </div>
              </td>
              <td className={`text-center col6`}>
                <div className={`alarm_icon ${site.device_live_status === 1 ? `${site.rectifier_fault === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
              <td className={`text-center col7`}>
                <div className={`alarm_icon ${site.device_live_status === 1 ? `${site.mains_fail === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
              <td className={`text-center col8`}>
                <div className={`alarm_icon ${site.device_live_status === 1 ? `${site.mdb_fault === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
              <td className={`text-center col9`}>
                <div className={`alarm_icon  ${site.device_live_status === 1 ? `${site.phase_fault === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
              <td className={`text-center col10`}>
                <div className={`alarm_icon ${site.device_live_status === 1 ? `${site.dc_low === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
              <td className={`text-center col11`}>
                <div className={`alarm_icon ${site.device_live_status === 1 ? `${site.llvd === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
              <td className={`text-center col12`}>
                <div className={`alarm_icon  ${site.device_live_status === 1 ? `${site.odc_temp_high === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
              <td className={`text-center col13`}>
                <div className={`alarm_icon    ${site.device_live_status === 1 ? `${site.dc_fan_fault === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
              <td className={`text-center col14`}>
                <div className={`alarm_icon   ${site.device_live_status === 1 ? `${site.smoke === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
              <td className={`text-center col15`}>
                <div className={`alarm_icon   ${site.device_live_status === 1 ? `${site.water_leakage === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
              <td className={`text-center col16`}>
                <div className={`alarm_icon   ${site.device_live_status === 1 ? `${site.aviation_light_fault === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
              <td className={`text-center col17`}>
                <div className={`alarm_icon   ${site.device_live_status === 1 ? `${site.bat_load_fuse_fault === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
              <td style={{ borderRight: '1px solid #ccc' }} className={`text-center col18`}>
                <div className={`alarm_icon    ${site.device_live_status === 1 ? `${site.pg_status === 1 ? 'A3' : 'A1'}` : 'A4'}`}>
                  &nbsp;
                </div>
              </td>
            </tr>
          ))}
        </tbody>

      </table>
      <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />

    </>
  )
}