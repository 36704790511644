import React, { Component } from 'react';
import 'jstree/dist/themes/default/style.min.css'; // Import jsTree CSS
import $ from 'jquery'; // Import jQuery
import 'jstree';
import API from '../../Network/API';
class JsTree extends Component {

    componentDidMount() {
        this.fetchTreeData();
      }

    fetchTreeData() {
        // Replace with your Laravel API endpoint
        API.get('/v1/oauth/tree-data')
        //   .then((response) => response.json())
          .then((response) => {
            this.initializeJsTree(response.data.data);
          })
          .catch((error) => {
            console.error('Error fetching tree data:', error);
          });
      }
    
      initializeJsTree(treeData) {
        const initialTreeData = [
            {
              text: 'Global',
              children: [],
            },
          ];

    // Merge the initialTreeData with apiTreeData
    const mergedTreeData = [...initialTreeData, ...treeData];

        $('#tree').jstree({
          core: {
            data: mergedTreeData,
            themes: {
              responsive: true, // Optional: Disable the responsive theme if needed
            },
          },
          plugins: ['state'],
          state: {
            core: {
              opened: true,
            },
          },
        });
      }

  render() {
    return <div id="tree"></div>;
  }
}

export default JsTree;