import React from 'react';
import { useSelector } from 'react-redux';

export function RmsAlarmNav({ id,title,value,rmsAlarmTypeSelect,ref}) { 
  // const selector_lock_type = useSelector((state) => state.doorAlarmTypeReducer)
  // console.log(selector_lock_type)
    return (
        <>
        <label  htmlFor={`flexCheckDefaultRms${id}`} className="list-group-item d-flex clickable justify-content-between align-items-center align-items-center">
            <div className="left-list-group">
              <div className="form-check pb-0 mb-0">
              <input ref={ref}  onClick={(e) => rmsAlarmTypeSelect(e)} title={title} name={`user[${id}]`} className={`form-check-input rms_alrm_type_${id}  rms_type_${id}  door_type_${id}`} type="checkbox" value={id} id={`flexCheckDefaultRms${id}`}  /> 

                <label className="form-check-label" htmlFor={`flexCheckDefaultRms${id}`}>
               {title}
                </label>
              </div>
            </div>
            <span className={`badge ${value>0?'badge-secondary-red':'badge-secondary'} rounded-pill`}>{value}</span>
          </label>
        </>
    );
}
export const MemoAlarmNav = React.memo(RmsAlarmNav);