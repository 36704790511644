
import { createSlice } from '@reduxjs/toolkit';

export const AlarmSiteCodeSlice = createSlice({
    name: 'AlarmSiteCode',
    initialState: {
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers: {
        ALARM_SITE_CODE_FETCH_START: (state) => {
            state.loading = true
        },
        ALARM_SITE_CODE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data=action.payload.sites
        },
        EDIT_ALARM_DOOR_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !==action.payload.id)
             state.main_data = [...newArray,action.payload]
        },
        ALARM_SITE_CODE_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const {EDIT_ALARM_DOOR_FETCH_SUCCESS, ALARM_SITE_CODE_FETCH_START, ALARM_SITE_CODE_FETCH_SUCCESS, ALARM_SITE_CODE_FETCH_ERROR } = AlarmSiteCodeSlice.actions;
export default AlarmSiteCodeSlice.reducer;