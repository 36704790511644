

import { createSlice } from '@reduxjs/toolkit';

export const RmsAlarmGroupSlice = createSlice({
    name:'RmsAlarmGroup',
    initialState:{
        loading: false,
        data: {

        },
        error: false,
    },
    reducers:{
        RMS_ALARM_GROUP_FETCH_START:(state)=>{
            state.loading=true
        },
        RMS_ALARM_GROUP_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
        },
        RMS_ALARM_GROUP_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { RMS_ALARM_GROUP_FETCH_START,RMS_ALARM_GROUP_FETCH_SUCCESS,RMS_ALARM_GROUP_FETCH_ERROR }=RmsAlarmGroupSlice.actions;
export default RmsAlarmGroupSlice.reducer;