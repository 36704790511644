import HelperClass from "../../CustomHook/HelperClass";

export default function AcmReport({ loading, get_data, nextPage }){

    return (
        <>
        <div className="ms-1 me-1" id="acm_history">

             
<div className="table-responsive">
    <table className="table table-bordered mt-2 bg-white" >
        <thead>
            <tr>
                <th className="toi-primary text-center">Date</th>
                <th className="toi-primary text-center">SITE ID</th>
                <th className="toi-primary text-center">{HelperClass.CUSTOMER_SITE} SITE ID</th>
                <th className="toi-primary text-center">Tenant Name</th>
                <th className="toi-primary text-center">ACM SL</th>
                <th className="toi-primary text-center">Start Time</th>
                <th className="toi-primary text-center">End Time</th>
                <th className="toi-primary text-center">Start Cons.(kWh)</th>
                <th className="toi-primary text-center">End Cons.(kWh)</th>
                <th className="toi-primary text-center">Total Cons.(kWh)</th>
                <th className="toi-primary text-center">ON Peak Cons.(kWh)</th>
                <th className="toi-primary text-center">Off Peak Cons.(kWh)</th>
               
            </tr>
        </thead>
        <tbody>
            {!loading && get_data?.data?.map((row, index) => (
                <tr key={index}>
                    <td className="fs-11 text-dark text-center"><span style={{width:'100px'}}>{row.date}</span></td>
                    <td className="fs-11 text-dark text-center">{row.site_id}</td>
                    <td className="fs-11 text-dark text-center">{row.easi_site_id}</td>
                    <td className="fs-11 text-dark text-center">{row.tenant_name}</td>
                    <td className="fs-11 text-dark text-center">{row.acm_sl_id}</td>
                    <td className="fs-11 text-dark text-center">{row.start_kwh_time}</td>
                    <td className="fs-11 text-dark text-center">{row.end_kwh_time}</td>
                    <td className="fs-11 text-dark text-center">{row.start_cons}</td>
                    <td className="fs-11 text-dark text-center">{row.end_cons}</td>
                    <td className="fs-11 text-dark text-center">{row.total_cons}</td>
                    <td className="fs-11 text-dark text-center">{row.on_peak_cons}</td>
                    <td className="fs-11 text-dark text-center">{row.off_peak_cons}</td>



                </tr>
            ))}
        </tbody>
    </table>

</div>

{get_data?.has_page ?
    <div class="float-md-end mb-1 mt-1">
        <div class="btn-group ">
            {get_data?.first_page ?
                <a class="btn btn-primary btn-sm" style={{ cursor: 'no-drop' }}>Prev</a>
                :
                <a class="btn btn-primary btn-sm " onClick={() => nextPage(get_data?.prv_page_url)}>Prev</a>
            }

            {get_data?.has_more_page ?
                <a class="btn btn-info btn-sm " onClick={() => nextPage(get_data?.next_page_url)}>Next</a>
                :
                <a class="btn btn-info btn-sm " style={{ cursor: 'no-drop' }}>Next</a>

            }
        </div>
    </div> : ''
}


</div>
        </>
    )

}