
import { createSlice } from '@reduxjs/toolkit';

export const DoorAlarmTypeSlice = createSlice({
    name:'DoorAlarmTypeSlice',
    initialState:{
        loading: false,
        data: [],
        error: false,
    },
    reducers:{
        DOOR_ALARM_TYPE_FETCH_START:(state)=>{
            state.loading=true
            state.data=[]
        },
        DOOR_ALARM_TYPE_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=[...state.data,action.payload]
        },
        DOOR_ALARM_TYPE_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { DOOR_ALARM_TYPE_FETCH_START,DOOR_ALARM_TYPE_FETCH_SUCCESS,DOOR_ALARM_TYPE_FETCH_ERROR }=DoorAlarmTypeSlice.actions;
export default DoorAlarmTypeSlice.reducer;