import { useSelector } from "react-redux"
import React, { Component, Fragment, useEffect, useRef, useState } from "react";
import SiteFilterModel from "../../Models/SiteFilterModel";

export default function AlarmTypeOnline() {
    const [modalDialog, setModalDialog] = useState(false);

    const toggleModal = () => {
        setModalDialog(!modalDialog)
    }

    const selector_status = useSelector((state) => state.doorStatusReducer)
    const selector_rms_alarm_nav = useSelector((state) => state.rmsAlarmGroupReducer)
    const selector_lock = useSelector((state) => state.lockAlarmListReducer)
    return (
        <>
            <div className="row text-center  mb-2 mt-2">


                <div className="d-flex justify-content-end">
                    <div class="input-group input-group-sm max-with-searbar">
                        <input type="text" class="form-control" placeholder="search by site id...." />
                        <button class="btn btn-success" type="button"><i class="fa-light fa-search" /></button>
                        <button class="btn btn-info" type="button" onClick={()=>setModalDialog(!modalDialog)}><i class="fa-duotone fa-filter-list me-1" />Filter</button>
                    </div>


                    <div class="dropdown custom-dropdown-grop-btn ms-2">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa-solid fa-table-columns" /> Show Fields
                        </button>

                        <ul className="dropdown-menu mx-0 shadow w-220px">
                            <li>
                                <a className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    UPDATED AT
                                    <div className="form-check form-switch mb-0 ">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    SITE ID
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    EASI SITE ID
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    NETWORK
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    DOOR OPEN
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </a>
                            </li>
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    MODULE FAULT
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    MAINS FAIL
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    MDB FAULT
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    PHASE FAULT
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    DC LOW
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    LLVD
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    ODC TEMP
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    FAN FAULT
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    SMOKE
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    WATER LEAKAGE
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    AVIATION LIGHT
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    FUSE FAULT
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    PG
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>

                    <div class="dropdown custom-dropdown-grop-btn ms-2">
                        <button class="btn btn-outline-primary bg-white text-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa-duotone fa-bell"></i> RMS Event ({selector_rms_alarm_nav?.data?.total_alarm>0?<span className="badge bg-danger">{selector_rms_alarm_nav?.data?.total_alarm}</span>:0})
                        </button>
                        <ul class="dropdown-menu">
                        {!selector_rms_alarm_nav?.loading && selector_rms_alarm_nav?.data?.rms_alarm_group?.map((rms_alarm, index) => (
                            <>
                            <li className="ps-1 list-header">{rms_alarm.name}</li>
                            <li><hr className="dropdown-divider" /></li>
                          
                                   {rms_alarm?.alarm?.map((alarm, index) => (
                                      <li>
                                    <a className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    {alarm.name}
                                        <div className="form-check form-switch mb-0">
                                            {alarm.active_alarm_count>0?<span className="badge bg-danger">{alarm.active_alarm_count}</span>:<span className="badge bg-dark">0</span>}
                                        </div> 
                                    </a>
                                    </li>
                                      ))}

                                
                            </>
                            ))}
                        </ul>
                    </div>

                    <div class="dropdown custom-dropdown-grop-btn ms-2">
                        <button class="btn btn-outline-info text-dark bg-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa-duotone fa-bell"></i> ACS Event ({selector_lock?.data?.total_alarm>0?<span className="badge bg-danger">{selector_lock?.data?.total_alarm}</span>:0})
                        </button>
                        <ul class="dropdown-menu">
                            {!selector_lock?.loading && selector_lock?.data?.lock_alarms?.map((lock_alarm, index) => (
                                <li>
                                   
                                    <a className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                        {lock_alarm.name}
                                        <div className="form-check form-switch mb-0">
                                          
                                            {lock_alarm.value>0?<span className="badge bg-danger">{lock_alarm.value}</span>:<span className="badge bg-dark">0</span>}
                                        </div>
                                        
                                    </a>
                                    
                                </li>
                            ))}
                        </ul>
                    </div>


                </div>

            </div>

            <SiteFilterModel isModalVisible={modalDialog} handleShowB={() => setModalDialog(false)} />
        </>
    )
}