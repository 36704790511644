import { Link } from "react-router-dom";

export default function DcTab(props){

    return (
        <>
         <ul className="dc_nav">
                    <li className={`cus-tab ${props.id==1?'active':''}`}><Link to={`/site/${props.site_id}/dc_energy_meter`}>Overview</Link></li>
                    <li className={`cus-tab ${props.id==2?'active':''}`}><Link to={`/site/${props.site_id}/dc_energy_meter/graph`}>Grpah</Link></li>
                    <li className={`cus-tab ${props.id==3?'active':''}`}><Link to={`/site/${props.site_id}/dc_energy_meter/overload`}>Overload</Link></li>
                    <li className={`cus-tab ${props.id==4?'active':''}`}><Link to={`/site/${props.site_id}/dc_energy_meter/load_disconnect`}>Load Disconnect</Link></li>
                    <li className={`cus-tab ${props.id==5?'active':''}`}><Link to={`/site/${props.site_id}/dc_energy_meter/dc_tenant`}>Tenant Info</Link></li>
                    <li className={`cus-tab ${props.id==6?'active':''}`}><Link to={`/site/${props.site_id}/dc_energy_meter/dc_consumption`}>DC Consumption</Link></li>
                </ul>
        </>
    )
}