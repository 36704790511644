import { useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import API from "../Network/API";
import { LOCK_ALARM_FETCH_SUCCESS } from "../Reducer/LockAlarmListSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { AlarmNav } from "../CustomMemo/AlarmNav";
import { ALARM_SITE_CODE_FETCH_SUCCESS } from "../Reducer/AlarmSiteCodeSlice";
import LockAlarmCard from "../Component/Lock/LockAlarmCard";
import { LOCK_ALARM_HISTORY_FETCH_SUCCESS, LOCK_ALARM_HISTORY_LOAD_MORE_FETCH_SUCCESS } from "../Reducer/LockAlarmHistorySlice";
import { DOOR_ALARM_TYPE_FETCH_START, DOOR_ALARM_TYPE_FETCH_SUCCESS } from "../Reducer/DoorAlarmTypeSlice";
import { read, utils, writeFile } from 'xlsx';
import { DOOR_SITE_CODE_FETCH_SUCCESS } from "../Reducer/DoorSiteCodeSlice";
import { toast } from "react-hot-toast";
import BaseApi from "../Network/BaseApi";
import UnlockDoor from "../Models/UnlockDoor";
import { useCallback } from "react";
import CountdownTimer from "../Props/CountdownTimer";
import ReactPaginate from 'react-paginate';
import Env from "../CustomHook/Env";

export default function Alarm() {
  const [nxtPageAlarm, setNextPageAlarm] = useState(null);
  const [nxtPage, setNextPage] = useState(null);
  const [alarm_type, setAlarmType] = useState([])
  const [alarm_type_data, setAlarmTypeData] = useState([])
  const [site_code, setSiteCode] = useState([])
  const [export_data, setExportData] = useState([])
  const [loading_export, setLoadingExport] = useState(false)
  const [start_date, setStartDate] = useState('')
  const [end_date, setEndDate] = useState('')
  const [step, setStep] = useState(0)
  const inputStartDate = useRef(null);
  const inputEndDate = useRef(null);
  const ref = useRef([]);
  const [searchParams] = useSearchParams();
  const selector_lock_type = useSelector((state) => state.doorAlarmTypeReducer)
  const selector_site_id = useSelector((state) => state.doorSiteCodeReducer)
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const [modalDialogUlock, setModalDialogUlock] = useState(false);
  const [siteId, setSiteID] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
 
    if (page != 1) {
      nextPage()
    }
  }, [page]);

  
  useEffect(() => {
 
    searchSite('')
  }, [currentPage]);

  const observer = useRef();
  const lastElementRef = useCallback((element) => {
    //element is the react element being referenced

    // disconnect observer set on previous last element
    if (observer.current) observer.current.disconnect();

    // if there's no more data to be fetched, don't set new observer
    if (!hasMore) return;

    // set new observer
    observer.current = new IntersectionObserver((entries) => {
      // increase page number when element enters (is intersecting with) viewport.
      // This triggers the pagination hook to fetch more items in the new page
      if (entries[0].isIntersecting && hasMore) setPage((prev) => prev + 1);
    });

    // observe/monitor last element
    if (element) observer.current.observe(element);
  }, [hasMore]);



  const unlockDoorButton = (value) => {
    setSiteID(value)
    setModalDialogUlock(!modalDialogUlock)
  }

  useEffect(() => {
    const loaderElement = document.querySelector(".loading-mask");
    API.get('/v1/oauth/lock_alarm_nav').then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(LOCK_ALARM_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {

    });

    
  }, []);


  useEffect(() => {
    if (step == 1) {
      const loaderElement = document.querySelector(".loading-mask");
      loaderElement.removeAttribute('hidden');
      loaderElement.setAttribute('visible', 'true');
      var pagea = '/v1/oauth/lock_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code + '&start_date=' + start_date + '&end_date=' + end_date
      API.get(pagea).then((response) => {
        if (loaderElement) {
          if (loaderElement && !loaderElement.hasAttribute('hidden')) {
            loaderElement.setAttribute('hidden', 'true');
          }
        }
        setHasMore(response.data.data.has_more_page)
        setLoading(false);
        dispatch(LOCK_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))

      }).catch((error) => {
        setLoading(false);
      });
    }
  }, [selector_site_id.data]);


  useEffect(() => {

    if (searchParams.get('type') > 0) {

      var checkk = document.querySelector('.door_type_' + searchParams.get('type'));
      // if(checkk){
      //   console.log(searchParams.get('type'));
      //   document.querySelector('.door_type_' + searchParams.get('type')).click();
      // }
      if (alarm_type.indexOf(parseInt(searchParams.get('type'))) === -1) {

        if (checkk) {
          document.querySelector('.door_type_' + searchParams.get('type')).click();
        }

        setAlarmType([
          ...alarm_type, parseInt(searchParams.get('type'))
        ])
        dispatch(DOOR_ALARM_TYPE_FETCH_SUCCESS(parseInt(searchParams.get('type'))))
        var checs = document.querySelector('.door_type_' + searchParams.get('type'));
        setAlarmTypeData([...alarm_type_data, {
          'id': parseInt(searchParams.get('type')),
          'name': checs?.title,
        }])
      }
    }

  }, [searchParams]);



  const nextPage = () => {

    API.get(`/v1/oauth/lock_alarm_history?page=${page}&query=${alarm_type}&site_code=${site_code}&start_date=${start_date}&end_date=${end_date}`).then((response) => {
      setHasMore(response.data.data.has_more_page)
      setLoading(false);
      dispatch(LOCK_ALARM_HISTORY_LOAD_MORE_FETCH_SUCCESS(response.data.data))

    }).catch((error) => {
      setLoading(false);
    });
  }

  const alarmTypeSelect = (event) => {
    if (event.target.checked) {
      if (alarm_type.indexOf(parseInt(event.target.value)) === -1) {
        setAlarmType([
          ...alarm_type, parseInt(event.target.value)
        ])
        dispatch(DOOR_ALARM_TYPE_FETCH_SUCCESS(parseInt(event.target.value)))
        setAlarmTypeData([...alarm_type_data, {
          'id': parseInt(event.target.value),
          'name': event.target.title,
        }])

      }

    } else {
      dispatch(DOOR_ALARM_TYPE_FETCH_SUCCESS(parseInt(event.target.value)))
      removeID(parseInt(event.target.value))
      removeType(parseInt(event.target.value))
    }

  }

  useEffect(() => {
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');

    var pagea = '/v1/oauth/lock_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code + '&start_date=' + start_date + '&end_date=' + end_date

    API.get(pagea).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      setHasMore(response.data.data.has_more_page)
      setLoading(false);
      dispatch(LOCK_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))

    }).catch((error) => {
      setLoading(false);
    });

  }, [selector_lock_type.data]);



  const removeType = (u_id) => {
    setAlarmTypeData(alarm_type_data.filter(a =>
      a.id !== u_id
    ))
    removeID(u_id)
  }

  const removeID = (url) => {
    const index = alarm_type.indexOf(url);
    if (index > -1) { // only splice array when item is found
      alarm_type.splice(index, 1); // 2nd parameter means remove one item only
    }
    setAlarmType(alarm_type)
    // setNextPageAlarm('/v1/oauth/lock_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code)
  }

  const checkedState = (id) => {

    return alarm_type.find((element) => {
      return element === id;
    })

  }

  const checkedSiteId = (event) => {
    if (event.target.checked) {
      if (site_code.indexOf(event.target.value) === -1) {
        setSiteCode([
          ...site_code, event.target.value
        ])
        dispatch(DOOR_SITE_CODE_FETCH_SUCCESS(event.target.value))
        setStep(1)
        // setNextPageAlarm('/v1/oauth/lock_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code+'&start_date='+start_date+'&end_date='+end_date)
      }
    }
    else {
      dispatch(DOOR_SITE_CODE_FETCH_SUCCESS(event.target.value))
      setStep(1)
      removeSiteID(event.target.value)
    }
  }



  const removeSiteID = (u_id) => {
    setSiteCode(site_code.filter(a =>
      a !== u_id
    ))
    // setNextPageAlarm('/v1/oauth/lock_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code+'&start_date='+start_date+'&end_date='+end_date)
  }

  const clearFiler = () => {
    setAlarmType([]);
    setAlarmTypeData([]);
    setSiteCode([]);
    setStartDate('');
    setEndDate('');
    inputEndDate.current.value = "";
    inputStartDate.current.value = "";
    dispatch(DOOR_ALARM_TYPE_FETCH_START())
    document.getElementsByClassName("form-check-input").checked = false
    var textinputs = document.querySelectorAll('input:checked');
    var empty = [].filter.call(textinputs, function (el) {
      el.checked = false;
    });
    // window.location.replace('/alarms')
    window.history.replaceState(null, '', '/alarms');
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    API.get('/v1/oauth/lock_alarm_history?page=1').then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      setHasMore(response.data.data.has_page)
      setLoading(false);
      dispatch(LOCK_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))

    }).catch((error) => {
      setLoading(false);
    });

  }

  const refreshFiler = () => {

    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    API.get('/v1/oauth/lock_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code + '&start_date=' + start_date + '&end_date=' + end_date).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      setHasMore(response.data.data.has_page)
      setLoading(false);
      dispatch(LOCK_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))

    }).catch((error) => {
      setLoading(false);
    });

  }

  const searchSite = (site_id) => {

    const loaderElement = document.querySelector(".loading-mask");
    // loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    API.get(`/v1/oauth/alarm_sites?page=${currentPage+1}&site_id=${site_id}`).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      setTotalPages(response.data.data.total_page)
      // setCurrentPage(response.data.data.current_page)
      dispatch(ALARM_SITE_CODE_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {

    });

  }

  const searchFiler = () => {
    if (start_date == '' && end_date == '') {
      toast.error('Please select date rane')
      return false;
    }
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    API.get('/v1/oauth/lock_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code + '&start_date=' + start_date + '&end_date=' + end_date).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }

      setHasMore(response.data.data.has_more_page)
      setLoading(false);
      dispatch(LOCK_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))

    }).catch((error) => {
      setLoading(false);
    });
  }

  const handleExport = () => {
    setLoadingExport(true)

    if (start_date == '' && end_date == '') {
      toast.error('Please select date rane')
      setLoadingExport(false)
      return false;
    }

    API.get('/v1/oauth/acs_alarm_export?&query=' + alarm_type + '&site_code=' + site_code + '&start_date=' + start_date + '&end_date=' + end_date).then((response) => {

      const headings = [[
        'Site ID',
        'Alarm Name',
        'Start Time',
        'End Time',
        'Elasped Time',
        'Remark'
      ]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      ws['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 12 }, { width: 40 }];
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, response.data.data.alarm_data, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, 'ACS Alarm Report.xlsx');
      setLoadingExport(false);
    }).catch((error) => {
      setLoadingExport(false)
    });


  }

  const save_download = () => {
    setLoadingExport(true)
    if (start_date == '' && end_date == '') {
      toast.error('Please select date rane')
      setLoadingExport(false)
      return false;
    }

    fetch(BaseApi() + '/v1/oauth/export_lock_alarm?&query=' + alarm_type + '&site_code=' + site_code + '&start_date=' + start_date + '&end_date=' + end_date, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("token") ?? ""
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'acs_alarm.csv';
        downloadLink.click();
        setLoadingExport(false)
      }).catch((error) => {
        // console.error('Error:', error);
        setLoadingExport(false)
      });
  }

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const selector_lock = useSelector((state) => state.lockAlarmListReducer)
  const selector_alarm_site_code = useSelector((state) => state.alarmSiteCodeReducer)
  const lock_alarm_history = useSelector((state) => state.lockAlarmHistoryReducer)
  return (
    <>
      <div className="alarm-tab d-flex justify-content-between ms-0 me-0">
        <ul className="dc_nav" style={{ marginTop: '-8px' }}>
          <li className="cus-tab active"> <Link to={`/alarms`} className="link-active"><i className="fa-solid fa-chart-line me-1" /> ACS Alarm Events</Link></li>
          <li className="cus-tab"> <Link to={`/rms_alarms`}><i className="fa-solid fa-chart-gantt me-1" /> RMS Alarm Events</Link></li>
        </ul>
        <div style={{ marginTop: '-8px' }}>
          <div className="input-group input-group-sm">
            <input ref={inputStartDate} onChange={(e) => setStartDate(e.target.value)} type="date" class="form-control" placeholder="" defaultValue={start_date} />
            <input ref={inputEndDate} onChange={(e) => setEndDate(e.target.value)} type="date" class="form-control" placeholder="" defaultValue={end_date} />
            <button class="btn btn-info" type="button" onClick={() => searchFiler()}><i class="fa-solid fa-search"></i></button>

            <a className="btn btn-sm btn-square btn-success me-1" onClick={() => save_download()}>
              <i class="fa-duotone fa-download" /> Export {loading_export ? (
                <span
                  className="spinner-border spinner-border-sm ml-5"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <span></span>
              )}
            </a>

            <a className="btn btn-sm btn-square btn-warning me-1" onClick={() => refreshFiler()}>
              <i class="fa-light fa-refresh" /> Sync
            </a>
            <a className="btn btn-sm btn-square btn-danger " onClick={() => clearFiler()}>
              <i class="fa-light fa-remove" /> Clear
            </a>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-md-2">
          <div className="scroll-side-nav">
            <ul className="list-group alarm-list mb-2 ">
              <li className="list-group-item d-flex justify-content-between align-items-center align-items-center title">
                <h6 className="m-0 p-0">Access</h6>
              </li>
              {!selector_lock?.loading && selector_lock?.data?.lock_alarms?.map((lock_alarm, index) => (
                <AlarmNav ref={(element) => { ref.current[3] = element }} checkedState={checkedState} alarmTypeSelect={alarmTypeSelect} id={lock_alarm.order_list} title={lock_alarm.name} value={lock_alarm.value} />

              ))}
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row mt-1">
          <div class="table-responsive  tblFixHead" id="dataview-all">
            <table className="table table-striped  table-bordered m-0 text-center bg-white mb-2">
              <thead className="text-uppercase toi-primary">
                <tr>
                  <td></td>
                  <td className="font-bold">Filter By Site ID</td>
                  <td colSpan={4}>
                    <div className="search-box me-2 position-relative">
                      <input onChange={(e) => searchSite(e.target.value)} type="text" className="form-control border-radius-20" id="searchResultList" placeholder="Search site id..." />
                      <i className="fa-light fa-search search-icon" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th className="toi-primary"></th>
                  <th className="toi-primary">{Env.CUSTOMER_SITE} Site ID</th>
                  <th className="toi-primary">Site ID</th>
                  <th className="toi-primary">Network</th>
                  <th className="toi-primary">Status</th>
                  <th className="toi-primary">Action</th>
                </tr>
              </thead>
              <tbody>
                {!selector_alarm_site_code?.loading && selector_alarm_site_code?.main_data?.map((site, index) => (
                  // <SiteCodeTRNew checkedSiteId={checkedSiteId} site={site} />
                  <tr for={site.site_id}>
                    <td><input type='checkbox' id={site.site_id} value={site.site_id} onClick={(e) => checkedSiteId(e)} /></td>
                    <td>{site.customer_site_id}</td>
                    <td><label for={site.site_id}>{site.site_id}</label></td>
                    <td className={`text-center col4`}>
                      {site.device_live_status === 1 ?
                        <div className="signal-bars mt1 sizing-box good four-bars">
                          <span>
                            <img src="/assets/icon/6.svg" className="tower-height" alt="" />
                          </span>
                          <div className={`first-bar bar ${site.network_val >= 1 ? '' : 'null-color'}`}>
                          </div>
                          <div className={`second-bar bar ${site.network_val >= 2 ? '' : 'null-color'}`}>
                          </div>
                          <div className={`third-bar bar ${site.network_val >= 3 ? '' : 'null-color'}`}>
                          </div>
                          <div className={`fourth-bar bar ${site.network_val >= 4 ? '' : 'null-color'}`}>
                          </div>
                          <div className={`fifth-bar bar ${site.network_val >= 5 ? '' : 'null-color'}`}>
                          </div>
                        </div>
                        :
                        <i class="fa-solid fa-wifi-slash offline-rms-icon" title="Site not updating" />
                      }
                    </td>
                    <td className='text-center d-flex justify-content-center' style={{ gap: '5px' }}>
                      {site.door_status == 1 && site.breach_status == 0 ?

                        <img src="/assets/icon/door-yello.png" alt="" className="img-size door_img_79" title="Door Open" />
                        :
                        site.breach_status == 1 ?
                          <img src="/assets/icon/door-red.png" alt="" className="img-size door_img_79" title="Security Breach" />
                          :
                          site.door_open_timeout == 1 && site.breach_status == 0 ?
                            <img src="/assets/icon/door-red.png" alt="" className="img-size door_img_79" title="Door Open Timeout" />
                            :
                            <img src="/assets/icon/door-green.png" alt="" className="img-size door_img_79" title="Door Closed" />

                      }

                      {site.lock_fault == 0 && site.lock_open_status == 1 ?

                        <img src="/assets/icon/lock-yello.png" alt="" className="img-size door_img_79" title="Lock Open" />
                        :
                        site.lock_fault == 1 ?
                          <img src="/assets/icon/lock-red.png" alt="" className="img-size door_img_79" title="Lock Fault" />
                          :
                          site.lock_close_fail == 1 ?
                            <img src="/assets/icon/lock-red.png" alt="" className="img-size door_img_79" title="Lock Close Failed" />
                            :
                            <img src="/assets/icon/lock-green.png" alt="" className="img-size door_img_79" title="Lock Closed" />

                      }

                      {site.card_reader_fault == 0 && site.card_temper_alart == 1 ?

                        <img src="/assets/icon/lock-yello.png" alt="" className="img-size door_img_79" title="Card Temper Alert" />
                        :
                        site.card_reader_fault == 1 && site.card_temper_alart == 0 ?
                          <img src="/assets/icon/lock-red.png" alt="" className="img-size door_img_79" title="Card Reader Fault" />
                          :
                          <img src="/assets/icon/rfid-green.png" alt="" className="img-size door_img_79" title="" />

                      }

{site.breach_status == 1 ?
                                        <img src="/assets/icon/sb_red.png" alt="" className="img-size" title="Security Breach" />
                                        :
                                        <img src="/assets/icon/sb_green.png" alt="" className="img-size" title="Normal" />
                                    }
                    </td>
                    <td>
                      {site?.lock_open_status == 1 || site.lock_command == 1 || site.device_live_status == 0 ?
                        <button disabled type="button" className="btn btn-secondary waves-effect btn-sm" id="btn1">
                          <i className="fa fa-key mr-1" /> <span>Unlock</span></button>
                        :
                        <button type="button" className="btn btn-secondary waves-effect btn-sm" id="btn1" onClick={() => unlockDoorButton(site.id)}>
                          <i className="fa fa-key mr-1" /> <span>Unlock</span></button>

                      }


                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            

            {/* <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector_alarm_site_code?.data.prv_page_url} nextPageUrl={selector_alarm_site_code?.data.next_page_url} postsPerPage={selector_alarm_site_code?.data.per_page} paginate={loadMore} totalPosts={selector_alarm_site_code?.data.total_page} hasMorePage={selector_alarm_site_code?.data.has_more_page} onFirstPage={selector_alarm_site_code?.data.first_page} hasPage={selector_alarm_site_code?.data.has_page} currentPage={selector_alarm_site_code?.data.current_page} /> */}
            <UnlockDoor site_id={siteId} isModalVisible={modalDialogUlock} handleShowB={() => setModalDialogUlock(false)} />

          </div>
          </div>
          <ReactPaginate
              pageCount={totalPages}
              onPageChange={handlePageChange}
              forcePage={currentPage}
              containerClassName={'pagination'}
              previousLinkClassName={'page-link'}
              breakClassName={'page-link'}
              nextLinkClassName={'page-link'}
              pageClassName={'page-item'}
              pageLinkClassName="page-link"
              disabledClassName={'disabled'}
              activeClassName={'active'}
            />
           
        </div>
        <div className="col-md-4">
          <div className="card">

            {alarm_type.length > 0 || site_code.length > 0 ?
              <div className="card-body">

                {alarm_type.length > 0 ?
                  alarm_type_data?.map((typeName, i) =>
                    <span class="badge bg-white shadow-sm rounded-pill text-dark" key={i}>{typeName['name']}  <i class="me-1 fas fa-times-circle" onClick={() => removeType(typeName['id'])}></i></span>
                  ) : ''}
                {alarm_type.length > 0 && site_code.length > 0 ?
                  <hr className="m-1 p-0" /> : ''
                }

                {site_code.length > 0 ?
                  <>

                    {site_code?.map((st, i) =>
                      <span class="badge bg-white shadow-sm rounded-pill text-dark">{st} <i class="me-1 fas fa-times-circle" onClick={() => removeSiteID(st)}></i></span>
                    )}
                  </>
                  : ''}
              </div>
              : ''}
          </div>

          <div className="card scroll-side-nav m-0 p-0">
            {!lock_alarm_history?.loading && lock_alarm_history?.alarms?.map((data, index) => (

              <table className={`table table-borderless alarm_table bg-white  mt-1 ${data.alarm_type == 1 ? 'border-warning' : data.alarm_type == 2 ? 'border-warning' : data.alarm_type == 3 ? 'border-danger' : data.alarm_type == 4 ? 'border-warning' : data.alarm_type == 5 ? 'border-info' : 'border-danger'}`} id="alart_list_table" style={{ marginBottom: '0px', width: '97%' }} ref={index === lock_alarm_history?.alarms.length - 1 ? lastElementRef : undefined}>
                <tbody><tr>
                  <td className="status">
                    <label className="label text-uppercase" style={{ fontWeight: 'bold', fontSize: '13px' }}><Link style={{ color: '#00aced' }} to={`/site/${data.site_id}`}>{data.site_id}</Link></label>
                  </td>
                  <td className="font-weight-bold" valign="top" style={{ verticalAlign: 'top' }}>Elapsed Time</td>
                  <td width="20%" valign="top" className="" style={{ verticalAlign: 'top' }}>
                    <label className={`alarm_type_name badge ${data.alarm_type == 6 ? 'bg-danger' : data.alarm_type == 11 ? 'bg-success' : 'bg-warning text-dark'} `}>
                      {data.name}
                    </label>
                  </td>
                </tr>
                  <tr className="time_tr" style={{ borderTop: '1px solid #f3f1f1' }}>
                    <td className="status" rel={data.alarm_type} data-value={data.site_id}>
                      {data.alarm_start}
                      <>
                        {data.alarm_end != null ?
                          <p>{data.alarm_end}</p> : ''
                        }
                      </>
                    </td>
                    <td className="font-weight-bold">
                      {data.alarm_end != null ?
                        <span className="elisp_time">{data.total_elaspsed}</span>
                        :
                        <CountdownTimer targetDate={data.start} />
                      }
                    </td>
                    <td width="20%" style={{ textAlign: 'left' }}>
                      {data.alarm_type == 1 && data.alarm_end == null ?
                        <button type="button" id="alarm_clear" className="btn btn-sm btn-info">Clear</button>
                        : ''}
                    </td>
                  </tr>
                  {data.alarm_type == 7 ?
                    <tr>
                      <td>
                        {
                          data.command_type == 1 ?
                            <>
                              <strong>Door Open By:</strong><br />
                              <strong>{data.command_details?.name}</strong>
                              <br /> <b>Org:</b> <span>{data.command_details?.organization}</span>
                              <br /> <b>Mobile:</b> <span>{data.command_details?.phone}</span>
                              <br /> <b>Job Details:</b> <span>{data.command_details?.details}</span>
                            </>
                            :
                            <>
                              <strong>Door Open By:</strong><br />
                              <strong>{data.user?.name}</strong>
                              <br /> <b>Org:</b> <span>{data.user?.vendor_name}</span>
                              <br /> <b>Mobile:</b> <span>{data.user?.phone}</span>
                              <br /> <b>Card:</b> <span>{data.user?.card_number}</span>
                              <br /> <b>Job Details:</b> <span>{data.user?.job_name}</span>
                            </>
                        }
                      </td>
                      <td>
                        {
                          data.command_type == 1 ?
                            <>
                              <strong>Lock Command By:</strong><br />
                              <strong>{data.user?.name}</strong>
                              <br /> <b>Org:</b> <span>{data.user?.vendor_name}</span>
                              <br /> <b>Mobile:</b> <span>{data.user?.phone}</span>
                            </> : ''
                        }
                      </td>
                    </tr>
                    : ''}
                </tbody>
              </table>
              // <LockAlarmCard data={alarm} ref={index === lock_alarm_history?.alarms.length - 1 ? lastElementRef : undefined} />
            ))}

            {loading && <div className="text-center">Loading......</div>}

            {/* {lock_alarm_history?.data?.has_page ?
              <div class="float-md-end mb-1 mt-1">
                <div class="btn-group ">
                  {lock_alarm_history?.data?.first_page ?
                    <a class="btn btn-primary btn-sm" style={{ cursor: 'no-drop' }}>Prev</a>
                    :
                    <a class="btn btn-primary btn-sm " onClick={() => nextPage(lock_alarm_history?.data?.prv_page_url)}>Prev</a>
                  }

                  {lock_alarm_history?.data?.has_more_page ?
                    <a class="btn btn-info btn-sm " onClick={() => nextPage(lock_alarm_history?.data?.next_page_url)}>Next</a>
                    :
                    <a class="btn btn-info btn-sm " style={{ cursor: 'no-drop' }}>Next</a>

                  }
                </div>
              </div> : ''
            } */}
          </div>

        </div>
      </div>
    </>
  )
}