
import { createSlice } from '@reduxjs/toolkit';

export const SiteIdSlice = createSlice({
    name: 'siteId',
    initialState: {
        loading: false,
        data: null,
        error: false,
    },
    reducers: {
        SITE_ID_FETCH_START: (state) => {
            state.loading = true
            state.data = []
        },
        SITE_ID_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        SITE_ID_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { SITE_ID_FETCH_START, SITE_ID_FETCH_SUCCESS, SITE_ID_FETCH_ERROR } = SiteIdSlice.actions;
export default SiteIdSlice.reducer;