import { useParams } from "react-router-dom";
import CellNav from "./CellNav";
import ColCard from "./ColCard";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SITE_ID_FETCH_SUCCESS } from "../../../../Reducer/SiteIdSlice";
import { BATTERY_ID_FETCH_SUCCESS } from "../../../../Reducer/batteryIDSlice";
import { useEffect } from "react";
import API from "../../../../Network/API";
import { useRef } from "react";
import ReactEcharts from "echarts-for-react";
export default function CellMonitoring() {
  const [rms, setRms] = useState()
  const ddd = useParams();
  const dispatch = useDispatch()
  const echarts_react_1 = useRef();
  const [battery_chart, setChart] = useState()

  useEffect(() => {
    dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
    dispatch(BATTERY_ID_FETCH_SUCCESS(ddd.sl))
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');

    API.get(`/v1/oauth/bms_home/${ddd.id}/cell/${ddd.sl}`).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }

      setRms(response.data.data)

    }).catch((error) => {

      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
    });
    last_soc_one_hour()
  }, [ddd.id]);

  const last_soc_one_hour = () => {

    API.get(`/v1/oauth/battery_soc_last_one_hour?site_id=${ddd.id}&battery_sl_id=${ddd.sl}`).then((response) => {
      setChart(response.data.data)
    }).catch((error) => {

    });
  }
  const option = {

    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['Cel-1', 'Cel-2', 'Cel-3', 'Cel-4', 'Cel-5', 'Cel-6', 'Cel-7', 'Cel-8', 'Cel-9', 'Cel-10', 'Cel-11', 'Cel-12', 'Cel-13', 'Cel-14', 'Cel-15']
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      data: battery_chart?.array_time
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} v'
      }
    },
    series: [
      {
        name: 'Cel-1',
        type: 'line',
        data: battery_chart?.array_v1
      },
      {
        name: 'Cel-2',
        type: 'line',
        data: battery_chart?.array_v2
      },
      {
        name: 'Cel-3',
        type: 'line',
        data: battery_chart?.array_v3
      },
      {
        name: 'Cel-4',
        type: 'line',
        data: battery_chart?.array_v4
      },
      {
        name: 'Cel-5',
        type: 'line',
        data: battery_chart?.array_v5
      },
      {
        name: 'Cel-6',
        type: 'line',
        data: battery_chart?.array_v6
      },
      {
        name: 'Cel-7',
        type: 'line',
        data: battery_chart?.array_v7
      },
      {
        name: 'Cel-8',
        type: 'line',
        data: battery_chart?.array_v8
      },
      {
        name: 'Cel-9',
        type: 'line',
        data: battery_chart?.array_v9
      },
      {
        name: 'Cel-10',
        type: 'line',

        data: battery_chart?.array_v10
      },
      {
        name: 'Cel-11',
        type: 'line',

        data: battery_chart?.array_v11
      },
      {
        name: 'Cel-12',
        type: 'line',

        data: battery_chart?.array_v12
      },
      {
        name: 'Cel-13',
        type: 'line',

        data: battery_chart?.array_v13
      },
      {
        name: 'Cel-14',
        type: 'line',

        data: battery_chart?.array_v14
      },
      {
        name: 'Cel-15',
        type: 'line',
        data: battery_chart?.array_v15
      }
    ]
  };
  return (
    <>
      <div className="row">
        <CellNav id={2} site_id={ddd.id} battery_id={ddd.sl} />
      </div>
      <div className="row ms-1 me-1 mb-2 g-2">

        <div class="d-flex flex-row bd-highlight  justify-content-between gap-2">
          <div className="col-cel w-100">
            <ColCard title={`Cell-1`} status={`${rms?.batteries?.v1 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v1}v`} bgColor={`bg-green`} />
          </div>
          <div className="col-cel w-100">
            <ColCard title={`Cell-2`} status={`${rms?.batteries?.v2 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v2}v`} bgColor={`bg-green`} />
          </div>
          <div className="col-cel w-100">
            <ColCard title={`Cell-3`} status={`${rms?.batteries?.v3 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v3}v`} bgColor={`bg-green`} />
          </div>
          <div className="col-cel w-100">
            <ColCard title={`Cell-4`} status={`${rms?.batteries?.v4 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v4}v`} bgColor={`bg-green`} />
          </div>
          <div className="col-cel w-100">
            <ColCard title={`Cell-5`} status={`${rms?.batteries?.v5 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v5}v`} bgColor={`bg-green`} />
          </div>
        </div>
        <div class="d-flex flex-row bd-highlight  justify-content-between gap-2">
          <div className="col-cel w-100">
            <ColCard title={`Cell-6`} status={`${rms?.batteries?.v6 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v6}v`} bgColor={`bg-green`} />
          </div>
          <div className="col-cel w-100">
            <ColCard title={`Cell-7`} status={`${rms?.batteries?.v7 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v7}v`} bgColor={`bg-green`} />
          </div>
          <div className="col-cel w-100">
            <ColCard title={`Cell-8`} status={`${rms?.batteries?.v8 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v8}v`} bgColor={`bg-green`} />
          </div>
          <div className="col-cel w-100">
            <ColCard title={`Cell-9`} status={`${rms?.batteries?.v9 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v9}v`} bgColor={`bg-green`} />
          </div>
          <div className="col-cel w-100">
            <ColCard title={`Cell-10`} status={`${rms?.batteries?.v10 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v10}v`} bgColor={`bg-green`} />
          </div>
        </div>
        <div class="d-flex flex-row bd-highlight  justify-content-between gap-2">
          <div className="col-cel w-100">
            <ColCard title={`Cell-11`} status={`${rms?.batteries?.v11 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v11}v`} bgColor={`bg-green`} />
          </div>
          <div className="col-cel w-100">
            <ColCard title={`Cell-12`} status={`${rms?.batteries?.v12 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v12}v`} bgColor={`bg-green`} />
          </div>
          <div className="col-cel w-100">
            <ColCard title={`Cell-13`} status={`${rms?.batteries?.v13 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v13}v`} bgColor={`bg-green`} />
          </div>
          <div className="col-cel w-100">
            <ColCard title={`Cell-14`} status={`${rms?.batteries?.v14 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v14}v`} bgColor={`bg-green`} />
          </div>
          <div className="col-cel w-100">
            <ColCard title={`Cell-15`} status={`${rms?.batteries?.v15 <= 0 ? 'Faulty' : 'Ok'}`} value={`${rms?.batteries?.v15}v`} bgColor={`bg-green`} />
          </div>
        </div>

        <div class="col-md-12">
          <hr />
          <div className="row">
            <div class="col-md-2 col-4 text-center border-right">
              <p className="p-0 m-0 title-c">Max. Volt</p>
              <p className="p-0 m-0 value-c">{rms?.max_cell}</p>
              <p className="p-0 m-0 key-c">V</p>
            </div>
            <div class="col-md-2 col-4 text-center border-right">
              <p className="p-0 m-0 title-c">Min. Volt</p>
              <p className="p-0 m-0 value-c">{rms?.min_cell}</p>
              <p className="p-0 m-0 key-c">V</p>
            </div>
            <div class="col-md-2  col-4 text-center border-right">
              <p className="p-0 m-0 title-c">Avg. Volt</p>
              <p className="p-0 m-0 value-c">{rms?.avg_volt}</p>
              <p className="p-0 m-0 key-c">V</p>
            </div>
            <div class="col-md-2 col-4 text-center border-right">
              <p className="p-0 m-0 title-c">Pack Volt</p>
              <p className="p-0 m-0 value-c">{rms?.batteries?.pack_voltage}</p>
              <p className="p-0 m-0 key-c">V</p>
            </div>
            <div class="col-md-2 col-4 text-center border-right">
              <p className="p-0 m-0 title-c">Pack Current</p>
              <p className="p-0 m-0 value-c">{rms?.batteries?.pack_current}</p>
              <p className="p-0 m-0 key-c">A</p>
            </div>
            <div class="col-md-2 col-4 text-center border-right">
              <p className="p-0 m-0 title-c">Power</p>
              <p className="p-0 m-0 value-c">{rms?.batteries?.power}</p>
              <p className="p-0 m-0 key-c">kW</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 me-1 ms-1">
        <div className="card shadow-sm border-0 bg-cc mt-2" style={{ minHeight: '300px' }}>
          <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

            <b class="main-card-title mb-0 flex-grow-1">Cell Voltage</b>
            <div class="flex-shrink-0">
              <div class="app-search">
                <div class="input-group">
                  <div class="position-relative">
                    Today last 1 Hour
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body bg-white">
            <ReactEcharts ref={echarts_react_1} style={{ height: "400px" }} option={option} />
          </div>
        </div>
      </div>
    </>
  )
}