
import React from 'react';
import '../../../Timeline.css';
import { useSelector } from 'react-redux';
import { LineUtil } from 'leaflet';
import { Link } from 'react-router-dom';
import CountdownTimer from '../../../Props/CountdownTimer';
export default function AcsTimeline() {
    const rms_active_selector = useSelector((state) => state.acsActiveAlarmSlice)
    return (
        <>
            <ul class="cbp_tmtimeline">
                {!rms_active_selector?.loading && rms_active_selector.data?.alarms?.map((rms_alrm, index) => (
                    <li>
                        <time class="cbp_tmtime"><span>{rms_alrm.alarm_time}</span> <span>{rms_alrm.time_ago}</span></time>
                        <div class={`cbp_tmicon ${rms_alrm.alarm_type == 1 ? 'bg-warning' : rms_alrm.alarm_type == 2 ? 'bg-warning' : rms_alrm.alarm_type == 3 ? 'bg-danger' : rms_alrm.alarm_type == 4 ? 'bg-warning' : rms_alrm.alarm_type == 5 ? 'bg-info' :rms_alrm.alarm_type == 8 ? 'bg-green' : 'bg-danger'}`}>
                            {rms_alrm.alarm_type == 1 ?
                                <i class="fa-solid fa-sensor-on text-white"></i>
                                :
                                rms_alrm.alarm_type == 2 ?
                                    <i class="fa-solid fa-lock-open"></i>
                                    :
                                    rms_alrm.alarm_type == 3 ?

                                        <i class="fa-light fa-credit-card-blank"></i>

                                        :
                                        rms_alrm.alarm_type == 4 ?
                                            <i class="fa-light fa-lock"></i>
                                            :
                                            rms_alrm.alarm_type == 5 ?
                                                <i class="fa-solid fa-bullhorn"></i>
                                                :
                                                rms_alrm.alarm_type == 6 ?
                                                    <i class="fa-regular fa-user-secret"></i>
                                                    :
                                                    rms_alrm.alarm_type == 7 ?
                                                        <i class="fa-regular fa-door-open"></i>
                                                        :
                                                        rms_alrm.alarm_type == 8 ?
                                                        <i class="fa-regular fa-door-closed"></i>
                                                            :
                                                            rms_alrm.alarm_type == 9 ?
                                                                <i class="fa-light fa-credit-card-blank"></i>
                                                                :
                                                                rms_alrm.alarm_type == 10 ?
                                                                    <i class="fa-sharp fa-lock"></i>
                                                                    :
                                                                    rms_alrm.alarm_type == 11 ?
                                                                        <i class="fa-regular fa-clock"></i>
                                                                        :
                                                                        <i class="fa-sharp fa-solid fa-bell"></i>
                            }
                        </div>
                        <div class={`cbp_tmlabel bg-timeline ${rms_alrm.alarm_type == 1 ? 'border-warning' : rms_alrm.alarm_type == 2 ? 'border-warning' : rms_alrm.alarm_type == 3 ? 'border-danger' : rms_alrm.alarm_type == 4 ? 'border-warning' : rms_alrm.alarm_type == 5 ? 'border-info':rms_alrm.alarm_type == 8 ? 'border-green' : 'border-danger'}`}>
                        <div className='row'>
                            <div className='col-8'>

                                <h2><Link to={`site/${rms_alrm.site_id}`} className='text-color-yellow'>{rms_alrm.site_id}</Link></h2>
                                <p className='mb-0 li-1st'><b className='mb-0'>{rms_alrm.name}</b></p>
                                <p className='mb-0 li-2st'>{rms_alrm.alarm_date}</p>
                            </div>
                            <div className='col-4'>
                            <p className='mb-0 li-2st'>
                                        {rms_alrm.alarm_end != null ?
                                            <span className="elisp_time">{rms_alrm.total_elaspsed}</span>
                                            :
                                            <CountdownTimer targetDate={rms_alrm.start} />
                                        }
                                    </p>
                            </div>
                            </div>
                        </div>
                    </li>
                ))}

            </ul>
        </>
    );
}