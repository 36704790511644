import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DOOR_FETCH_ERROR, DOOR_FETCH_MORE_SUCCESS, DOOR_FETCH_SUCCESS } from "../../Reducer/DoorSlice";
import API from "../../Network/API";
import { useEffect } from "react";
import CountdownTimer from "../../Props/CountdownTimer";
import Paginate from "../../CustomHook/Paginate";
import { Link } from "react-router-dom";
import SiteFilterModel from "../../Models/SiteFilterModel";
import UnlockDoor from "../../Models/UnlockDoor";
import { useCallback } from "react";
import { useRef } from "react";
import Env from "../../CustomHook/Env";

export default function AllLock() {
    const [nxtPage, setNextPage] = useState(null);
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.doorReducer)
    const selector_status = useSelector((state) => state.doorStatusReducer)
    const [modalDialog, setModalDialog] = useState(false);
    const [modalDialogUlock, setModalDialogUlock] = useState(false);
    const [siteId, setSiteID] = useState();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);


    useEffect(() => {

        if(page!=1){
            all_door_load()
        }
       
    }, [page]);

    const unlockDoorButton = (value) => {
        setSiteID(value)
        setModalDialogUlock(!modalDialogUlock)
    }

    const toggleModal = () => {
        setModalDialog(!modalDialog)
    }

    
    const searchSite = (site_code) => {
        API.get('/v1/oauth/all_door?site_id=' + site_code).then((response) => {
            dispatch(DOOR_FETCH_SUCCESS(response.data.data))
            setLoading(false);
            setHasMore(response.data.data.has_more_page)
        }).catch((error) => {
            dispatch(DOOR_FETCH_ERROR(error))
            setLoading(false);
        });
    }


    const all_door_load = () => {
        API.get(`/v1/oauth/all_door?page=${page}`).then((response) => {
            setLoading(false);
            setHasMore(response.data.data.has_more_page)
            dispatch(DOOR_FETCH_MORE_SUCCESS(response.data.data))
        }).catch((error) => {
            dispatch(DOOR_FETCH_ERROR(error))
            setLoading(false);
        });
    }

    const syncData = () => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/all_door').then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            setLoading(false);
            setHasMore(response.data.data.has_more_page)
            dispatch(DOOR_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            dispatch(DOOR_FETCH_ERROR(error))
            setLoading(false);
        });
    }

    const observer = useRef();
    const lastElementRef = useCallback((element) => { 
        //element is the react element being referenced
        
        // disconnect observer set on previous last element
        if (observer.current) observer.current.disconnect();
          
        // if there's no more data to be fetched, don't set new observer
        if (!hasMore) return;
          
        // set new observer
        observer.current = new IntersectionObserver((entries) => {
          // increase page number when element enters (is intersecting with) viewport.
          // This triggers the pagination hook to fetch more items in the new page
          if (entries[0].isIntersecting && hasMore) setPage((prev) => prev + 1);
        });
      
        // observe/monitor last element
        if (element) observer.current.observe(element);
      }, [hasMore]);


    const selector_rms_alarm_nav = useSelector((state) => state.rmsAlarmGroupReducer)
    const selector_lock = useSelector((state) => state.lockAlarmListReducer)

    const strAscending = [...selector?.main_data].sort((a, b) =>
    a.site_id > b.site_id ? 1 : -1,
  );
    return (
        <>
         
                <div className="row text-center  mb-2 mt-2 me-1 ms-1">


                    <div className="d-flex justify-content-end">
                        <div class="input-group input-group-sm max-with-searbar">
                            <input type="text" onChange={(e) => searchSite(e.target.value)} class="form-control" placeholder="search by site id...." />
                            <button class="btn btn-success" type="button"><i class="fa-light fa-search" /></button>
                            <button class="btn btn-info" type="button" onClick={() => setModalDialog(!modalDialog)}><i class="fa-duotone fa-filter-list me-1" />Filter</button>
                        </div>
                        <button class="btn btn-warning  ms-2" type="button" onClick={() => syncData()}><i class="fa-light fa-refresh me-1" />Sync</button>


                        <div class="dropdown custom-dropdown-grop-btn ms-2">
                            <button class="btn btn-outline-primary bg-white text-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa-duotone fa-bell"></i> RMS Event ({selector_rms_alarm_nav?.data?.total_alarm > 0 ? <span className="badge bg-danger">{selector_rms_alarm_nav?.data?.total_alarm}</span> : 0})
                            </button>
                            <ul class="dropdown-menu">
                                {!selector_rms_alarm_nav?.loading && selector_rms_alarm_nav?.data?.rms_alarm_group?.map((rms_alarm, index) => (
                                    <>
                                        <li className="ps-1 list-header">{rms_alarm.name}</li>
                                        <li><hr className="dropdown-divider" /></li>

                                        {rms_alarm?.alarm?.map((alarm, index) => (
                                            <li>
                                                <Link className="dropdown-item d-flex gap-2 align-items-center justify-content-between" to={`/rms_alarms?type=${alarm.order_list}`}>
                                                    {alarm.name}
                                                    <div className="form-check form-switch mb-0">
                                                        {alarm.active_alarm_count > 0 ? <span className="badge bg-danger">{alarm.active_alarm_count}</span> : <span className="badge bg-dark">0</span>}
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}


                                    </>
                                ))}
                            </ul>
                        </div>

                        <div class="dropdown custom-dropdown-grop-btn ms-2">
                            <button class="btn btn-outline-info text-dark bg-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa-duotone fa-bell"></i> ACS Event ({selector_lock?.data?.total_alarm > 0 ? <span className="badge bg-danger">{selector_lock?.data?.total_alarm}</span> : 0})
                            </button>
                            <ul class="dropdown-menu">
                                {!selector_lock?.loading && selector_lock?.data?.lock_alarms?.map((lock_alarm, index) => (
                                    <li>

                                        <Link className="dropdown-item d-flex gap-2 align-items-center justify-content-between" to={`/alarms?type=${lock_alarm.order_list}`}>
                                            {lock_alarm.name}
                                            <div className="form-check form-switch mb-0">
                                                {lock_alarm.value > 0 ? <span className="badge bg-danger">{lock_alarm.value}</span> : <span className="badge bg-dark">0</span>}
                                            </div>

                                        </Link>

                                    </li>
                                ))}
                            </ul>
                        </div>


                    </div>

                </div>
       

            <div className="table-responsive">

                <table className="table table-bordered table-striped m-0 table-sm bg-white text-center">
                    <thead className="text-uppercase">
                        <tr>
                            <th className="toi-primary">Last Updated</th>
                            <th className="text-center toi-primary" width={`5%`}>Site ID</th>
                            <th className="text-center toi-primary" width={`5%`}>{Env.CUSTOMER_SITE} Site ID</th>
                            <th className="text-center toi-primary">Network</th>
                            <th className="text-center toi-primary" width={`5%`}>Remaining Backup</th>
                            <th className="text-center toi-primary">Door(RMS)</th>
                            <th className="text-center toi-primary">Mains Fail</th>
                            <th className="text-center toi-primary" >DC Low</th>
                            <th className="text-center toi-primary" width={`5%`}>Battery Critical</th>
                            <th className="text-center toi-primary" width={`5%`}>Reactifier Fault</th>
                            <th className="text-center toi-primary">LLVD</th>
                            <th className="text-center toi-primary">PG/DG ON</th>
                            <th className="text-center toi-primary">Door(ACS)</th>
                            <th className="text-center toi-primary">Lock</th>
                            <th className="text-center toi-primary">RFID</th>
                            <th className="text-center toi-primary">Security Breach</th>
                            <th className="text-center toi-primary">Vibration</th>

                            <th className="text-center toi-primary">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!selector?.loading && strAscending?.map((site, index) => (
                            <tr className={`${site.device_live_status == 0 ? 'offline-tr' : ''}`} ref={index === selector?.main_data.length - 1 ? lastElementRef : undefined}>
                                <td className="update_time_79">
                                    {site.update_time}
                                </td>

                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.site_id}</Link>
                                </td>
                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.customer_site_id}</Link>
                                </td>

                                <td className="text-center">
                                    {site.device_live_status === 1 ?
                                        <div className="signal-bars mt1 sizing-box good four-bars">
                                            <span>
                                                <img src="/assets/icon/6.svg" className="tower-height" alt="" />
                                            </span>
                                            <div className={`first-bar bar ${site.network_val >= 1 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`second-bar bar ${site.network_val >= 2 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`third-bar bar ${site.network_val >= 3 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fourth-bar bar ${site.network_val >= 4 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fifth-bar bar ${site.network_val >= 5 ? '' : 'null-color'}`}>
                                            </div>
                                        </div>
                                        :
                                        <i class="fa-solid fa-wifi-slash offline-rms-icon" title="Site not updating" />
                                    }
                                </td>
                                <td>
                                    {site?.rms?.mains_fail == 1 ?
                                        <span className="elisp_time">00:00</span> : '--'}
                                </td>
                                <td className="text-center">
                                    {site?.rms?.door_open == 1 ?
                                        <img src="/assets/icon/door-yello.png" alt="" className="img-size" title="Door Open" />
                                        :
                                        <img src="/assets/icon/door-green.png" alt="" className="img-size" title="Door Closed" />

                                    }
                                </td>
                                <td className="text-center">
                                    {site?.rms?.mains_fail == 1 ?
                                        <img src="/assets/icon/circle-red-png.webp" alt="" className="img-size blink" title="Critical" />
                                        :
                                        <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                    }
                                </td>
                                <td>
                                    {site?.rms?.dc_low == 1 ?
                                        <img src="/assets/icon/circle-red-png.webp" alt="" className="img-size blink" title="Critical" />
                                        :
                                        <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                    }
                                </td>
                                <td className="text-center">
                                    {site?.rms?.dc_low == 1 ?
                                        <img src="/assets/icon/circle-red-png.webp" alt="" className="img-size blink" title="Critical" />
                                        :
                                        <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                    }
                                </td>
                                <td>
                                    {site?.rms?.rectifier_fault == 1 ?
                                        <img src="/assets/icon/circle-red-png.webp" alt="" className="img-size blink" title="Critical" />
                                        :
                                        <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                    }
                                </td>
                                <td>
                                    {site?.rms?.llvd == 1 ?
                                        <img src="/assets/icon/circle-red-png.webp" alt="" className="img-size blink" title="Critical" />
                                        :
                                        <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                    }
                                </td>
                                <td>
                                    {site?.rms?.pg_status == 1 ?
                                        <img src="/assets/icon/yellow_icon.png" alt="" className="rms-alarm-img blink" title="Critical" />
                                        :
                                        <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                    }
                                </td>
                                <td className="text-center">
                                    {site.door_status == 1 && site.breach_status == 0 ?

                                        <img src="/assets/icon/door-yello.png" alt="" className="img-size" title="Door Open" />
                                        :
                                        site.breach_status == 1 ?
                                            <img src="/assets/icon/door-red.png" alt="" className="img-size" title="Security Breach" />
                                            :
                                            site.door_open_timeout == 1 && site.breach_status == 0 ?
                                                <img src="/assets/icon/door-red.png" alt="" className="img-size" title="Door Open Timeout" />
                                                :
                                                <img src="/assets/icon/door-green.png" alt="" className="img-size" title="Door Closed" />

                                    }
                                </td>
                                <td className="text-center">

                                    {site.lock_fault == 0 && site.lock_open_status == 1 ?

                                        <img src="/assets/icon/lock-yello.png" alt="" className="img-size" title="Lock Open" />
                                        :
                                        site.lock_fault == 1 ?
                                            <img src="/assets/icon/lock-red.png" alt="" className="img-size" title="Lock Fault" />
                                            :
                                            site.lock_close_fail == 1 ?
                                                <img src="/assets/icon/lock-red.png" alt="" className="img-size" title="Lock Close Failed" />
                                                :
                                                <img src="/assets/icon/lock-green.png" alt="" className="img-size" title="Lock Closed" />

                                    }
                                </td>
                                <td className="text-center">
                                    {site.card_reader_fault == 0 && site.card_temper_alart == 1 ?

                                        <img src="/assets/icon/lock-yello.png" alt="" className="img-size" title="Card Temper Alert" />
                                        :
                                        site.card_reader_fault == 1 && site.card_temper_alart == 0 ?
                                            <img src="/assets/icon/lock-red.png" alt="" className="img-size" title="Card Reader Fault" />
                                            :
                                            <img src="/assets/icon/rfid-green.png" alt="" className="img-size" title="" />

                                    }

                                </td>
                                <td className="text-center">
                                    {site.breach_status == 1 ?
                                        <img src="/assets/icon/sb_red.png" alt="" className="img-size" title="Security Breach" />
                                        :
                                        <img src="/assets/icon/sb_green.png" alt="" className="img-size" title="Normal" />
                                    }
                                </td>
                                <td className="text-center">
                                    {site.vib_sens_status == 1 ?
                                        <i class="fa-solid fa-sensor-on text-red" style={{ fontSize: '18px' }} />
                                        :
                                        <i class="fa-solid fa-sensor-on text-success" style={{ fontSize: '18px' }} />
                                    }

                                </td>

                                <td className="text-center" width={'10%'}>
                                    <div className="btn-group btn-group-sm">
                                        {site?.lock_open_status == 1 || site.lock_command == 1 || site.device_live_status==0 ?
                                            <button disabled type="button" className="btn btn-secondary waves-effect" id="btn1">
                                                <i className="fa fa-key mr-1" /> <span>Unlock</span></button>
                                            :
                                            <button type="button" className="btn btn-secondary waves-effect" id="btn1" onClick={() => unlockDoorButton(site.id)}>
                                                <i className="fa fa-key mr-1" /> <span>Unlock</span></button>

                                        }
                                        <button type="button" className="btn btn-sm btn-info dropdown-toggle waves-effect" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fe-more-vertical font-18 align-middle" />
                                        </button>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" href="#"><i className="fe-plus mr-1" /> Create Job</a>
                                            <a className="dropdown-item" href="#"><i className="fe-settings mr-1" /> Configurations</a>

                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>

            </div>
           <SiteFilterModel isModalVisible={modalDialog} handleShowB={() => setModalDialog(false)} />

            <UnlockDoor site_id={siteId} isModalVisible={modalDialogUlock} handleShowB={() => setModalDialogUlock(false)} />

        </>
    );

}