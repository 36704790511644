
import { createSlice } from '@reduxjs/toolkit';

export const rmsAlarmIdSlice = createSlice({
    name: 'rmsAlarmId',
    initialState: {
        loading: false,
        data: {

        },
        sites:[],
        error: false,
    },
    reducers: {
        RMS_ALARM_ID_FETCH_START: (state) => {
            state.loading = true
        },
        RMS_ALARM_ID_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
     
        RMS_ALARM_ID_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const {RMS_ALARM_ID_FETCH_START,RMS_ALARM_ID_FETCH_SUCCESS,RMS_ALARM_ID_FETCH_ERROR } = rmsAlarmIdSlice.actions;
export default rmsAlarmIdSlice.reducer;