import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGGED_IN } from "../Reducer/authSlice";

export default function Realtime(){
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    useEffect(() => {

        const userInfo = JSON.parse(localStorage.getItem("auth"));
        if (userInfo) {
          dispatch(USER_LOGGED_IN(userInfo));
        }
        setTimeout(() => {
         
            window.Echo.private(`App.User.${user.id}`) 
            .subscribed(() => {
            console.log(`subscribed to users ${user.id}`);
            }) .notification((notification) => {
                console.log(notification)
            });

            window.Echo.channel('notify_event')
            .subscribed(() => {
                // console.log('subscribed to post comments' + user['id']);
            }) .listen('SiteAlarmEvent', (e) => {
                 console.log(e);
            }).listen('AccessLockAlarmCountEvent', (e) => {
                console.log(e);
            })

        }, 5000);

        }, []);
    return(
        <>
        </>
    )
}