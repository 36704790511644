export default function CellCard(props){
    return (
        <>
        <div className={`card shadow-sm border-0 ${props.bgColor} mt-2 rounded`}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center icon_box_main">
                                       <div className="icon_h">
                                       <div className="icon_box me-3 shadow">
                                        <i className={`${props.icon}`}></i>
                                        </div>
                                       </div>
                                        <div className="icon_box_content w-100">
                                            <h6 className=" p-0 m-0 fw-blod">{props.value}</h6>
                                            <hr className="mb-1 mt-2" />
                                            <p className="p-0 m-0 fs-12">{props.title}</p>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
        </>
    )
}