

import { createSlice } from '@reduxjs/toolkit';

export const SiteAssetSlice = createSlice({
    name: 'site_asset',
    initialState: {
        loading: false,
        data: {

        },
        main_data: [],
        error: false,
    },
    reducers: {
        SITE_ASSET_FETCH_START: (state) => {
            state.loading = true
        },
        SITE_ASSET_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data = action.payload.assets
        },
        ADD_ASSET_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.site_id !==action.payload.site_id)
             state.main_data = [...newArray,action.payload]
        },
        SITE_ASSET_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const {ADD_ASSET_SUCCESS, SITE_ASSET_FETCH_START, SITE_ASSET_FETCH_SUCCESS, SITE_ASSET_FETCH_ERROR } = SiteAssetSlice.actions;
export default SiteAssetSlice.reducer;