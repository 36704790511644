export default function RectifierAllLogReport(props){
    return (
        <>
        <div className="table-responsive">
           <table className="table table-striped table-bordered table-centered mb-0  alarm_table_custom text-center" id="all_data">
            <thead className="toi-primary text-white">
            <tr className="text-uppercase">
                            <th className="text-center toi-primary" rowSpan={2}>Datetime</th>
                            <th className="text-center toi-primary" rowSpan={2}>SITE ID</th>
                            <th className="text-center toi-primary" rowSpan={2}>Easi SITE ID</th>
                            <th className="text-center toi-primary" rowSpan={2}>Rectifer Name</th>
                            <th className="text-center toi-primary" colSpan={2}>DC</th>
                            <th className="text-center toi-primary" colSpan={6}>Phase</th>
                            <th className="text-center toi-primary" colSpan={2}>Cumulative Power</th>
                            <th className="text-center toi-primary" rowSpan={2}>AC Frequency(Hz)</th>
                            <th className="text-center toi-primary" rowSpan={2}>DC Active Power(kW)</th>
                        </tr>
                        <tr>
                            <th className="text-center toi-primary">V</th>
                            <th className="text-center toi-primary">I</th>
                            <th className="text-center toi-primary">A(V)</th>
                            <th className="text-center toi-primary">B(V)</th>
                            <th className="text-center toi-primary">C(V)</th>
                            <th className="text-center toi-primary">A(I)</th>
                            <th className="text-center toi-primary">B(I)</th>
                            <th className="text-center toi-primary">C(I)</th>
                            <th className="text-center toi-primary">AC(kWh)</th>
                            <th className="text-center toi-primary">DC(kWh)</th>
                        </tr>
            </thead>
            <tbody>
                        {props.data?.logs?.length>0 && props.data?.logs?.map((site, index) => (
                            <tr className={`${site.device_live_status == 0 ? 'offline-tr' : ''}`}>
                                <td>
                                <p className="m-0 p-0" style={{fontSize:'12px',width:'150px'}}>{site.update_time}</p>
                                        
                                </td>

                                <td className="text-center">
                                {props.data?.rectifier?.site_id}
                                </td>
                                <td className="text-center">
                                {props.data?.rectifier?.rms?.customer_site_id}
                                </td>

                                <td className="text-center">
                                    <a className="text-warning text-underline-hover" href="#"> {props.data?.rectifier?.rectifier_name?.name}</a>
                                </td>
                                
                                <td className="text-center">
                                    {site.dc_voltage}
                                </td>
                                <td className="text-center">
                                {site.dc_current}
                                </td>
                                <td className="text-center">
                                {site.pahse_avoltage}
                                </td>
                                <td className="text-center">
                                {site.pahse_bvoltage}
                                </td>
                                <td className="text-center">
                                {site.pahse_cvoltage}
                                </td>
                                <td className="text-center">
                                {site.pahse_acurrent}
                                </td>
                                <td className="text-center">
                                {site.pahse_bcurrent}
                                </td>
                                <td className="text-center">
                                {site.pahse_ccurrent}
                                </td>
                              
                                <td className="text-center">
                                {site.cum_ac_power}
                                </td>
                                <td className="text-center">
                                {site.cum_dc_power}
                                </td>
                                <td className="text-center">
                                {site.ac_frequency}
                                </td>
                                <td className="text-center">
                                {site.dc_active_power}
                                </td>
                            </tr>
                          ))}
            </tbody>
        </table>
        </div>
        </>
    )
}