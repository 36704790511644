import React from 'react';
import { useCountdown } from '../CustomHook/useCountdown';


const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  return (
    <><span className="elisp_time ms-1">{hours}:{minutes}:{seconds}</span></>
     );

};

export default CountdownTimer;