

import { createSlice } from '@reduxjs/toolkit';

export const dcEnergyMeterSlice = createSlice({
    name: 'dcEnergyMeter',
    initialState: {
        loading: false,
        data: {

        },
        sites: [],
        main_data: {

        },
        error: false,
    },
    reducers: {
        DC_ENERGY_FETCH_START: (state) => {
            state.loading = true
        },
        DC_ENERGY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.sites = action.payload.sites
        },
        DC_ENERGY_FETCH_LOAD_MORE: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.sites = [...state.sites, ...action.payload.sites]
        },
        EDIT_DC_ENERGY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.sites.filter(todo => todo.id !== action.payload.id)
            state.sites = [...newArray, action.payload]
        },
        DC_ENERGY_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { DC_ENERGY_FETCH_LOAD_MORE, DC_ENERGY_FETCH_START, DC_ENERGY_FETCH_SUCCESS, DC_ENERGY_FETCH_ERROR, EDIT_DC_ENERGY_FETCH_SUCCESS } = dcEnergyMeterSlice.actions;
export default dcEnergyMeterSlice.reducer;