

import { createSlice } from '@reduxjs/toolkit';

export const rectifierSlice = createSlice({
    name: 'rectifier',
    initialState: {
        loading: false,
        data: {

        },
        sites: [],
        main_data: {

        },
        error: false,
    },
    reducers: {
        RECTIFIER_FETCH_START: (state) => {
            state.loading = true
        },
        RECTIFIER_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.sites = action.payload.sites
        },
        RECTIFIER_FETCH_LOAD_MORE: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.sites = [...state.sites, ...action.payload.sites]
        },
        EDIT_RECTIFIER_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.sites.filter(todo => todo.id !== action.payload.id)
            state.sites = [...newArray, action.payload]
        },
        RECTIFIER_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { RECTIFIER_FETCH_LOAD_MORE, RECTIFIER_FETCH_START, RECTIFIER_FETCH_SUCCESS, RECTIFIER_FETCH_ERROR, EDIT_RECTIFIER_FETCH_SUCCESS } = rectifierSlice.actions;
export default rectifierSlice.reducer;