import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Paginate from "../../CustomHook/Paginate";
import API from "../../Network/API";
import { ROLE_FETCH_SUCCESS } from "../../Reducer/RoleSlice";
import AddRole from "./AddRole";
import EditRole from "./EditRole";


export default function RoleIndex() {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [modalDialog, setModalDialog] = useState(false);
    const [editmodalDialog, setEditModalDialog] = useState(false);
    const [data, setData] = useState()
    const toggleModal = () => {
        setModalDialog(!modalDialog)
    }

    const toggleModalEdit = (row) => {
        setEditModalDialog(!editmodalDialog)
        setData(row)
    }

   

 

    const syncData = (name) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/roles?name' + name).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(ROLE_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    }

    useEffect(() => {

        syncData('')

    }, []);

    const selector = useSelector((state) => state.roleReducer)

    return (
        <>
            <div className="row text-center  mb-2 mt-2">
                <div className="d-flex justify-content-end">
                    <div class="input-group input-group-sm max-with-searbar">
                        <input onChange={(e) => syncData(e.target.value)} type="text" class="form-control" placeholder="search by name...." />
                        <button class="btn btn-success" type="button"><i class="fa-light fa-search" /></button>
                    </div>
                    <button class="btn btn-success  ms-2 me-2" type="button" onClick={() => toggleModal()}><i class="fa-light fa-plus me-1" />Add New</button>
                </div>

            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive-lg">
                        <table className="table table-bordered bg-white table-striped text-center" id="myTable">
                            <thead className="text-uppercase">
                                <tr>
                                    <th scope="col" className="text-center toi-primary">SL</th>
                                    <th scope="col" className="text-center toi-primary">Role Name</th>
                                    <th scope="col" className="text-center toi-primary">Details</th>
                                    <th scope="col" className="text-center toi-primary">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!selector?.loading && selector?.main_data.length > 0 && selector?.main_data?.map((row, index) => (
                                    <tr key={index}>
                                        <td className="text-center">
                                            {index}
                                        </td>
                                        <td>
                                            {row.name}
                                        </td>
                                        <td>
                                            {row.note}
                                        </td>

                                        <td className="text-center">
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm btn-primary dropdown-toggle" data-bs-toggle="dropdown">
                                                    Action
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li><a class="dropdown-item" href="#" onClick={() => toggleModalEdit(row)}><i className="fa fa-edit" /> Edit</a></li>
                                                    <li><a class="dropdown-item" href="#"><i className="fa fa-key" /> Role Permission</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                      
                    </div>
                </div>
            </div>

            <AddRole isModalVisible={modalDialog} handleShowB={() => setModalDialog(false)} />
            <EditRole isModalVisible={editmodalDialog} handleShowB={() => setEditModalDialog(false)} data={data} />

        </>
    )
}