import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"

export default function AlarmTypeCard(){
    const selector_status = useSelector((state) => state.doorStatusReducer)
    const location = useLocation();
    return (<>
    <div className="col-md-12 text-center  mb-1 mt-1">
                    <div class="btn-group shadow-0" role="group" aria-label="Basic example">
                        <Link to="/door_management/all_door" class={`btn btn-outline-info  waves-effect waves-light ${location.pathname=='/door_management/all_door'?'active':''} `}>All Door: <span class="badge badge-secondary ">{selector_status?.data?.online_status?.total_site}</span></Link>
                        <Link to="/door_management/door_open" class={`btn btn-outline-info  waves-effect waves-light ${location.pathname=='/door_management/door_open'?'active':''} `}>Door Open: <span className={`badge ${selector_status?.data?.door_status?.door_status>0?'badge-secondary-red':'badge-secondary'}`}>{selector_status?.data?.door_status?.door_status}</span></Link>
                        <Link to="/door_management/lock_open" class={`btn btn-outline-info  waves-effect waves-light ${location.pathname=='/door_management/lock_open'?'active':''} `}>Lock Open: <span className={`badge ${selector_status?.data?.door_status?.door_status>0?'badge-secondary-red':'badge-secondary'}`}>{selector_status?.data?.door_status?.door_status}</span></Link>
                        <Link to="/door_management/lock_closed_failed" class={`btn btn-outline-info  waves-effect waves-light ${location.pathname=='/door_management/lock_closed_failed'?'active':''} `}>Lock Close Failed: <span className={`badge ${selector_status?.data?.door_status?.lock_close_fail>0?'badge-secondary-red':'badge-secondary'}`}>{selector_status?.data?.door_status?.lock_close_fail}</span></Link>
                        <Link to="/door_management/lock_fault" class={`btn btn-outline-info  waves-effect waves-light ${location.pathname=='/door_management/lock_fault'?'active':''} `}>Lock Fault: <span className={`badge ${selector_status?.data?.door_status?.lock_fault>0?'badge-secondary-red':'badge-secondary'}`}>{selector_status?.data?.door_status?.lock_fault}</span></Link>
                        <Link to="/door_management/reader_fault" class={`btn btn-outline-info  waves-effect waves-light ${location.pathname=='/door_management/reader_fault'?'active':''} `}>Reader Fault: <span className={`badge ${selector_status?.data?.door_status?.card_reader_fault>0?'badge-secondary-red':'badge-secondary'}`}>{selector_status?.data?.door_status?.card_reader_fault}</span></Link>
                        <Link to="/door_management/security_breach" class={`btn btn-outline-info  waves-effect waves-light ${location.pathname=='/door_management/security_breach'?'active':''} `}>Security Breach: <span className={`badge ${selector_status?.data?.door_status?.breach_status>0?'badge-secondary-red':'badge-secondary'}`}>{selector_status?.data?.door_status?.breach_status}</span></Link>
                        <Link to="/door_management/vibration" class={`btn btn-outline-info  waves-effect waves-light ${location.pathname=='/door_management/vibration'?'active':''} `}>Vibration: <span className={`badge ${selector_status?.data?.door_status?.vib_sens_status>0?'badge-secondary-red':'badge-secondary'}`}>{selector_status?.data?.door_status?.vib_sens_status}</span></Link>
                        <Link to="/door_management/buzzer" class={`btn btn-outline-info  waves-effect waves-light ${location.pathname=='/door_management/buzzer'?'active':''} `}>Buzzer: <span className={`badge ${selector_status?.data?.door_status?.buz_status>0?'badge-secondary-red':'badge-secondary'}`}>{selector_status?.data?.door_status?.buz_status}</span></Link>
                        <Link to="/door_management/card_temper_alert" class={`btn btn-outline-info  waves-effect waves-light ${location.pathname=='/door_management/card_temper_alert'?'active':''} `}>Card Temper: <span className={`badge ${selector_status?.data?.door_status?.card_temper_status>0?'badge-secondary-red':'badge-secondary'}`}>{selector_status?.data?.door_status?.card_temper_status}</span></Link>
                    </div>
                </div>
    </>)
}