import HelperClass from "../../CustomHook/HelperClass";

export default function DcKwReport({ loading, get_data, nextPage, request }) {

    return (
        <>
            <div className="ms-1 me-1" id="dc_kw_history">
                <div className="table-responsive bg-white">
                    <table width="100%" border={1} cellPadding={0} cellSpacing={0} className="table table-bordered mt-2 text-center">
                        <thead className="text-center">
                            <tr>
                                <th height={20} colSpan={20} align="center" valign="center" className="col" style={{ fontSize: '18px', fontWeight: 'bold' }}> DC(kW) Report
                                </th>
                            </tr>
                            <tr>
                                <th height={20} colSpan={20} align="center" valign="center" className="col" style={{ fontSize: '13px', fontWeight: 'bold' }}> Date: {request?.duration_type == 1 ? request?.start_date : `${request?.start_date} To ${request?.end_date}`}
                                </th>
                            </tr>
                            
                            <tr>
                                <th align="center" valign="center" class="col1" >Date</th>
                                <th align="center" valign="center" class="col1" >{HelperClass().CUSTOMER_SITE} Site ID</th>
                                <th align="center" valign="center" class="col1" >Site ID</th>
                                <th align="center" valign="center" class="col1" >Tenant Name</th>
                                <th align="center" valign="center" class="col1" >Avg (kW)</th>
                                 <th align="center" valign="center" class="col1" >Max (kW)</th>
                            </tr>
                        </thead>

                        <tbody>
                            {!loading && get_data?.data?.length > 0 && get_data?.data?.map((site, index) => (
                                <tr key={index}>
                                    <td>{site.date}</td>
                                    <td>{site.easi_site_id}</td>
                                    <td>{site.site_id}</td>
                                    <td>{site.tenant_name}</td>
                                    <td>{site.avg_kw}</td>
                                    <td>{site.max_kw}</td>
                                
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {get_data?.has_page ?
                    <div class="float-md-end mb-1 mt-1">
                        <div class="btn-group ">
                            {get_data?.first_page ?
                                <a class="btn btn-primary btn-sm" style={{ cursor: 'no-drop' }}>Prev</a>
                                :
                                <a class="btn btn-primary btn-sm " onClick={() => nextPage(get_data?.prv_page_url)}>Prev</a>
                            }

                            {get_data?.has_more_page ?
                                <a class="btn btn-info btn-sm " onClick={() => nextPage(get_data?.next_page_url)}>Next</a>
                                :
                                <a class="btn btn-info btn-sm " style={{ cursor: 'no-drop' }}>Next</a>

                            }
                        </div>
                    </div> : ''
                }
            </div>
        </>
    )

}