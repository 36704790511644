import CountdownTimer from "./CountdownTimer";

export default function RmsDashboardTrProps(props) {
    return (
        <>
            <tr>
                <th>{props.title}</th>
                <td>{props.status==1?props.startDate:''}</td>
                <th className="text-center">
                {props.status==1?<CountdownTimer targetDate={props.startDate}/>:'00:00:00'}
                </th>
                <td className="text-center"><div className={`alarm_icon ${props.status==1?'A3':'A1'}`} title={`${props.status==1?'Critical':'Normal'}`}> &nbsp;</div> </td>
            </tr>
        </>
    )
}