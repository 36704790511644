import { Link } from "react-router-dom";

export default function CellNav(props){

    return (
        <>
         <ul className="dc_nav">
                    <li className={`cus-tab ${props.id==1?'active':''}`}><Link to={`/site/${props.site_id}/bms/${props.battery_id}/dashboard`}>Overview</Link></li>
                    <li className={`cus-tab ${props.id==2?'active':''}`}><Link to={`/site/${props.site_id}/bms/${props.battery_id}/cell_monitoring`}>Cell Monitoring</Link></li>
                    <li className={`cus-tab ${props.id==3?'active':''}`}><Link to={`/site/${props.site_id}/bms/${props.battery_id}/graph`}>Graph</Link></li>
                    <li className={`cus-tab ${props.id==4?'active':''}`}><Link to={`/site/${props.site_id}/bms/${props.battery_id}/report`}>Report</Link></li>
              </ul>
        </>
    )
}