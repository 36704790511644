import HelperClass from "../../CustomHook/HelperClass";

export default function DcEnergyConsumptionReport({ loading, get_data, nextPage,request }) {
    return (
        <>
            <div className="ms-1 me-1" id="dc_enercy_consum_history">
                <div className="table-responsive bg-white">
                    <table width="100%" border={1} cellPadding={0} cellSpacing={0} className="table table-bordered mt-2 text-center">
                        <thead className="text-center">
                            <tr>
                                <th height={20} colSpan={20} align="center" valign="center" className="col" style={{ fontSize: '18px', fontWeight: 'bold' }}> DC Energy Consumption Report
                                </th>
                            </tr>
                            <tr>
                                <th height={20} colSpan={20} align="center" valign="center" className="col" style={{ fontSize: '13px', fontWeight: 'bold' }}> Date:{request?.duration_type==1?request?.start_date:`${request?.start_date} To ${request?.end_date}`}
                                </th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="3" align="center" valign="center" class="col" >Total</th>
                                <th colspan="3" align="center" valign="center" class="col" >
                                    Grid Power</th>
                                <th colspan="3" align="center" valign="center" class="col" >
                                    Generator</th>
                                <th colspan="3" align="center" valign="center" class="col" >
                                    Battery</th>
                                    {!loading && get_data?.tanents?.map((row, index) => (
                                    <th colspan="2" align="center" valign="center" class="col">Tenant {index+1}</th>
                                    ))}
                                    {!loading && get_data?.rectifiers?.map((row, index) => (
                                    <th colspan="4" align="center" valign="center" class="col">Rectifier {index+1}</th>
                                    ))}
                            </tr>
                            <tr>
            <th align="center" valign="center" class="col1" >{HelperClass().CUSTOMER_SITE} Site ID</th>

            <th align="center" valign="center" class="col1" >Site ID</th>
            <th align="center" valign="center" class="col1" >Site Name</th>
            <th align="center" valign="center" class="col1" >Cluster</th>
            <th align="center" valign="center" class="col1" >Zone</th>
            <th align="center" valign="center" class="col1" >Start Time</th>
            <th align="center" valign="center" class="col1" >End Time</th>
            <th align="center" valign="center" class="col1" >Period</th>
            <th align="center" valign="center" class="col1" >Run Hours (HH:MM:SS)</th>
            <th align="center" valign="center" class="col1" >Total Consumption(kWh)</th>
            <th align="center" valign="center" class="col1" >Avg. Consumption(kWh)</th>
            <th align="center" valign="center" class="col1" >Run Hours (HH:MM:SS)</th>
            <th align="center" valign="center" class="col1" >Consumption(kWh)</th>
            <th align="center" valign="center" class="col1" >Avail(%)</th>
            <th align="center" valign="center" class="col1" >Run Hours (HH:MM:SS)</th>
            <th align="center" valign="center" class="col1" >Consumption(kWh)</th>
            <th align="center" valign="center" class="col1" >Avail(%)</th>
            <th align="center" valign="center" class="col1" >Run Hours (HH:MM:SS)</th>
            <th align="center" valign="center" class="col1" >Consumption(kWh)</th>
            <th align="center" valign="center" class="col1" >Avail(%)</th>
            {!loading && get_data?.tanents?.map((row, index) => (
                                  <>
                                   <th align="center" valign="center" class="col1" >Tenant Name</th>
                <th align="center" valign="center" class="col1" >Consumption(kWh)</th>
                                  </>
                                    ))}
                                    {!loading && get_data?.rectifiers?.map((row, index) => (
                                    <>
                                       <th align="center" valign="center" class="col" >Rectifier Name</th>
                <th align="center" valign="center" class="col" >Initial</th>
                <th align="center" valign="center" class="col" >Final</th>
                <th align="center" valign="center" class="col" >Consumption(kWh)</th>
                                    </>
                                    ))}
            </tr>
                        </thead>

                        <tbody>
                        {!loading && get_data?.data?.length>0 && get_data?.data?.map((site, index) => (
                            <tr>
                                <td>{site.easi_site_id}</td>
                                <td>{site.site_id}</td>
                                <td>{site.site_name}</td>
                                <td>{site.cluster_name}</td>
                                <td>{site.zone_name}</td>
                                <td>{site.start_time}</td>
                                <td>{site.end_time}</td>
                                <td>{site.period}</td>
                                <td>{site.total_run_hour}</td>
                                <td>{site.total_kwh}</td>
                                <td>{site.avg_kwh}</td>
                                <td>{site.grid_run_hour}</td>
                                <td>{site.grid_cons}</td>
                                <td>{site.grid_availability}</td>
                                <td>{site.pg_run_hour}</td>
                                <td>{site.pg_cons}</td>
                                <td>{site.pg_availability}</td>
                                <td>{site.battery_run_hour}</td>
                                <td>{site.battery_cons}</td>
                                <td>{site.battery_availability}</td>
                                {!loading && get_data?.tanents?.map((tent, tind) => (
                                  <>
                                   <td align="center" valign="center" class="col1" >{site?.tenant_kwh[tind]?.tenant_name}</td>
                                    <td align="center" valign="center" class="col1" >{site?.tenant_kwh[tind]?.cons}</td>
                                  </>
                                    ))}

{!loading && get_data?.rectifiers?.map((rrow, rindex) => (
                                    <>
                                       <td align="center" valign="center" class="col" >{site?.rectifier_kwh[rindex]?.rectifier_name}</td>
                <td align="center" valign="center" class="col" >{site?.rectifier_kwh[rindex]?.initial}</td>
                <td align="center" valign="center" class="col" >{site?.rectifier_kwh[rindex]?.final}</td>
                <td align="center" valign="center" class="col" >{(site?.rectifier_kwh[rindex]?.cons.toFixed(2))}</td>
                                    </>
                                    ))}
                            </tr>
                             ))}
                        </tbody>
                    </table>
                </div>
                {get_data?.has_page ?
                        <div class="float-md-end mb-1 mt-1">
                            <div class="btn-group ">
                                {get_data?.first_page ?
                                    <a class="btn btn-primary btn-sm" style={{ cursor: 'no-drop' }}>Prev</a>
                                    :
                                    <a class="btn btn-primary btn-sm " onClick={() => nextPage(get_data?.prv_page_url)}>Prev</a>
                                }

                                {get_data?.has_more_page ?
                                    <a class="btn btn-info btn-sm " onClick={() => nextPage(get_data?.next_page_url)}>Next</a>
                                    :
                                    <a class="btn btn-info btn-sm " style={{ cursor: 'no-drop' }}>Next</a>

                                }
                            </div>
                        </div> : ''
                    }
            </div>
        </>
    )
}