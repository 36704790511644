import { Link } from "react-router-dom";

export default function OfflineNav(prpos) {
    return (
        <>
            <div className="btn-group">
                <Link className={`btn btn-outline-info ${prpos?.active == 1 ? 'text-white active' : 'text-dark bg-white'}`} to={`/offline_sites`} aria-expanded="false">
                    All Site <span className="badge bg-danger">{prpos?.data?.total_page}</span>
                </Link>


                <Link className={`btn btn-outline-info ${prpos?.active == 24 ? 'text-white active' : 'text-dark bg-white'}`} to={`/offline_sites/24`} aria-expanded="false">
                    24 Hours <span className="badge bg-danger">{prpos?.data?.offline_sites?.length > 0 ? prpos?.data?.offline_sites[0]?.door_offline_status : 0}</span>
                </Link>


                <Link className={`btn btn-outline-info ${prpos?.active == 48 ? 'text-white active' : 'text-dark bg-white'}`} to={`/offline_sites/48`} aria-expanded="false">
                    48 Hours <span className="badge bg-danger">{prpos?.data?.offline_sites?.length > 0 ? prpos?.data?.offline_sites[0]?.site_offline_fourteen_eight : 0}</span>
                </Link>


                <Link className={`btn btn-outline-info ${prpos?.active == 72 ? 'text-white active' : 'text-dark bg-white'}`} to={`/offline_sites/72`} aria-expanded="false">
                    72 Hours <span className="badge bg-danger">{prpos?.data?.offline_sites?.length > 0 ? prpos?.data?.offline_sites[0]?.site_offline_seventy_two : 0}</span>
                </Link>

            </div>
        </>
    )
}