import { Link } from "react-router-dom";

export default function SiteAlarmNav(props){

    return (
        <>
         <ul className="dc_nav">
                    <li className={`cus-tab ${props.id==1?'active':''}`}><Link to={`/site/${props.site_id}/alarms`}>ACS Alarms</Link></li>
                    <li className={`cus-tab ${props.id==2?'active':''}`}><Link to={`/site/${props.site_id}/alarms/rms`}>RMS Alarms</Link></li>
                </ul>
        </>
    )
}