import { useEffect } from "react";
import { SITE_ID_FETCH_SUCCESS } from "../../../../Reducer/SiteIdSlice";
import DcTab from "../DcTab";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import API from "../../../../Network/API";
import ReactEcharts from "echarts-for-react";
import { useRef } from "react";
import ExportButton from "../../../../CustomHook/ExportButton";

export default function DcTenant() {
    const [nxtPageAlarm, setNextPageAlarm] = useState(null);
    const [dateValue, setDateChange] = useState(new Date().toISOString().slice(0, 10))
    const [to_date, setToDateChange] = useState()
    const [date_list, setDateList] = useState()
    const [month_data, setMonthData] = useState()
    const [get_data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const ddd = useParams();
    const dispatch = useDispatch()
    const [step, setStepNumber] = useState(1)
    const year = (new Date()).getFullYear();
    const yearLast = 2022;
    const years = Array.from(new Array(20), (val, index) => index + yearLast);
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const echarts_react_1 = useRef();
    const [month_id, setMonthId] = useState(currentMonth)
    const [year_id, setYear] = useState(year)

    useEffect(() => {
        setLoading(true);
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))

        API.get('/v1/oauth/dc_tenant_overview?site_id=' + ddd.id).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setLoading(false);
            setData(response.data.data)
        }).catch((error) => {
            setLoading(false);
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });

    }, [ddd.id]);



    const searchLog = () => {
        setLoading(true);
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/dc_tenant_monthly_graph?site_id=' + ddd.id + '&month=' + month_id + '&year=' + year_id)
            .then((response) => {
                setMonthData(response.data)
                if (loaderElement) {
                    if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                        loaderElement.setAttribute('hidden', 'true');
                    }
                }
            }).catch((error) => {
                if (loaderElement) {
                    if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                        loaderElement.setAttribute('hidden', 'true');
                    }
                }
            });
    }


    const searchReportLog = () => {
        setLoading(true);
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/report_wise_kwh?site_id=' + ddd.id + '&month=' + month_id + '&year=' + year_id)
            .then((response) => {
                setDateList(response.data.data)
                if (loaderElement) {
                    if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                        loaderElement.setAttribute('hidden', 'true');
                    }
                }
            }).catch((error) => {
                if (loaderElement) {
                    if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                        loaderElement.setAttribute('hidden', 'true');
                    }
                }
            });
    }

    const setStep = (number) => {
        setStepNumber(number)
        if (number == 2) {
            load_month_graph(month_id, year_id)
        } else if (number == 3) {
            load_month_kwh_report(month_id, year_id)
        }
    }

    const load_month_graph = (monthId, yearId) => {
        API.get('/v1/oauth/dc_tenant_monthly_graph?site_id=' + ddd.id + '&month=' + monthId + '&year=' + yearId)
            .then((response) => {
                setMonthData(response.data)

            }).catch((error) => {

            });
    }

    const load_month_kwh_report = (monthId, yearId) => {
        API.get('/v1/oauth/report_wise_kwh?site_id=' + ddd.id + '&month=' + monthId + '&year=' + yearId)
            .then((response) => {
                setDateList(response.data.data)

            }).catch((error) => {

            });
    }

    const option_tent = {
        title: {
            text: 'Tenant Wise Graphical View',
            subtext: 'Month of :' + month_id + ',' + year_id,
            left: 'center'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [{
            type: 'category',
            data: month_data?.tenant_name,
            axisTick: {
                alignWithLabel: true
            }
        }],
        yAxis: [{
            type: 'value'
        }],
        series: [{
            name: 'kWh',
            type: 'bar',
            barWidth: '60%',
            data: month_data?.tenant_kwh
        }]
    }

    return (
        <>
            <div className="row">
                <DcTab id={5} site_id={ddd.id} />
            </div>

            <div className="row ms-1 me-1">
                <div className="col-12 text-center">
                    <div class="btn-group btn-group-md">
                        <button type="button" class={`btn btn-outline-primary   ${step == 1 ? 'btn-primary text-white' : 'bg-white'}`} onClick={() => setStep(1)}>Overview</button>
                        <button type="button" class={`btn btn-outline-primary   ${step == 2 ? 'btn-primary text-white' : 'bg-white'}`} onClick={() => setStep(2)}>Graph</button>
                        <button type="button" class={`btn btn-outline-primary   ${step == 3 ? 'btn-primary text-white' : 'bg-white'}`} onClick={() => setStep(3)}>Report</button>
                    </div>
                </div>
                {step == 1 ?
                    <>
                        {!loading && get_data?.tenants?.map((tenant, index) => (
                            <div className="col-4">
                                <div className="card shadow-sm border-0 mt-2">
                                    <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">
                                        <b class="main-card-title mb-0 flex-grow-1">
                                            {tenant.tenantName}
                                        </b>
                                        <div class="flex-shrink-0">
                                            <span style={{ fontSize: '12px' }} className="text-main-color">{tenant?.month_name}</span>
                                            <p className="p-0 m-0" style={{ fontSize: '10px' }}>Last Update: {tenant.last_upadte}</p>
                                        </div>
                                    </div>
                                    <div className="card-body mt-0">
                                        <div className="row">
                                            <div className="col-12 mb-3 tnt-card">
                                                <table width={`100%`} className="text-center">
                                                    <tr>
                                                        <td className="td_border">
                                                            <p className="text-main-color p-0 m-0 font-bold">{tenant?.cc} A</p>
                                                            <p className="text-dark td-title p-0 m-0">CL</p>
                                                        </td>
                                                        <td className="td_border">
                                                            <p className="text-main-color p-0 m-0 font-bold">{tenant?.nc} A</p>
                                                            <p className="text-dark td-title p-0 m-0">NCL</p>
                                                        </td>
                                                        <td className="td_border">
                                                            <p className="text-main-color  p-0 m-0 font-bold">{tenant?.load} W</p>
                                                            <p className="text-dark td-title p-0 m-0">Load</p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <article class="d-flex justify-content-between">
                                                    <div class="stat-cards-icon bg-main-color text-white">
                                                        <i class="fa-regular fa-wave-pulse"></i>
                                                    </div>
                                                    <div class="stat-cards-info">
                                                        <p class="stat-cards-info__num p-0 m-0">{tenant?.today_cons} A</p>
                                                        <p class="stat-cards-info__title p-0 m-0">Today</p>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <article class="d-flex justify-content-between">
                                                    <div class="stat-cards-icon bg-main-color text-white">
                                                        <i class="fa-regular fa-wave-pulse"></i>
                                                    </div>
                                                    <div class="stat-cards-info">
                                                        <p class="stat-cards-info__num p-0 m-0">{tenant?.month_cons} A</p>
                                                        <p class="stat-cards-info__title p-0 m-0">Monthly(kWh)</p>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <article class="d-flex justify-content-between">
                                                    <div class="stat-cards-icon bg-main-color text-white">
                                                        <i class="fa-sharp fa-regular fa-clock"></i>
                                                    </div>
                                                    <div class="stat-cards-info">
                                                        <p class="stat-cards-info__num p-0 m-0">Total: {tenant?.total_oveload}</p>
                                                        <p class="stat-cards-info__num p-0 m-0">Time: {tenant?.total_oveload_sum}</p>
                                                        <p class="stat-cards-info__title p-0 m-0">Overloaded</p>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <article class="d-flex justify-content-between">
                                                    <div class="stat-cards-icon bg-main-color text-white">
                                                        <i class="fa-sharp fa-regular fa-clock"></i>
                                                    </div>
                                                    <div class="stat-cards-info">
                                                        <p class="stat-cards-info__num p-0 m-0">Total: {tenant?.total_disconnect}</p>
                                                        <p class="stat-cards-info__num p-0 m-0">Time: {tenant?.total_disonnect_sum}</p>
                                                        <p class="stat-cards-info__title p-0 m-0">Load Disconnected</p>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>

                    : step == 2 ?
                        <>
                            <div className="col-12" >
                                <div className="card shadow-sm border-0 bg-cc mt-2">
                                    <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                                        <b class="main-card-title mb-0 flex-grow-1">Graph view</b>
                                        <div class="flex-shrink-0">

                                            <div class="input-group input-group-sm">
                                                <select className="from-control" onChange={(e) => setYear(e.target.value)}>
                                                    {years?.map((value, index) => (
                                                        <option key={`year${index}`} value={value} selected={year == value ? true : false}>{value}</option>
                                                    ))}
                                                </select>
                                                <select className="from-control" onChange={(e) => setMonthId(e.target.value)}>
                                                    {months.map((month, index) => (
                                                        <option key={index} value={month} selected={currentMonth == month ? true : false}>{month}</option>
                                                    ))}
                                                </select>
                                                <button className="btn btn-success" onClick={() => searchLog()}><i className="fa-light fa-search"></i></button>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="card-body bg-white" >

                                        <ReactEcharts notMerge={true} ref={echarts_react_1} style={{ height: "300px" }} option={option_tent} />


                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>

                            <div className="col-12" >
                                <div className="card shadow-sm border-0 bg-cc mt-2">
                                    <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                                        <b class="main-card-title mb-0 flex-grow-1">DC kWh</b>
                                        <div class="flex-shrink-0">

                                            <div class="input-group input-group-sm">
                                                <select className="from-control" onChange={(e) => setYear(e.target.value)}>
                                                    {years?.map((value, index) => (
                                                        <option key={`year${index}`} value={value} selected={year == value ? true : false}>{value}</option>
                                                    ))}
                                                </select>
                                                <select className="from-control" onChange={(e) => setMonthId(e.target.value)}>
                                                    {months.map((month, index) => (
                                                        <option key={index} value={month} selected={currentMonth == month ? true : false}>{month}</option>
                                                    ))}
                                                </select>
                                                <button className="btn btn-success" onClick={() => searchReportLog()}><i className="fa-light fa-search"></i></button>
                                                <ExportButton className="btn btn-info" tableId="myTable" fileName="monthly_data"/>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="card-body bg-white" >

                                        <table className="table table-bordered table-striped m-0 table-sm bg-white text-center" id="myTable">
                                            <thead >
                                                <tr>
                                                    <th className="toi-primary">Date</th>
                                                    {date_list?.tenants.length > 0 && date_list?.tenants.map((tnent, index) => (
                                                        <th className="toi-primary">{tnent.tenant_name}(kWh)</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {date_list?.dateList?.length > 0 && date_list?.dateList?.map((dateL, index) => (
                                                    <tr>
                                                        <td>{dateL}</td>
                                                        {date_list?.tenants.length > 0 && date_list?.tenants.map((tnent, index) => (
                                                            <th>
                                                                {date_list?.tenant_daily_log.length > 0 && date_list?.tenant_daily_log.map((tentValue, index) => (
                                                                    <>
                                                                        {tentValue.date==dateL && tnent.tenant_id==tentValue.tenant_id?tentValue.kwh:''}
                                                                    </>
                                                                ))}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>

                                    </div>
                                </div>
                            </div>

                        </>
                }
            </div>
        </>
    )
}