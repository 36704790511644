import { useEffect } from "react";
import { SITE_ID_FETCH_SUCCESS } from "../../../Reducer/SiteIdSlice";
import { RECTIFIER_ID_FETCH_SUCCESS } from "../../../Reducer/RectifierIDSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import RectifierNav from "./RectifierNav";
import API from "../../../Network/API";
import RectifierAllLogReport from "./report/RectifierAllLogReport";
import exportTableToExcel from "../../../CustomHook/exportTableToExcel";
import exportTableToPdf from "../../../CustomHook/exportTableToPdf";

export default function SiteRectifierReport() {
    const ddd = useParams();
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const [duration_type, setDurationType] = useState(1)
    const [report_type, setReportType] = useState(1)
    const [data, setData] = useState()
    const [to_date, setDateCons2] = useState('')
    const [dateCons, setDateCons] = useState(new Date().toISOString().slice(0, 10))
    useEffect(() => {
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
        dispatch(RECTIFIER_ID_FETCH_SUCCESS(ddd.rid))

    }, [ddd.id]);

    const syncData = () => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get(`/v1/oauth/rectifier_app_report?site_id=${ddd.id}&sl_no=${ddd.rid}&report_type=${report_type}&duration_type=${duration_type}&start_date=${dateCons}&end_date=${to_date}`).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setData(response.data.data)
        }).catch((error) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
    }

    const handleExportToExcel = () => {
        if(report_type==1){
            exportTableToExcel('all_data', 'All_Data');
        }else if(report_type==2){
            
        }
      };

    const handleExportToPdf = () => {
        if(report_type==1){
            exportTableToPdf('all_data', 'All_Data.pdf');
        }

        else if(report_type==2){
          
        }
       
      };

    return (
        <>
            <div className="row">
                <RectifierNav id={3} site_id={ddd.id} acm_id={ddd.rid} />
            </div>

            <div className="row ms-1 me-1 mb-2">
                <div className="col-md-12 mb-2">
                    <div class="align-items-center d-flex ">
                        <h6 class="main-card-title mb-0 flex-grow-1">
                            Report

                        </h6>
                        <div class="flex-shrink-0 text-end">

                            <div class="input-group input-group-sm">
                                <select className="form-select" onChange={(e)=>setDurationType(e.target.value)}>
                                  
                                    <option value={1}>Daily</option>
                                    <option value={2}>Date Range</option>
                               
                                </select>

                                <input value={dateCons} type="date" class="form-control" onChange={(e) => setDateCons(e.target.value)} />
                                {duration_type==2?
                                <>
                                <span class="input-group-text" >To</span>
                                <input type="date" class="form-control" onChange={(e) => setDateCons2(e.target.value)} />
                               
                                </>
                               :''}
                               <select className="form-select" onChange={(e)=>setReportType(e.target.value)}>
                                  
                                    <option value={1}>All Data</option>
                                    {/* <option value={2}>DC Voltage</option>
                                    <option value={3}> AC Voltage</option>
                                    <option value={4}>AVG DC Voltage</option>
                                    <option value={5}>AVG AC Voltage</option> */}
                                </select>
                                <button class="btn btn-info input-group-text" onClick={()=>syncData()}>Search</button>
                                <button className="btn btn-sm btn-light dropdown">
                                    <a className="" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">Download</a>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{}}>
                                    <li><a class="dropdown-item" href="#" onClick={()=>handleExportToExcel()}><i class="fa-duotone fa-download"></i> Export</a></li>
                                            <li><a class="dropdown-item" href="#" onClick={() => window.print()}><i class="fa-duotone fa-print"></i> Print</a></li>
                                            <li><a class="dropdown-item" href="#" onClick={()=>handleExportToPdf()}><i class="fa-duotone fa-file-pdf"></i> Download as PDF</a></li>
                                        </ul>
                               </button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="card">
                    <div className="card-body">
                          <RectifierAllLogReport data={data}/>          
                    </div>
                </div>
            </div>

        </>
    )
}