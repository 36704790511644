import React from 'react';
export function Card({ title, value,icon,route,color }) {
    return (
        <div className={`card ${color} img-card custom-card`}>
        <div className="card-body">
          <div className="d-flex">
            <div className="text-white">
              <h2 className="mb-0 number-font">{value}</h2>
              <p className="text-white mb-0">{title} </p>
            </div>
            <div className="ms-auto">
              <i className={`${icon} text-white fs-24`} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  export const MemoCard = React.memo(Card);