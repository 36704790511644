import { createSlice } from '@reduxjs/toolkit';

export const doorDashboardSlice = createSlice({
    name:'doorDashboard',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers:{
        DOOR_DASHBOARD_FETCH_START:(state)=>{
            state.loading=true
        },
        DOOR_DASHBOARD_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload
        },
        EDIT_DOOR_DASHBOARD_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !==action.payload.id)
             state.main_data = [...newArray,action.payload]
        },
        DOOR_DASHBOARD_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { DOOR_DASHBOARD_FETCH_START,DOOR_DASHBOARD_FETCH_SUCCESS,DOOR_DASHBOARD_FETCH_ERROR,EDIT_DOOR_DASHBOARD_FETCH_SUCCESS }=doorDashboardSlice.actions;
export default doorDashboardSlice.reducer;