
import { createSlice } from '@reduxjs/toolkit';

export const batteryIDSlice = createSlice({
    name: 'batteryID',
    initialState: {
        loading: false,
        data: {

        },
        error: false,
    },
    reducers: {
        BATTERY_ID_FETCH_START: (state) => {
            state.loading = true
        },
        BATTERY_ID_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        BATTERY_ID_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { BATTERY_ID_FETCH_START, BATTERY_ID_FETCH_ERROR, BATTERY_ID_FETCH_SUCCESS } = batteryIDSlice.actions;
export default batteryIDSlice.reducer;