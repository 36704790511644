import { useEffect, useRef } from "react";
import { SITE_ID_FETCH_SUCCESS } from "../../../../Reducer/SiteIdSlice";
import DcTab from "../DcTab";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import API from "../../../../Network/API";
import ReactEcharts from "echarts-for-react";
import ExportButton from "../../../../CustomHook/ExportButton";

export default function DcConsumption() {
    const [nxtPageAlarm, setNextPageAlarm] = useState(null);
    const [dateValue, setDateChange] = useState(new Date().toISOString().slice(0, 10))
    const currentDate = new Date();
    const monthId = currentDate.getMonth() + 1
    const startOfMonth = monthId < 10 ? `0${monthId}` : monthId
    const [start_date, setSatrtDateChange] = useState('2023-' + startOfMonth + '-01')
    const [to_date, setToDateChange] = useState(new Date().toISOString().slice(0, 10))
    const [dc_cons, setDcData] = useState()
    const [dc_cons_graph, setDcConsGraph] = useState()
    const [dc_cons_report, setDcConsReport] = useState()
    const [loading, setLoading] = useState(false)
    const ddd = useParams();
    const dispatch = useDispatch()
    const [step, setStepNumber] = useState(1)
    const echarts_react_1 = useRef();
    const setStep = (number) => {
        setStepNumber(number)
        if (number == 2) {
            load_report()
        }
    }

    const searchGraph = () => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/dc_consumtion_graph?site_id=' + ddd.id + '&date=' + start_date + '&to_date=' + to_date).then((response) => {
            setLoading(false);
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setDcConsGraph(response.data.data)
        }).catch((error) => {
            setLoading(false);
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });

    }


    const searchDcCons = () => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/dc_consumtion_overview?site_id=' + ddd.id + '&date=' + dateValue).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setLoading(false);
            setDcData(response.data.data)
        }).catch((error) => {
            setLoading(false);
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
    }

    useEffect(() => {

        setLoading(true);
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))

        API.get('/v1/oauth/dc_consumtion_overview?site_id=' + ddd.id + '&date=' + dateValue).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setLoading(false);
            setDcData(response.data.data)
        }).catch((error) => {
            setLoading(false);
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });


        API.get('/v1/oauth/dc_consumtion_graph?site_id=' + ddd.id + '&date=' + start_date + '&to_date=' + to_date).then((response) => {
            setLoading(false);
            setDcConsGraph(response.data.data)
        }).catch((error) => {
            setLoading(false);
        });


        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
    }, [ddd.id]);


    const load_report = () => {
        API.get('/v1/oauth/dc_consumtion_report?site_id=' + ddd.id + '&date=' + start_date + '&to_date=' + to_date).then((response) => {
            setLoading(false);
            setDcConsReport(response.data.data)
        }).catch((error) => {
            setLoading(false);
        });
    }


    const option2 = {
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {},
        toolbox: {
            show: true,
            feature: {

                dataView: { readOnly: false },
                magicType: { type: ['line', 'bar'] },
                restore: {},
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: dc_cons_graph?.date
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} %'
            }
        },
        series: [
            {
                name: 'Grid',
                type: 'line',
                data: dc_cons_graph?.grid,
                markPoint: {
                    data: [
                        { type: 'max', name: 'Max' },
                        { type: 'min', name: 'Min' }
                    ]
                },
                markLine: {
                    data: [{ type: 'average', name: 'Avg' }]
                }
            },
            {
                name: 'Battery',
                type: 'line',
                data: dc_cons_graph?.batt,
                markPoint: {
                    data: [{ name: 'Battery', value: -2, xAxis: 1, yAxis: -1.5 }]
                },
                markLine: {
                    data: [
                        { type: 'average', name: 'Avg' },
                        [
                            {
                                symbol: 'none',
                                x: '90%',
                                yAxis: 'max'
                            },
                            {
                                symbol: 'circle',
                                label: {
                                    position: 'start',
                                    formatter: 'Max'
                                },
                                type: 'max',
                                name: '最高点'
                            }
                        ]
                    ]
                }
            },
            {
                name: 'PG',
                type: 'line',
                data: dc_cons_graph?.pg,
                markPoint: {
                    data: [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
                },
                markLine: {
                    data: [
                        { type: 'average', name: 'Avg' },
                        [
                            {
                                symbol: 'none',
                                x: '90%',
                                yAxis: 'max'
                            },
                            {
                                symbol: 'circle',
                                label: {
                                    position: 'start',
                                    formatter: 'Max'
                                },
                                type: 'max',
                                name: '最高点'
                            }
                        ]
                    ]
                }
            }
        ]
    }

    return (
        <>
            <div className="row">
                <DcTab id={6} site_id={ddd.id} />
            </div>
            <div className="row ms-1 me-1">
                <div className="col-12">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="text-center" style={{ width: '90%' }}>
                            <div class="btn-group btn-group-md">
                                <button type="button" class={`btn btn-outline-primary   ${step == 1 ? 'btn-primary text-white' : 'bg-white'}`} onClick={() => setStep(1)}>Overview</button>
                                <button type="button" class={`btn btn-outline-primary   ${step == 2 ? 'btn-primary text-white' : 'bg-white'}`} onClick={() => setStep(2)}>Report</button>
                            </div>
                        </div>
                        <div class="flex-shrink-0">
                            {step == 1 ?
                                <div class="input-group input-group-sm">
                                    <input value={dateValue}
                                        autoFocus={true} onChange={(e) => setDateChange(e.target.value)} type="date" class="form-control" autocomplete="off" />
                                    <button className="btn btn-info" onClick={() => searchDcCons()}><i className="fa-light fa-search" /></button>

                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
            {step == 1 ?
                <div className="row ms-1 me-1">
                    <div className="col-md-4" >
                        <div className="card shadow-sm border-0 bg-cc mt-2">
                            <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                                <b class="main-card-title mb-0 flex-grow-1">Power Availability</b>

                            </div>
                            <div className="card-body bg-white" style={{ height: '260px' }}>


                                <div className="row">

                                    <div className="col-7">

                                        <div className="col-12 mb-3">
                                            <article class="d-flex justify-content-between">
                                                <div class="stat-cards-icon bg-main-color ">
                                                    <i class="fa-regular fa-car-battery  text-white"></i>
                                                </div>
                                                <div class="stat-cards-info">
                                                    <p class="stat-cards-info__num p-0 m-0">{dc_cons?.battery_availability}%</p>
                                                    <p class="stat-cards-info__title p-0 m-0">Battery</p>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <article class="d-flex justify-content-between">
                                                <div class="stat-cards-icon bg-main-color ">
                                                    <i class="fa-regular fa-wave-pulse  text-white"></i>
                                                </div>
                                                <div class="stat-cards-info">
                                                    <p class="stat-cards-info__num p-0 m-0">{dc_cons?.grid_availability}%</p>
                                                    <p class="stat-cards-info__title p-0 m-0">Grid</p>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="col-12">
                                            <article class="d-flex justify-content-between">
                                                <div class="stat-cards-icon bg-main-color">
                                                    <img src="/img/pg.png" style={{ height: '40px' }} />
                                                </div>
                                                <div class="stat-cards-info">
                                                    <p class="stat-cards-info__num p-0 m-0">{dc_cons?.pg_availability}%</p>
                                                    <p class="stat-cards-info__title p-0 m-0">PG/DG</p>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div class="pg_on_count bg-main-color shadow-dark text-white"><i class="fa-regular" >{dc_cons?.period * 100}<span style={{ fontSize: '12px' }}>%</span></i></div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-md-4" >
                        <div className="card shadow-sm border-0 bg-cc mt-2">
                            <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                                <b class="main-card-title mb-0 flex-grow-1">DC Consumption</b>

                            </div>
                            <div className="card-body bg-white" style={{ height: '260px' }}>


                                <div className="row">

                                    <div className="col-7">

                                        <div className="col-12 mb-3">
                                            <article class="d-flex justify-content-between">
                                                <div class="stat-cards-icon bg-main-color ">
                                                    <i class="fa-regular fa-car-battery  text-white"></i>
                                                </div>
                                                <div class="stat-cards-info">
                                                    <p class="stat-cards-info__num p-0 m-0">{dc_cons?.battery_cons} kWh</p>
                                                    <p class="stat-cards-info__title p-0 m-0">Battery</p>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <article class="d-flex justify-content-between">
                                                <div class="stat-cards-icon bg-main-color ">
                                                    <i class="fa-regular fa-wave-pulse  text-white"></i>
                                                </div>
                                                <div class="stat-cards-info">
                                                    <p class="stat-cards-info__num p-0 m-0">{dc_cons?.grid_cons} kWh</p>
                                                    <p class="stat-cards-info__title p-0 m-0">Grid</p>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="col-12">
                                            <article class="d-flex justify-content-between">
                                                <div class="stat-cards-icon bg-main-color">
                                                    <img src="/img/pg.png" style={{ height: '40px' }} />
                                                </div>
                                                <div class="stat-cards-info">
                                                    <p class="stat-cards-info__num p-0 m-0">{dc_cons?.pg_cons} kWh</p>
                                                    <p class="stat-cards-info__title p-0 m-0">PG/DG</p>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div class="pg_on_count bg-main-color shadow-dark text-white" style={{ height: '100px', width: '100px' }}><i class="fa-regular" style={{ fontSize: '20px', marginTop: '12px' }}>{dc_cons?.total_kwh}<span style={{ fontSize: '10px' }}>kWh</span></i></div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-md-4" >
                        <div className="card shadow-sm border-0 bg-cc mt-2">
                            <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                                <b class="main-card-title mb-0 flex-grow-1">DC Tenant Consumption</b>

                            </div>
                            <div className="card-body bg-white" style={{ height: '260px' }}>


                                <div className="row">

                                    <div className="col-7">

                                        {!loading && dc_cons?.tenants?.map((tName, index) => (
                                            <div className="col-12 mb-3">
                                                <article class="d-flex justify-content-between">
                                                    <div class="stat-cards-icon bg-main-color ">
                                                        <i class="fa-regular fa-wave-pulse  text-white"></i>
                                                    </div>
                                                    <div class="stat-cards-info">
                                                        <p class="stat-cards-info__num p-0 m-0">{tName.kwh} kWh</p>
                                                        <p class="stat-cards-info__title p-0 m-0">{tName.name}</p>
                                                    </div>
                                                </article>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-3">
                                        <div class="pg_on_count bg-main-color shadow-dark text-white" style={{ height: '100px', width: '100px' }}><i class="fa-regular" style={{ fontSize: '20px', marginTop: '12px' }}>{dc_cons?.total_kwh}<span style={{ fontSize: '10px' }}>kWh</span></i></div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12" >
                        <div className="card shadow-sm border-0 bg-cc mt-2">
                            <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">
                                <b class="main-card-title mb-0 flex-grow-1">Power Availability</b>
                                <div class="flex-shrink-0">

                                    <div class="input-group input-group-sm">
                                        <input value={start_date}
                                            autoFocus={true} onChange={(e) => setSatrtDateChange(e.target.value)} type="date" class="form-control" autocomplete="off" />

                                        <input value={to_date}
                                            autoFocus={true} onChange={(e) => setToDateChange(e.target.value)} type="date" class="form-control" autocomplete="off" />

                                        <button className="btn btn-info" onClick={() => searchGraph()}><i className="fa-light fa-search" /></button>

                                    </div>

                                </div>
                            </div>
                            <div className="card-body bg-white" >
                                <ReactEcharts notMerge={true} ref={echarts_react_1} style={{ height: "300px" }} option={option2} />

                            </div>
                        </div>
                    </div>

                </div>
                :
                <>
                    <div className="row ms-1 me-1">
                        <div className="card shadow-sm border-0 mt-2">
                            <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">
                                <b class="main-card-title mb-0 flex-grow-1">DC Consumption Report</b>
                                <div class="flex-shrink-0">

                                    <div class="input-group input-group-sm">
                                        <input value={start_date}
                                            autoFocus={true} onChange={(e) => setSatrtDateChange(e.target.value)} type="date" class="form-control" autocomplete="off" />

                                        <input value={to_date}
                                            autoFocus={true} onChange={(e) => setToDateChange(e.target.value)} type="date" class="form-control" autocomplete="off" />

                                        <button className="btn btn-info" onClick={() => load_report()}><i className="fa-light fa-search" /></button>
                                        <ExportButton tableId={`dc_cons_table`} fileName={`${ddd.id}_dc_consupmition_${start_date}_to_${to_date}`} className={`btn btn-success`}/>
                                    </div>

                                </div>
                            </div>
                            <div className="card-body bg-white" >
                                <div className="table-responsive">
                                <table align="center" id={`dc_cons_table`} className="table table-bordered table-striped m-0 table-sm bg-white text-center" >
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th colSpan={3}>Total</th>
                                            <th colSpan={3}>Grid</th>
                                            <th colSpan={3}>Battery</th>
                                            <th colSpan={3}>PG</th>
                                        </tr>
                                        <tr>
                                            <th className="toi-primary">Start Time</th>
                                            <th className="toi-primary">End Time</th>
                                            <th className="toi-primary">Period(%)</th>
                                            <th className="toi-primary">Run Hours (HH:MM:SS)</th>
                                            <th className="toi-primary">Total Consumption(kWh)</th>
                                            <th className="toi-primary">Avg. Consumption(kWh)</th>

                                            <th className="toi-primary">Run Hours (HH:MM:SS)</th>
                                            <th className="toi-primary">Consumption(kWh)</th>
                                            <th className="toi-primary">Avail(%)</th>

                                            <th className="toi-primary">Run Hours (HH:MM:SS)</th>
                                            <th className="toi-primary">Consumption(kWh)</th>
                                            <th className="toi-primary">Avail(%)</th>

                                            <th className="toi-primary">Run Hours (HH:MM:SS)</th>
                                            <th className="toi-primary">Consumption(kWh)</th>
                                            <th className="toi-primary">Avail(%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dc_cons_report?.data?.length > 0 && dc_cons_report?.data?.map((row, index) => (
                                            <tr>
                                                <td>{row.start_time}</td>
                                                <td>{row.end_time}</td>
                                                <td>{row.period}</td>
                                                <td>{row.total_run_hour}</td>
                                                <td>{row.total_kwh}</td>
                                                <td>{row.avg_kwh}</td>
                                                <td>{row.grid_run_hour}</td>
                                                <td>{row.grid_cons}</td>
                                                <td>{row.grid_availability}</td>
                                                <td>{row.battery_run_hour}</td>
                                                <td>{row.battery_cons}</td>
                                                <td>{row.battery_availability}</td>
                                                <td>{row.pg_run_hour}</td>
                                                <td>{row.pg_cons}</td>
                                                <td>{row.pg_availability}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            }
        </>
    )
}