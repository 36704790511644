import { useState } from "react";
import { toast } from "react-hot-toast";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import API from "../Network/API";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { USER_FETCH_START } from "../Reducer/profileSlice";
export default function DashboardSettingModel(props) {
    const { user } = useSelector((state) => state.profileReducer);
    const [loading, setLoading] = useState(false);
    const [step,setSteps]=useState(2)
    const modalRef1 = useRef()
    const dispatch=useDispatch();

    const setStep=(event)=>{
        if (event.target.checked) {
            setSteps(1)
        }else{
            setSteps(2)
        }
       
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        const fd = new FormData();
        fd.append(`dashboard_type`, event.target.requst_type.value)
        
        API.post('/v1/oauth/dashboard-change', fd)
            .then(response => {
                setLoading(false);
                dispatch(USER_FETCH_START(response.data.data))
                  props.handleShowB();
                setSteps(2)
                 toast.success(response.data.message);
            }).catch((error) => {
                setLoading(false);
                toast.error(error.response.data.message)
            });
    }
    return (
        <>
            <Modal
                isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
                ref={modalRef1} >
                <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                   Profile
                </ModalHeader>
                <ModalBody className="bg-light">
                    <div className="meeting-schedule-popup">
                        <form onSubmit={handleSubmit} id="add_country_form">
                        <div className="row mb-3">
                            <table>
                                <tr>
                                    <th>Organization Name</th>
                                    <td>{user?.vendor_name}</td>
                                </tr>
                                <tr>
                                    <th> Name</th>
                                    <td>{user?.name}</td>
                                </tr>
                                <tr>
                                    <th> Email</th>
                                    <td>{user?.email}</td>
                                </tr>
                            </table>
                       
              
                        </div>
                        <hr/>
                        <h6>Dashboard Settings</h6>
                            <div className="row mb-3 mt-2">
                               
                            <div className="col-12 mb-2 d-flex justify-content-between">
                                    <div class="form-check form-switch">
                                        <input defaultChecked={user?.dashboard_version==3?true:false} onChange={(e)=>setStep(e)} class="form-check-input" type="radio" id="flexSwitchCheckDefault2" name="requst_type" value={3}/>
                                        <label class="form-check-label" for="flexSwitchCheckDefault2">Dashboard V.1.0</label>
                                    </div>
                                    <img src="/img/dsh-1.png" width={200}/>
                                  
                                </div>
                                <div className="col-12 mb-2 d-flex justify-content-between">
                                    <div class="form-check form-switch">
                                        <input defaultChecked={user?.dashboard_version==2?true:false} onChange={(e)=>setStep(e)} class="form-check-input" type="radio" id="flexSwitchCheckDefault1" name="requst_type" value={2}/>
                                        <label class="form-check-label" for="flexSwitchCheckDefault1">Dashboard V.1.1</label>
                                    </div>
                                    <img src="/img/dsh-2.png" width={200}/>
                                </div>
                                <div className="col-12 mb-2 d-flex justify-content-between">
                                    <div class="form-check form-switch">
                                        <input defaultChecked={user?.dashboard_version==1?true:false} onChange={(e)=>setStep(e)} class="form-check-input" type="radio" id="flexSwitchCheckDefault" name="requst_type" value={1} />
                                        <label class="form-check-label" for="flexSwitchCheckDefault">Dashboard V.1.2</label>
                                    </div>
                                    <img src="/img/dsh-3.png" width={200}/>
                                </div>
                                <div className="col-12 mb-2 d-flex justify-content-between">
                                    <div class="form-check form-switch">
                                        <input defaultChecked={user?.dashboard_version==4?true:false} onChange={(e)=>setStep(e)} class="form-check-input" type="radio" id="flexSwitchCheckDefault4" name="requst_type" value={4} />
                                        <label class="form-check-label" for="flexSwitchCheckDefault4">Dashboard V.1.3</label>
                                    </div>
                                    <img src="/img/dsh-3.png" width={200}/>
                                </div>
                            
                            </div>
                            <ModalFooter className={'post-footer-comment-icon bg-light'}>

                                <button type="submit" className="btn btn-success rounded-pill"><i class="fa-light fa-paper-plane-top me-1"></i> Save Change{loading ? (
                                    <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                ) : (
                                    <span></span>
                                )}</button>

                            </ModalFooter>
                        </form>
                    </div>

                </ModalBody>
            </Modal>
        </>
    )
}