import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx'


const AcsExportButton = ({ tableId, fileName,className }) => {
  const exportToExcel = () => {
    const table = document.getElementById(tableId);
    const workbook = XLSX.utils.table_to_book(table);
    const cellStyle = {
      alignment: {
        wrapText: true
      }
    };
    // const range = XLSX.utils.decode_range(workbook);
    // const range = XLSX.utils.decode_range(workbook.Sheets['!ref']);
    // workbook["A1"].s = {
    //   font: {
    //     color: { rgb: "FF0187FA" },
    //     bold: true,
    //   },
    //   alignment: {
    //     horizontal: "center",
    //   },
    // };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <button type='button' onClick={exportToExcel} className={className}><i class="fa-duotone fa-download me-2"></i> Export</button>
  );
};

export default AcsExportButton;
