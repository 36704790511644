import { useState } from "react";
import { toast } from "react-hot-toast";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import { useDispatch } from "react-redux";

import { useRef } from "react";
import API from "../../../Network/API";
import { ADD_ASSET_SUCCESS } from "../../../Reducer/SiteAssetSlice";
export default function AddAsset(props){
    const modalRef1 = useRef()
    const dispatch=useDispatch();
    const [loading, setLoading] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        const fd = new FormData();
        fd.append(`site_id`, props.site_id)
        fd.append(`asset_number`, event.target.asset_number.value)
        fd.append(`asset_name`, event.target.asset_name.value)
        fd.append(`brand`, event.target.brand.value)
        fd.append(`model`, event.target.model.value)
        fd.append(`serial_number`, event.target.serial_number.value)
        fd.append(`supplier_name`, event.target.supplier_name.value)
        fd.append(`qty`, event.target.qty.value)
        fd.append(`details`, event.target.details.value)

        API.post('/v1/oauth/asset/save', fd)
            .then(response => {
                setLoading(false);
                // console.log(response.data.data)
                  dispatch(ADD_ASSET_SUCCESS(response.data.data))
                  props.handleShowB();
                 document.getElementById("add_country_form").reset()
                 toast.success(response.data.message);
            }).catch((error) => {
                setLoading(false);
                toast.error(error.response.data.message)
            });

    }
    return (
        <>
        <Modal
                isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
                ref={modalRef1} >
                <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                    Add Asset ({props.site_id})
                </ModalHeader>
                <ModalBody className="bg-light">
                    <div className="meeting-schedule-popup">
                        <form onSubmit={handleSubmit} id="add_country_form">
                            <div className="row mb-3">
                               

                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="asset_number" className="form-label form-label-font-14">Asset Number</label>
                                    <input type="text" className="rounded-0 form-control" name="asset_number" required />
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="asset_name" className="form-label form-label-font-14">Product Name/Type *</label>
                                    <input type="text" className="rounded-0 form-control" name="asset_name" required />
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="brand" className="form-label form-label-font-14">Brand</label>
                                    <input type="text" className="rounded-0 form-control" name="brand" />
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="model" className="form-label form-label-font-14">Model</label>
                                    <input type="text" className="rounded-0 form-control" name="model" />
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="serial_number" className="form-label form-label-font-14">Serial Number</label>
                                    <input type="text" className="rounded-0 form-control" name="serial_number" />
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="supplier_name" className="form-label form-label-font-14">Supplier Name</label>
                                    <input type="text" className="rounded-0 form-control" name="supplier_name" />
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="qty" className="form-label form-label-font-14">QTY</label>
                                    <input type="text" className="rounded-0 form-control" name="qty" />
                                </div>
                                <div className={`col-6 mb-2`}>
                                    <label htmlFor="installation_date" className="form-label form-label-font-14">Installation Date</label>
                                    <input type="date" className="rounded-0 form-control" name="installation_date" />
                                </div>
                                <div className={`col-12 mb-2`}>
                                    <label htmlFor="details" className="form-label form-label-font-14">Details</label>
                                    <textarea  type="text" className="rounded-0 form-control" name="details" />
                                </div>

                            </div>
                            <ModalFooter className={'post-footer-comment-icon bg-light'}>

                                <button type="submit" className="btn btn-success rounded-pill"><i class="fa-light fa-save me-1"></i> Save{loading ? (
                                    <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                ) : (
                                    <span></span>
                                )}</button>

                            </ModalFooter>
                        </form>
                    </div>

                </ModalBody>
            </Modal>
        </>
    )
}