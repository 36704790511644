import { useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

export default function SiteFilterModel(props) {
    const [loading, setLoading] = useState(false);
    const handleSubmit = () => {

    }
    return (
        <>
            <Modal
                isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
            >
                <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                Quick filters
                </ModalHeader>
                <ModalBody className="bg-light">
                    <div className="meeting-schedule-popup">
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-3">
                                <div className="col-6 mb-2">
                                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Cluster</label>
                                    <select className="form-control-sm">
                                        <option></option>
                                    </select>
                                </div>
                                <div className="col-6 mb-2">
                                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Zone</label>
                                    <select className="form-control-sm">
                                        <option></option>
                                    </select>
                                </div>

                                <div className="col-6 mb-2">
                                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Tenant</label>
                                    <select className="form-control-sm">
                                        <option></option>
                                    </select>
                                </div>
                                <div className="col-6 mb-2">
                                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Site Type</label>
                                    <select className="form-control-sm">
                                        <option></option>
                                    </select>
                                </div>
                                <div className="col-6 mb-2">
                                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Alarm Type(RMS)</label>
                                    <select className="form-control-sm">
                                        <option></option>
                                    </select>
                                </div>
                                <div className="col-6 mb-2">
                                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Alarm Type(ACS)</label>
                                    <select className="form-control-sm">
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                            <ModalFooter className={'post-footer-comment-icon bg-light'}>

                <button type="submit" className="btn btn-success rounded-pill"><i class="fa-light fa-paper-plane-top me-1"></i> Search{loading ? (
                  <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                ) : (
                  <span></span>
                )}</button>

              </ModalFooter>
                        </form>
                    </div>

                </ModalBody>
            </Modal>
        </>
    )

}