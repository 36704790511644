import { useDispatch } from "react-redux";
import BatteryNav from "./BatteryNav";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { SITE_ID_FETCH_SUCCESS } from "../../../Reducer/SiteIdSlice";
import BatteryCard from "./BatteryCard";
import Battery from "./Battery";
import API from "../../../Network/API";

export default function SiteBatteryHome() {
    const ddd = useParams();
    const [batteries, setBattery] = useState([])
    const [rms, setRms] = useState()
    const dispatch = useDispatch()
    useEffect(() => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');

        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
        API.get('/v1/oauth/bms_home/' + ddd.id).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            // console.log(response.data.data.rms)
            setRms(response.data.data.rms)
            setBattery(response.data.data.batteries)

        }).catch((error) => {

            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
    }, [ddd.id]);
    return (
        <>
            <div className="row">
                <BatteryNav id={1} site_id={ddd.id} />
            </div>
            <div className="row ms-1 me-1 mb-2">
                <div className="col-md-4" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-light"><i class="fa-light fa-circle-info me-1"></i>DC Voltage</div>
                        <div className="card-body bg-white" style={{ height: '230px' }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div class="pg_on_count bg-main-color shadow-dark text-white"><i class="fa-regular">{rms?.avlt}<span style={{ fontSize: '12px' }}>V</span></i></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                            <b class="main-card-title mb-0 flex-grow-1">Battery Health</b>
                            <div class="flex-shrink-0">

                                <span><i class="header-title2-1 bg-i-healthy"></i>Good</span>&nbsp;
                                <span><i class="header-title2-1 bg-i-subhealthy"></i>Sub-health</span> &nbsp;
                                <span><i class="header-title2-1 bg-i-abnormal"></i>Abnormal</span> &nbsp;
                                <span><i class="header-title2-1 bg-i-unknown"></i>Unknown</span>


                            </div>
                        </div>
                        <div className="card-body bg-white" style={{ height: '230px', overflow: 'auto' }}>
                            <div className="row">
                                {batteries?.length > 0 && batteries?.map((battery, index) => (
                                    <div class="col-sm-3 mb-2">
                                        <BatteryCard row={battery}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mt-2">
                    <div className="card">
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                            <b class="main-card-title mb-0 flex-grow-1">Battery List</b>

                        </div>
                        <div className="card-body m-0 p-0">
                            <table className="table mb-0  table-bordered alarm_table_custom">
                                <thead className="toi-primary">
                                    <tr>
                                        <th></th>
                                        <th>Battery Name</th>
                                        <th>SL.No</th>
                                        <th>Last Update</th>
                                        <th className="text-center">SoC</th>
                                        <th className="text-center">SoH</th>
                                        <th className="text-center">Pack Voltage</th>
                                        <th className="text-center">Pack  Current</th>
                                        <th className="text-center">Power(kW)</th>
                                        <th className="text-center">Bat Temp</th>
                                        <th className="text-center">Env Temp</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Alarm</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {batteries?.length > 0 && batteries?.map((row, index) => (
                                        <tr >
                                            <td>
                                            <i title={`${row?.is_online==1?'✅ Connected':'⛔️ Disconnected'}`} class={`fa-solid fs-14 ${row?.is_online==1?'fa-battery-bolt text-success':'fa-battery-exclamation text-danger'}`}></i>
                                            </td>
                                            <td className={row?.is_online==1?'text-dark':'text-dark'}>{row?.name}
                                            </td>
                                            <td>
                                                <Link to={`/site/${ddd?.id}/bms/${row?.battery_sl}/dashboard`}>{row?.battery_sl}</Link>
                                            </td>
                                            <td>{row?.device_time}</td>
                                            <td className="text-center">{row?.soc}%</td>
                                            <td className="text-center">{row?.soh}%</td>
                                            <td className="text-center">{row?.pack_voltage} V</td>
                                            <td className="text-center">{row?.pack_current} A</td>
                                            <td className="text-center">
                                            {row?.power} kW</td>
                                            <td className="text-center"> {row?.bat_temp}°C</td>
                                            <td className="text-center"> {row?.env_temp}°C</td>
                                            <td className="text-center">
                                            {row?.status==1?'Charging':'Discharging'}
                                            </td>
                                            <td className="text-center">
                                                <div className="alarm_icon  A1">
                                                    &nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}