import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Paginate from "../../CustomHook/Paginate";
import API from "../../Network/API";
import { VENDOR_USER_FETCH_SUCCESS } from "../../Reducer/VendorUserSlice";
import AddUser from "./AddUser";
import EditUser from "./EditUser";


export default function UserIndex() {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [nxtPage, setNextPage] = useState(null);
    const [modalDialog, setModalDialog] = useState(false);
    const [editmodalDialog, setEditModalDialog] = useState(false);
    const [data,setData]=useState()
    const [organigation,setOrganigation]=useState([])
    const toggleModal = () => {
        setModalDialog(!modalDialog)
    }

    const toggleModalEdit = (row) => {
        setEditModalDialog(!editmodalDialog)
        setData(row)
    }

    const paginate = (pageNumber) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/users?page=' + pageNumber).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(VENDOR_USER_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    };

    const previousPage = (nxtPage) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get(nxtPage).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(VENDOR_USER_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    }

    const nextPage = (nxtPage) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get(nxtPage).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(VENDOR_USER_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    }

    const syncData = (name) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/users?name='+name).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(VENDOR_USER_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    }

    useEffect(() => {
        
        syncData('')

        API.get('/v1/oauth/users/create').then((response) => {
            setOrganigation(response.data.data)
            
        }).catch((error) => {});

    }, []);

    const selector = useSelector((state) => state.vendorUserReducer)

    return (
        <>
            <div className="row text-center  mb-2 mt-2">
                <div className="d-flex justify-content-end">
                    <div class="input-group input-group-sm max-with-searbar">
                        <input onChange={(e)=> syncData(e.target.value)} type="text" class="form-control" placeholder="search by name...." />
                        <button class="btn btn-success" type="button"><i class="fa-light fa-search" /></button>
                    </div>
                    <button class="btn btn-success  ms-2 me-2" type="button" onClick={()=>toggleModal()}><i class="fa-light fa-plus me-1" />Add New</button>
                </div>

            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive-lg">
                        <table className="table table-bordered bg-white table-striped text-center" id="myTable">
                            <thead className="text-uppercase">
                                <tr>
                                    <th scope="col" className="text-center toi-primary">SL</th>
                                    <th scope="col" className="text-center toi-primary">Organization Name</th>
                                    <th scope="col" className="text-center toi-primary">Name</th>
        
                                    <th scope="col" className="text-center toi-primary">Phone Number</th>
                                    <th scope="col" className="text-center toi-primary">Card Number</th>
                                    <th scope="col" className="text-center toi-primary">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {!selector?.loading && selector?.main_data.length > 0 && selector?.main_data?.map((row, index) => (
                                <tr key={index}>
                                    <td className="text-center">{index} </td>
                                    <td> {row.vendor_name} </td>
                                    <td> {row.name} </td>
                                    <td> {row.phone}</td>
                                    <td>{row.card_number}</td>
                                    <td className="text-center">
                                        <a href="javascript:void(0);" className="btn btn-info btn-sm" onClick={()=>toggleModalEdit(row)}> <i className="fa fa-edit" /> Edit</a>
                                    </td>
                                </tr>
                                 ))}
                            </tbody>
                        </table>

                        {/* <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />
           */}
                    </div>
                </div>
            </div>

            <AddUser isModalVisible={modalDialog} handleShowB={() => setModalDialog(false)} organigation={organigation} />
            <EditUser isModalVisible={editmodalDialog} handleShowB={() => setEditModalDialog(false)} data={data} organigation={organigation}/>

                                
        </>
    )
}