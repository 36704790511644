
import { createSlice } from '@reduxjs/toolkit';

export const DoorSiteCodeSlice = createSlice({
    name:'DoorSiteCodeSlice',
    initialState:{
        loading: false,
        data: [],
        error: false,
    },
    reducers:{
        DOOR_SITE_CODE_FETCH_START:(state)=>{
            state.loading=true
            state.data=[]
        },
        DOOR_SITE_CODE_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=[...state.data,action.payload]
        },
        DOOR_SITE_CODE_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { DOOR_SITE_CODE_FETCH_START,DOOR_SITE_CODE_FETCH_SUCCESS,DOOR_SITE_CODE_FETCH_ERROR }=DoorSiteCodeSlice.actions;
export default DoorSiteCodeSlice.reducer;