import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../Network/API";
import Paginate from "../../CustomHook/Paginate";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useCallback } from "react";
import { RECTIFIER_FETCH_LOAD_MORE, RECTIFIER_FETCH_SUCCESS } from "../../Reducer/RectifierSlice";

export default function RectifierIndex() {
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
   
    const [isLoading, setLoading] = useState(false);
    const [main_data, setMainData] = useState([]);
    const [site_status, setStatus] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        syncData('');
    }, []);


    useEffect(() => {

        if(page!=1){
            all_load()
        }
       
    }, [page]);
    

     const observer = useRef();
     const lastElementRef = useCallback((element) => { 
         //element is the react element being referenced
         
         // disconnect observer set on previous last element
         if (observer.current) observer.current.disconnect();
           
         // if there's no more data to be fetched, don't set new observer
         if (!hasMore) return;
           
         // set new observer
         observer.current = new IntersectionObserver((entries) => {
           // increase page number when element enters (is intersecting with) viewport.
           // This triggers the pagination hook to fetch more items in the new page
           if (entries[0].isIntersecting && hasMore) setPage((prev) => prev + 1);
         });
       
         // observe/monitor last element
         if (element) observer.current.observe(element);
       }, [hasMore]);


    const syncData = (name) => {
        setLoading(true)
        API.get('/v1/oauth/rectifier_monitoring?site_id=' + name).then((response) => {
            setPage(1)
            setHasMore(response.data.data.has_more_page)
            dispatch(RECTIFIER_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });
    }


    const all_load = () => {
        setLoading(true)
        API.get(`/v1/oauth/rectifier_monitoring?page=${page}`).then((response) => {
            dispatch(RECTIFIER_FETCH_LOAD_MORE(response.data.data))
            setMainData(response.data.data)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });
    }

    const selector = useSelector((state) => state.rectifierReducer)
    const strAscending = selector?.sites.length > 0 && [...selector?.sites].sort((a, b) =>
        a.site_id > b.site_id ? 1 : -1,
    );

    return (
        <>
            <div className="row text-center  mb-2 mt-2 me-1 ms-1">
                <div className="d-flex justify-content-end">
                    <div class="input-group input-group-sm max-with-searbar">
                        <input onChange={(e) => syncData(e.target.value)} type="text" class="form-control" placeholder="search by site id...." />
                        <button class="btn btn-success" type="button"><i class="fa-light fa-search" /></button>
                    </div>
                    <button onClick={() => syncData('')} class="btn btn-warning  ms-2" type="button" ><i class="fa-light fa-refresh me-1" />Sync</button>
                </div>
            </div>

            <div className="table-responsive scrol-table-height">
                <table className="table table-bordered table-striped m-0 table-sm bg-white text-center">
                    <thead>
                        <tr className="text-uppercase">
                            <th className="text-center toi-primary" rowSpan={2}>Updated</th>
                            <th className="text-center toi-primary" rowSpan={2}>SITE ID</th>
                            <th className="text-center toi-primary" rowSpan={2}>Easi SITE ID</th>
                            <th className="text-center toi-primary" rowSpan={2}>Rectifer Name</th>
                            <th className="text-center toi-primary" rowSpan={2}>Network</th>
                            <th className="text-center toi-primary w-20" rowSpan={2}>Mains Fail</th>
                            <th className="text-center toi-primary w-20" rowSpan={2}>DC Low</th>
                            <th className="text-center toi-primary w-20" rowSpan={2}>Module Fault</th>
                            <th className="text-center toi-primary w-20" rowSpan={2}>Fuse Fault</th>
                            <th className="text-center toi-primary w-20" rowSpan={2}>LLVD</th>
                            <th className="text-center toi-primary w-20" rowSpan={2}>BLVD</th>
                            <th className="text-center toi-primary" colSpan={2}>DC</th>
                            <th className="text-center toi-primary" colSpan={6}>Phase</th>
                            <th className="text-center toi-primary" rowSpan={2}>AC frequency</th>
                            <th className="text-center toi-primary" colSpan={2}>Cumulative Power</th>
                        </tr>
                        <tr>
                            <th className="text-center toi-primary">V</th>
                            <th className="text-center toi-primary">I</th>
                            <th className="text-center toi-primary">A(V)</th>
                            <th className="text-center toi-primary">B(V)</th>
                            <th className="text-center toi-primary">C(V)</th>
                            <th className="text-center toi-primary">A(I)</th>
                            <th className="text-center toi-primary">B(I)</th>
                            <th className="text-center toi-primary">C(I)</th>
                            <th className="text-center toi-primary">AC(kWh)</th>
                            <th className="text-center toi-primary">DC(kWh)</th>
                        </tr>
                    </thead>
                    <tbody>
                    {!selector?.loading && selector?.sites.length > 0 && strAscending?.map((site, index) => (
                            <tr className={`${site.device_live_status == 0 ? 'offline-tr' : ''}`}  ref={index === selector?.sites.length - 1 ? lastElementRef : undefined}>
                                <td>
                                <p className="m-0 p-0" style={{fontSize:'12px',width:'150px'}}>{site.update_time}</p>
                                        
                                </td>

                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.site_id}</Link>
                                </td>
                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.customer_site_id}</Link>
                                </td>

                                <td className="text-center">
                                    <a className="text-warning text-underline-hover" href="#"> {site.name}</a>

                                </td>
                                <td className="text-center">
                                    {site.device_live_status === 1 ?
                                        <div className="signal-bars mt1 sizing-box good four-bars">
                                            <span>
                                                <img src="/assets/icon/6.svg" className="tower-height" alt="" />
                                            </span>
                                            <div className={`first-bar bar ${site.network_val >= 1 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`second-bar bar ${site.network_val >= 2 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`third-bar bar ${site.network_val >= 3 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fourth-bar bar ${site.network_val >= 4 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fifth-bar bar ${site.network_val >= 5 ? '' : 'null-color'}`}>
                                            </div>
                                        </div>
                                        :
                                        <i class="fa-solid fa-wifi-slash offline-rms-icon" title="Site not updating" />
                                    }
                                </td>
                                <td className="text-center">
                                    <div className={`alarm_icon ${site.mains_fail==1?'A3':'A1'}`}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className={`alarm_icon ${site.dc_low==1?'A3':'A1'}`}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className={`alarm_icon ${site.rectifier_fault==1?'A3':'A1'}`}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className={`alarm_icon ${site.bat_load_fuse_fault==1?'A3':'A1'}`}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className={`alarm_icon   ${site.llvd==1?'A3':'A1'}`}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className={`alarm_icon   ${site.critical_disconnect==1?'A3':'A1'}`}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td className="text-center">
                                    {site.dc_voltage}
                                </td>
                                <td className="text-center">
                                {site.dc_current}
                                </td>
                                <td className="text-center">
                                {site.pahse_avoltage}
                                </td>
                                <td className="text-center">
                                {site.pahse_bvoltage}
                                </td>
                                <td className="text-center">
                                {site.pahse_cvoltage}
                                </td>
                                <td className="text-center">
                                {site.pahse_acurrent}
                                </td>
                                <td className="text-center">
                                {site.pahse_bcurrent}
                                </td>
                                <td className="text-center">
                                {site.pahse_ccurrent}
                                </td>
                                <td className="text-center">
                                {site.ac_frequency}
                                </td>
                                <td className="text-center">
                                {site.cum_ac_power}
                                </td>
                                <td className="text-center">
                                {site.cum_dc_power}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {isLoading && <div className="text-center">Loading......</div>}
            </div>
        </>
    )
}