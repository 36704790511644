import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { SITE_ID_FETCH_SUCCESS } from "../../../Reducer/SiteIdSlice";
import { useEffect } from "react";
import RectifierNav from "./RectifierNav";
import { RECTIFIER_ID_FETCH_SUCCESS } from "../../../Reducer/RectifierIDSlice";
import API from "../../../Network/API";
import { useState } from "react";
import RmsDashboardTrProps from "../../../Props/RmsDashboardTrProps";

export default function SiteRectifier() {
    const ddd = useParams();
    const dispatch = useDispatch()
    const [data, setData] = useState()

    useEffect(() => {
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
        dispatch(RECTIFIER_ID_FETCH_SUCCESS(ddd.rid))

        syncData();

    }, [ddd.id]);

    const syncData = () => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/rectifier_app?site_id=' + ddd.id + '&sl_no=' + ddd.rid).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setData(response.data.data)
        }).catch((error) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
    }

    return (
        <>
            <div className="row">
                <RectifierNav id={1} site_id={ddd.id} acm_id={ddd.rid} />
            </div>

            <div className="row ms-1 me-1 mb-2">

                <div className="col-xl-7 col-lg-7" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">
                            <b class="main-card-title mb-0 flex-grow-1">Overview</b>
                        </div>
                        <div className="card-body  bg-white">

                            <table className="table table-striped table-bordered table-centered mb-0 text-uppercase alarm_table_custom">
                                <tbody>
                                    <tr>
                                        <th>Cluster</th>
                                        <td>{data?.rms?.cluster?.name}</td>
                                        <th>Zone</th>
                                        <td>{data?.rms?.zone?.name}</td>
                                        <th>Rectifier SL</th>
                                        <td>{data?.rectifier?.rectifier_sl_no}</td>
                                    </tr>
                                    <tr>
                                        <th>Site ID</th>
                                        <td>{data?.rms?.site_id}</td>
                                        <th>Easi Site ID</th>
                                        <td>{data?.rms?.customer_site_id}</td>
                                        <th>Rectifier Name</th>
                                        <td>{data?.rectifier?.name} </td>
                                    </tr>
                                    <tr>
                                        <th>Site Category</th>
                                        <td>{data?.rms?.category?.name}</td>
                                        <th>Device SL</th>
                                        <td>{data?.rms?.device_id}</td>
                                        <th>Rectifier Brand</th>
                                        <td>{data?.rectifier?.brand_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Active SIM</th>
                                        <td>{data?.rms?.site?.phone_number}</td>
                                        <th width="20%">RMS Installation</th>
                                        <td>{data?.rms?.site?.installation_date}</td>
                                        <th>Update Time</th>
                                        <td width="20%">
                                            {data?.rectifier?.update_time}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <div className="col-xl-5 col-lg-5" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">
                            <b class="main-card-title mb-0 flex-grow-1">CONTROLLER SETTING</b>
                        </div>
                        <div className="card-body  bg-white">

                            <table className="table table-striped table-bordered table-centered mb-0 text-uppercase mt-1 alarm_table_custom text-center">
                                <thead className="toi-primary text-white">
                                    <tr>
                                        <th className="text-center">Name</th>
                                        <th className="text-center">Current Value</th>
                                        <th className="text-center">Unit</th>
                                        <th className="text-center">Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Number of Module</td>
                                        <td>{data?.rectifier?.num_module}</td>
                                        <td>PCS</td>
                                        <td>{data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>Blvd setting</td>
                                        <td>{data?.rectifier?.blvd_setting}</td>
                                        <td>V</td>
                                        <td>{data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>Llvd setting</td>
                                        <td>{data?.rectifier?.llvd_setting}</td>
                                        <td>V</td>
                                        <td>{data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>DC Low setting</td>
                                        <td>{data?.rectifier?.dc_low_setting}</td>
                                        <td>V</td>
                                        <td>{data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>Current Limit</td>
                                        <td>{data?.rectifier?.cur_limit}</td>
                                        <td>%</td>
                                        <td>{data?.rectifier?.update_time}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <div className="col-xl-7 col-lg-7" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">
                            <b class="main-card-title mb-0 flex-grow-1">DATA OVERVIEW</b>
                        </div>
                        <div className="card-body  bg-white">

                            <table className="table table-striped table-bordered table-centered mb-0  mt-1 text-center alarm_table_custom">
                                <thead className="toi-primary text-white">
                                    <tr>
                                        <th className="text-center">Name</th>
                                        <th className="text-center">Current Value</th>
                                        <th className="text-center">Unit</th>
                                        <th className="text-center">Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Phase Voltage(A)</td>
                                        <td>{data?.rectifier?.pahse_avoltage}</td>
                                        <td>V</td>
                                        <td> {data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>Phase Voltage(B)</td>
                                        <td>{data?.rectifier?.pahse_bvoltage}</td>
                                        <td>V</td>
                                        <td> {data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>Phase Voltage(C)</td>
                                        <td>{data?.rectifier?.pahse_cvoltage}</td>
                                        <td>V</td>
                                        <td> {data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>Phase Current(A)</td>
                                        <td>{data?.rectifier?.pahse_acurrent}</td>
                                        <td>A</td>
                                        <td> {data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>Phase Current(B)</td>
                                        <td>{data?.rectifier?.pahse_bcurrent}</td>
                                        <td>A</td>
                                        <td> {data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>Phase Current(C)</td>
                                        <td>{data?.rectifier?.pahse_ccurrent}</td>
                                        <td>A</td>
                                        <td> {data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>DC Voltage</td>
                                        <td>{data?.rectifier?.dc_voltage}</td>
                                        <td>V</td>
                                        <td> {data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>DC Current</td>
                                        <td>{data?.rectifier?.dc_current}</td>
                                        <td>A</td>
                                        <td> {data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>AC Frequency</td>
                                        <td>{data?.rectifier?.ac_frequency}</td>
                                        <td>Hz</td>
                                        <td> {data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>Cum DC Power</td>
                                        <td>{data?.rectifier?.cum_dc_power}</td>
                                        <td>kWh</td>
                                        <td> {data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>Cum AC Power</td>
                                        <td>{data?.rectifier?.cum_ac_power}</td>
                                        <td>kWh</td>
                                        <td> {data?.rectifier?.update_time}</td>
                                    </tr>
                                    <tr>
                                        <td>DC Active Power</td>
                                        <td>{data?.rectifier?.dc_active_power}</td>
                                        <td>kW</td>
                                        <td> {data?.rectifier?.update_time}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <div className="col-xl-5 col-lg-5" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">
                            <b class="main-card-title mb-0 flex-grow-1">ALARM OVERVIEW</b>
                        </div>
                        <div className="card-body  bg-white">

                            <table className="table table-striped table-bordered table-centered mb-0 text-uppercase mt-1 alarm_table_custom">
                                <thead className="toi-primary text-white">
                                    <tr>
                                        <th className="text-center">Alarm Name</th>
                                        <th className="text-center">Generated At</th>
                                        <th className="text-center">Elapsed Time</th>
                                        <th className="text-center">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <RmsDashboardTrProps title={`Mains Fail`} startDate={data?.rms?.mains_fail_time} status={data?.rms?.mains_fail} />
                                <RmsDashboardTrProps title={`DC Low`} startDate={data?.rms?.dc_low_time} status={data?.rms?.dc_low} /> 
                                <RmsDashboardTrProps title={`MODULE FAULT`} startDate={data?.rms?.rectifier_fault_time} status={data?.rms?.rectifier_fault} />   
                                <RmsDashboardTrProps title={`LLVD`} startDate={data?.rms?.llvd_start_time} status={data?.rms?.llvd} />   
                                <RmsDashboardTrProps title={`Batt/Load Fuse Fault`} startDate={data?.rms?.bat_load_fuse_fault_time} status={data?.rms?.bat_load_fuse_fault} />   
                                    
                             
                                
                                </tbody>
                            </table>


                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}