import { useEffect, useState, useRef } from "react";
import { SITE_ID_FETCH_SUCCESS } from "../../../Reducer/SiteIdSlice";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/dist";
import { ACM_ID_FETCH_SUCCESS } from "../../../Reducer/acmIDSlice";
import API from "../../../Network/API";
import ReactEcharts from "echarts-for-react";
import AcsNav from "./AcsNav";
export default function AcmGraph() {
    const ddd = useParams();
    const dispatch = useDispatch()
    const [data, setData] = useState()
    const [voltage_data, setVoltageData] = useState()
    const [current_data, setCurrentData] = useState()
    const echarts_react_1 = useRef();
    const echarts_react_2 = useRef();
    const [step, setStep1] = useState(0);
    const [dateCons, setDateCons] = useState(new Date().toISOString().slice(0, 10))
    const loaderElement1 = document.querySelector(".loading-mask");
    const setStep = (stp) => {
        setStep1(stp)
        if (stp == 0) {
            phase_voltage()
        } else if (stp == 1) {
            phase_current()
        }
    }

    const searchData = () => {

        loaderElement1.removeAttribute('hidden');
        loaderElement1.setAttribute('visible', 'true');

        if (step == 0) {
            phase_voltage()
        } else if (step == 1) {
            phase_current()
        }
    }

    useEffect(() => {
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
        dispatch(ACM_ID_FETCH_SUCCESS(ddd.acm_id))

        syncData()

    }, [ddd.id]);

    const syncData = () => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/acm_meter_app?site_id=' + ddd.id + '&acm_id=' + ddd.acm_id).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setData(response.data.data)
        }).catch((error) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
        setStep(0)
        // phase_voltage()

    }

    const phase_voltage = () => {
        API.get('/v1/oauth/phase_voltage?site_id=' + ddd.id + '&acm_id=' + ddd.acm_id + '&date=' + dateCons).then((response) => {
            setVoltageData(response.data.data)
            if (loaderElement1) {
                if (loaderElement1 && !loaderElement1.hasAttribute('hidden')) {
                    loaderElement1.setAttribute('hidden', 'true');
                }
            }
        }).catch((error) => {
            if (loaderElement1) {
                if (loaderElement1 && !loaderElement1.hasAttribute('hidden')) {
                    loaderElement1.setAttribute('hidden', 'true');
                }
            }
        });
    }

    const phase_current = () => {
        API.get('/v1/oauth/phase_current?site_id=' + ddd.id + '&acm_id=' + ddd.acm_id + '&date=' + dateCons).then((response) => {
            setCurrentData(response.data.data)
            if (loaderElement1) {
                if (loaderElement1 && !loaderElement1.hasAttribute('hidden')) {
                    loaderElement1.setAttribute('hidden', 'true');
                }
            }
        }).catch((error) => {
            if (loaderElement1) {
                if (loaderElement1 && !loaderElement1.hasAttribute('hidden')) {
                    loaderElement1.setAttribute('hidden', 'true');
                }
            }
        });
    }


    const option = {

        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Phase-1', 'Phase-2', 'Phase-3']
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            data: voltage_data?.array_time
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} V'
            }
        },
        series: [
            {
                name: 'Phase-1',
                type: 'bar',
                data: voltage_data?.array_voltage1
            },
            {
                name: 'Phase-2',
                type: 'bar',
                data: voltage_data?.array_voltage2
            },
            {
                name: 'Phase-3',
                type: 'bar',
                data: voltage_data?.array_voltage3
            },

        ]
    };
    const option1 = {

        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Phase-1', 'Phase-2', 'Phase-3']
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            data: current_data?.array_time
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} A'
            }
        },
        series: [
            {
                name: 'Phase-1',
                type: 'bar',
                data: current_data?.array_voltage1
            },
            {
                name: 'Phase-2',
                type: 'bar',
                data: current_data?.array_voltage2
            },
            {
                name: 'Phase-3',
                type: 'bar',
                data: current_data?.array_voltage3
            },

        ]
    };
    return (
        <>
            <div className="row">
                <AcsNav id={2} site_id={ddd.id} acm_id={ddd.acm_id} />
            </div>
            <div className="row ms-1 me-1 mb-2">
                <div className="col-md-12">
                    <div class="align-items-center d-flex ">
                        <h6 class="main-card-title mb-0 flex-grow-1">
                            ACM :{data?.acm?.acm_name}
                            <p className="p-0 m-0" style={{ fontSize: '10px' }}>Last Update: {data?.acm?.device_time}</p>
                        </h6>
                        <div class="flex-shrink-0 text-end">

                            <div class="input-group">
                                <input value={dateCons} type="date" class="form-control" onChange={(e) => setDateCons(e.target.value)} />
                                <button class="btn btn-info input-group-text" onClick={() => searchData()}>Search</button>
                                <button onClick={() => syncData('')} class="btn btn-warning" type="button" ><i class="fa-light fa-refresh me-1" />Sync</button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-md-12" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                            <b class="main-card-title mb-0 flex-grow-1"><i class="fa-light fa-bar-chart me-1"></i>  {step == 0 ? 'Daily Phase Voltage' : step == 1 ? 'Daily Phase Current' : step == 2 ? 'Daily Dataview' : ''}</b>
                            <div class="flex-shrink-0">
                                <div class="app-search">
                                    <div class="input-group">
                                        <div class="position-relative">
                                            <div class="dropdown">
                                                <a class="btn btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {step == 0 ? 'Daily Phase Voltage' : step == 1 ? 'Daily Phase Current' : step == 2 ? 'Daily Dataview' : ''}
                                                </a>

                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <li><a class="dropdown-item" href="#" onClick={() => setStep(0)}>Daily Phase Voltage</a></li>
                                                    <li><a class="dropdown-item" href="#" onClick={() => setStep(1)}>Daily Phase Current</a></li>
                                                    {/* <li><a class="dropdown-item" href="#" onClick={() => setStep(2)}>Daily Dataview</a></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-white">
                            <div className="row">
                                {step == 0 ?
                                    <ReactEcharts ref={echarts_react_1} style={{ height: "300px" }} option={option} />
                                    : step == 1 ?
                                        <ReactEcharts ref={echarts_react_2} style={{ height: "300px" }} option={option1} />
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}