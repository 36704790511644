import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";

import API from "../../../../Network/API";
import { SITE_ID_FETCH_SUCCESS } from "../../../../Reducer/SiteIdSlice";
import AcsExportButton from "../../../../CustomHook/AcsExportButton";
import SiteAlarmNav from "../SiteAlarmNav";
import CountdownTimer from "../../../../Props/CountdownTimer";


export default function RmsSiteAlarm() {
    const [nxtPageAlarm, setNextPageAlarm] = useState(null);
    const [dateValue, setDateChange] = useState('')
    const [to_date, setToDateChange] = useState('')
    const [get_data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [alarm_type, setAlarmType] = useState('')
    const [acs_nav, setNavData] = useState([])
    const ddd = useParams();
    const dispatch = useDispatch()
    useEffect(() => {
        setLoading(true);
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))

        API.get('/v1/oauth/site_rms_alarm?site_id=' + ddd.id).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setLoading(false);
            setData(response.data.data)
        }).catch((error) => {
            setLoading(false);
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });

        
        API.get('/v1/oauth/rms_site_wise_alarm_nav').then((response) => {
            setNavData(response.data.data)
        }).catch((error) => {
        });

    }, [ddd.id]);


    const clearSearch=()=>{
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/site_rms_alarm?site_id=' + ddd.id).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setLoading(false);
            setData(response.data.data)
        }).catch((error) => {
            setLoading(false);
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
    }

    const nextPage = (nxtPage) => {
        setLoading(true);
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        var pagea = nxtPage + '&site_id=' + ddd.id + '&start_date=' + dateValue + '&end_date=' + to_date+'&alarm_type='+alarm_type
        API.get(pagea).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setLoading(false);
            setData(response.data.data)

        }).catch((error) => {
            setLoading(false);
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
    }

    const search = () => {
        setLoading(true);
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        var pagea = '/v1/oauth/site_rms_alarm?site_id=' + ddd.id + '&start_date=' + dateValue + '&end_date=' + to_date+'&alarm_type='+alarm_type
        API.get(pagea).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setLoading(false);
            setData(response.data.data)

        }).catch((error) => {
            setLoading(false);
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
    }

    return (
        <>
            <div className="row">
                <SiteAlarmNav id={2} site_id={ddd.id} />
            </div>
            <div className="row ms-1 me-1 mb-1">
                <div class="align-items-center d-flex">
                    <b class="main-card-title mb-0 flex-grow-1"></b>
                    <div class="flex-shrink-0">
                        <div class="">
                            <div class="input-group input-group-sm">
                            <div class="custom-dropdown-grop-btn me-2">
                                    <select className="form-select" onChange={(e)=>setAlarmType(e.target.value)}>
                                        <option value={``}>Select Alarm Types</option>
                                        {acs_nav.alarm_types?.map((lock_alarm, index) => (
                                            <optgroup label={lock_alarm.name}>
                                               {lock_alarm.alarm?.map((type, index) => (
                                                <option value={type.order_list}>
                                                {type.name}
                                            </option>
                                              ))}
                                            </optgroup>
                                          
                                        ))}
                                    </select>
                                </div>
                                <input value={dateValue} autoFocus={true} onChange={(e) => setDateChange(e.target.value)} type="date" class="form-control" autocomplete="off" />
                                <span class="input-group-text">To</span>
                                <input value={to_date} autoFocus={true} onChange={(e) => setToDateChange(e.target.value)} type="date" class="form-control" placeholder="" autocomplete="off" />
                              <a href="#" onClick={() => search()} class=" input-group-text text-white bg-success"><i className="fa-light fa-search" /></a>
                                <a href="#" onClick={() => clearSearch()} class="input-group-text bg-warning"><i className="fa-light fa-sync" /></a>
                                <AcsExportButton className="btn btn-info" tableId="overload" fileName="rms_alarms" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ms-1 me-1">
                <div className="border-0 mt-2">
                    <div>
                        <table className="table table-bordered table-striped m-0 table-sm bg-white " id="overload">
                            <thead>
                                <tr>
                                    <th className="toi-primary text-center">Alarm Name</th>
                                    <th className="toi-primary text-center">Start Time</th>
                                    <th className="toi-primary text-center">End Time</th>
                                    <th className="toi-primary text-center">Elapsed Time</th>
                                    <th className="toi-primary text-center">Tenant</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading && get_data?.alarms?.map((row, index) => (
                                    <tr>
                                        <td className="fs-11 text-dark text-center">{row.name}</td>
                                        <td className="fs-11 text-dark text-center">{row.alarm_start}</td>
                                        <td className="fs-11 text-dark text-center">{row.alarm_end}</td>
                                        <td className="fs-11 text-dark text-center">
                                        {row.alarm_end != null ?
               <span className="elisp_time">{row.total_elaspsed}</span> 
                :
                <CountdownTimer targetDate={row.start} />
              }
                                        </td>
                                        <td className="fs-11 text-dark text-center">{row.tenant_name}</td>
                                      
                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                    {get_data?.has_page ?
                        <div class="float-md-end mb-1 mt-1">
                            <div class="btn-group ">
                                {get_data?.first_page ?
                                    <a class="btn btn-primary btn-sm" style={{ cursor: 'no-drop' }}>Prev</a>
                                    :
                                    <a class="btn btn-primary btn-sm " onClick={() => nextPage(get_data?.prv_page_url)}>Prev</a>
                                }

                                {get_data?.has_more_page ?
                                    <a class="btn btn-info btn-sm " onClick={() => nextPage(get_data?.next_page_url)}>Next</a>
                                    :
                                    <a class="btn btn-info btn-sm " style={{ cursor: 'no-drop' }}>Next</a>

                                }
                            </div>
                        </div> : ''
                    }
                </div>

            </div>

        </>
    )
}