export default function RmsAlarmReport({ loading, get_data, nextPage }) {

    return (
        <>
            <div className="row ms-1 me-1" id="RMS_Alarm">

              
                    <div className="table-responsive">
                        <table className="table table-bordered mt-2 bg-white " id="overload">
                            <thead>
                                <tr>
                                    <th className="toi-primary text-center">SITE ID</th>
                                    <th className="toi-primary text-center">Alarm Name</th>
                                    <th className="toi-primary text-center">Start Time</th>
                                    <th className="toi-primary text-center">End Time</th>
                                    <th className="toi-primary text-center">Elapsed Time</th>
                                    <th className="toi-primary text-center">Tenant</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading && get_data?.alarms?.map((row, index) => (
                                    <tr>
                                        <td className="fs-11 text-dark text-center">{row.site_id}</td>
                                        <td className="fs-11 text-dark text-center">{row.name}</td>
                                        <td className="fs-11 text-dark text-center">{row.alarm_start}</td>
                                        <td className="fs-11 text-dark text-center">{row.alarm_end}</td>
                                        <td className="fs-11 text-dark text-center">
                                            {row.alarm_end != null ?
                                                <span >{row.total_elaspsed}</span>
                                                :
                                                ''
                                            }
                                        </td>
                                        <td className="fs-11 text-dark text-center">{row.tenant_name}</td>


                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                    {get_data?.has_page ?
                        <div class="float-md-end mb-1 mt-1">
                            <div class="btn-group ">
                                {get_data?.first_page ?
                                    <a class="btn btn-primary btn-sm" style={{ cursor: 'no-drop' }}>Prev</a>
                                    :
                                    <a class="btn btn-primary btn-sm " onClick={() => nextPage(get_data?.prv_page_url)}>Prev</a>
                                }

                                {get_data?.has_more_page ?
                                    <a class="btn btn-info btn-sm " onClick={() => nextPage(get_data?.next_page_url)}>Next</a>
                                    :
                                    <a class="btn btn-info btn-sm " style={{ cursor: 'no-drop' }}>Next</a>

                                }
                            </div>
                        </div> : ''
                    }
                </div>

           
        </>
    )
}