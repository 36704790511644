import { home_overview } from "../Constants/EndPoint";
import { ACS_ACTIVE_ALARM_FETCH_SUCCESS } from "../Reducer/AcsActiveAlarmSlice";
import { Notify_FETCH_ERROR, Notify_FETCH_SUCCESS } from "../Reducer/NotifySlice";
import { RMS_ACTIVE_ALARM_HISTORY_FETCH_SUCCESS } from "../Reducer/RmsActiveAlarmHistorySlice";
import { RMS_ACTIVE_ALARM_FETCH_SUCCESS } from "../Reducer/RmsActiveAlarmSlice";
import { RMS_ALARM_GROUP_FETCH_SUCCESS } from "../Reducer/RmsAlarmGroupSlice";
import { RMS_STATION_FETCH_ERROR, RMS_STATION_FETCH_SUCCESS } from "../Reducer/RmsStationSlice";
import { TIMELINE_FETCH_SUCCESS } from "../Reducer/TimelineSlice";
import { HOME_FETCH_SUCCESS } from "../Reducer/homeSlice";
import API from "./API";
const limit = (count) => `page=${count}`;

const Notification = {
    all: (page, dispatch) =>
        API.get(`/v1/oauth/notifications?${limit(20, page)}`).then(response => {
            dispatch(Notify_FETCH_SUCCESS(response.data.data));
        }).catch((error) => {
            dispatch(Notify_FETCH_ERROR());
        }),

    latest: (dispatch) =>
        API.get(`/v1/oauth/notifications`).then(response => {
            dispatch(Notify_FETCH_SUCCESS(response.data.data));
        }).catch((error) => {
            dispatch(Notify_FETCH_ERROR());
        }),

    readAll: () =>
        API.post('/v1/oauth/notification-read-all'),
}

const All_RMS = {
    all: (dispatch) =>
        API.get(`/v1/oauth/rms_station?page=1`).then(response => {
            dispatch(RMS_STATION_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            dispatch(RMS_STATION_FETCH_ERROR());
        }),
}

const RMS_ALARM = {
    nav: (dispatch) =>
        API.get('/v1/oauth/rms_alarm_nav').then((response) => {
            dispatch(RMS_ALARM_GROUP_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {

        }),

    rms_active_alarm:(dispatch)=>    
    API.get('/v1/oauth/rms_active_alarm').then((response) => {
        dispatch(RMS_ACTIVE_ALARM_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
    }),

    acs_active_alarm:(dispatch)=>
    API.get('/v1/oauth/acs_active_alarm').then((response) => {
        dispatch(ACS_ACTIVE_ALARM_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
    }),
    timeline:(dispatch)=>
    API.get('/v1/oauth/timeline').then((response) => {
        dispatch(TIMELINE_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
    }),
    rms_active_alarm:(dispatch,id)=>
    API.get(`/v1/oauth/rms_filter_alarm/${id}`).then((response) => {
        dispatch(RMS_ACTIVE_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
    }),
}


const HOME = {
    overview: (dispatch) =>
        API.get(home_overview).then((response) => {
            const encryptedData = response.data.data;
            const decodedData = JSON.parse(atob(encryptedData));
            dispatch(HOME_FETCH_SUCCESS(decodedData))
        }).catch((error) => {
            if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                localStorage.clear();
                window.location.href = '/';
            }
        }),
        sync_equipment:(dispatch)=>
        API.get('/v1/oauth/home_equipment_disconnected').then((response) => {
            
        }).catch((error) => {
        })
}


export default {
    Notification,
    All_RMS,
    RMS_ALARM,
    HOME,
};