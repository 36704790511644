import { useDispatch } from "react-redux";
import AllLock from "../Component/Lock/AllLock";
import { DOOR_FETCH_SUCCESS } from "../Reducer/DoorSlice";
import { useState } from "react";
import API from "../Network/API";
import { useEffect } from "react";
import { LOCK_ALARM_FETCH_SUCCESS } from "../Reducer/LockAlarmListSlice";
import { RMS_ALARM_GROUP_FETCH_SUCCESS } from "../Reducer/RmsAlarmGroupSlice";

export default function AllSite(){
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        all_door()

        API.get('/v1/oauth/lock_alarm_nav').then((response) => {
          dispatch(LOCK_ALARM_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
        });

        API.get('/v1/oauth/rms_alarm_nav').then((response) => {
          dispatch(RMS_ALARM_GROUP_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
    
        });

      }, []);
    const all_door = () => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/all_door').then((response) => {
          if (loaderElement && !loaderElement.hasAttribute('hidden')) {
            loaderElement.setAttribute('hidden', 'true');
          }
          dispatch(DOOR_FETCH_SUCCESS(response.data.data))
          setLoading(false)
        }).catch((error) => {
          setLoading(true)
        });
        
    }
    return(
        <>
        <AllLock />
        </>
    )
}