import { createSlice } from '@reduxjs/toolkit';

export const RmsActiveAlarmHistorySlice = createSlice({
    name: 'RmsAlarmHistory',
    initialState: {
        loading: false,
        data: {

        },
        alarms:[],
        error: false,
    },
    reducers: {
        RMS_ACTIVE_ALARM_HISTORY_FETCH_START: (state) => {
            state.loading = true
        },
        RMS_ACTIVE_ALARM_HISTORY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.alarms=action.payload.alarms
        },
        RMS_ACTIVE_ALARM_HISTORY_LOAD_MORE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.alarms=[...state.alarms,...action.payload.alarms]
        },
        RMS_ACTIVE_ALARM_HISTORY_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { RMS_ACTIVE_ALARM_HISTORY_LOAD_MORE_FETCH_SUCCESS,RMS_ACTIVE_ALARM_HISTORY_FETCH_START, RMS_ACTIVE_ALARM_HISTORY_FETCH_SUCCESS, RMS_ACTIVE_ALARM_HISTORY_FETCH_ERROR } = RmsActiveAlarmHistorySlice.actions;
export default RmsActiveAlarmHistorySlice.reducer;