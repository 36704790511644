import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import API from "../Network/API";
import { useState } from "react";

import HomeGrid from "../CustomHook/HomeGrid";
import ReactEcharts from "echarts-for-react";
import { useRef } from "react";


import { Link } from "react-router-dom";
import { TIMELINE_FETCH_SUCCESS } from "../Reducer/TimelineSlice";
import Timeline from "../Props/Timeline";
import Agent from "../Network/Agent";

export default function HomeV3() {
    const echarts_react_1 = useRef();
    const echarts_react_2 = useRef();
    const echarts_react_3 = useRef();

    const dispatch = useDispatch();
    const [equip_data, setEqipmentData] = useState();

    useEffect(() => {
        const interval = setInterval(() => {
            Agent.HOME.overview(dispatch);
            sync_equipment();
            Agent.RMS_ALARM.timeline(dispatch);
        }, 300000); // 3 minutes

        // Clear the interval when the component is unmounted or when it no longer needs to run
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        Agent.HOME.overview(dispatch);
        sync_equipment();
        Agent.RMS_ALARM.timeline(dispatch);
    }, []);

   
    const sync_equipment = () => {
        API.get('/v1/oauth/home_equipment_disconnected').then((response) => {
            setEqipmentData(response.data.data)
        }).catch((error) => {
        });
    }


    const selector = useSelector((state) => state.homeReducer)
    const alarms = selector?.data

 
    const option = {
        color: ["#ffa91c", "#fd3b48", "#f96a74", "#ffc61c", "#33E6FF"],
        tooltip: {

            trigger: 'item',
            formatter: '{a} <br/>{b}',
            axisPointer: {
                animation: true
            },
            backgroundColor: "#333333e8",
            textStyle: {
                color: '#fff'
            }

        },
        legend: {
            top: '25%',
            left: '50%',
            orient: 'vertical',
            right: 'left',
            textStyle: {
                fontSize: 11
            },
            data: [
                `Mains Fail (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mains_fail : 0})`,
                `DC Low (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_low : 0})`,
                `MDB Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mdb_fault : 0})`,
                `Phase Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.phase_missing : 0})`,
                `PG Running (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.pg_status : 0})`,
            ],
            padding: [
                0, // up
                0, // right
                0, // down
                10, // left
            ]
        },
        series: [
            {
                name: 'Power',
                type: 'pie',
                selectedMode: 'single',
                radius: [0, 0],
                label: {
                    position: 'inner',
                    fontSize: 16,
                    color: '#000000'
                },
                labelLine: {
                    show: false
                },
                right: '35%',
                data: [{
                    value: 0,
                    name: 'Power'
                }]
            },
            {
                name: 'Power',
                type: 'pie',
                radius: ['35%', '60%'],
                label: {
                    show: false,
                    formatter: '{c}',
                    position: 'inside'
                },
                itemStyle: {
                    borderRadius: 1,
                    borderColor: '#fff',
                },
                labelLine: {
                    show: false
                },
                right: '35%',
                data: [{
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mains_fail : 0,
                    name: `Mains Fail (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mains_fail : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_low : 0,
                    name: `DC Low (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_low : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mdb_fault : 0,
                    name: `MDB Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mdb_fault : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.phase_missing : 0,
                    name: `Phase Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.phase_missing : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.pg_status : 0,
                    name: `PG Running (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.pg_status : 0})`
                }
                ]
            }
        ]
    };

    const option2 = {

        tooltip: {

            trigger: 'item',
            formatter: '{a} <br/>{b}',
            axisPointer: {
                animation: true
            },
            backgroundColor: "#333333e8",
            textStyle: {
                color: '#fff'
            }

        },
        legend: {
            top: '25%',
            left: '50%',
            orient: 'vertical',
            right: 'left',
            textStyle: {
                fontSize: 11
            },
            data: [
                `Smoke (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.smoke : 0})`,
                `DC Fan Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_fan_fault : 0})`,
                `Rectifier Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.rectifier_fault : 0})`,
                `Water Leakage (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.water_leakage : 0})`,
                `ODC Temp High (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.temp_high_sp : 0})`,
                `Door Open (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.door_open : 0})`,
            ],
            padding: [
                0, // up
                0, // right
                0, // down
                10, // left
            ]
        },
        series: [{
            name: 'Sensor',
            type: 'pie',
            selectedMode: 'single',
            radius: [0, 0],
            label: {
                position: 'inner',
                fontSize: 16,
                color: '#000000'
            },
            labelLine: {
                show: false
            },
            right: '35%',
            data: [{
                value: 0,
                name: 'Sensor'
            }]
        },
        {
            name: 'Sensor',
            type: 'pie',
            radius: ['35%', '60%'],
            label: {
                show: false,
                formatter: '{c}',
                position: 'inside'
            },
            itemStyle: {
                borderRadius: 1,
                borderColor: '#fff',
            },
            labelLine: {
                show: false
            },
            right: '35%',
            data: [
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.smoke : 0,
                    name: `Smoke (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.smoke : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_fan_fault : 0,
                    name: `DC Fan Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_fan_fault : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.rectifier_fault : 0,
                    name: `Rectifier Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.rectifier_fault : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.water_leakage : 0,
                    name: `Water Leakage (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.water_leakage : 0})`
                },

                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.temp_high_sp : 0,
                    name: `ODC Temp High (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.temp_high_sp : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.door_open : 0,
                    name: `Door Open (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.door_open : 0})`
                },
            ]
        }
        ]
    };

    const option3 = {
        color: ["#ffa91c", "#fd3b48", "#f96a74"],
        tooltip: {
            trigger: 'item',
            formatter: 'DC Energy Meter <br/>{b}',
            axisPointer: {
                animation: true
            },
            backgroundColor: "#333333e8",
            textStyle: {
                color: '#fff'
            }
        },
        legend: {
            top: '25%',
            left: '50%',
            orient: 'vertical',
            right: 'left',
            textStyle: {
                fontSize: 12
            },
            data: [
                `LLVD(${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.llvd : 0})`,
                `Overload(${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.overloaded : 0})`,
                `Site Down(${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.load_disconnect : 0})`,
            ],
            padding: [
                0, // up
                0, // right
                0, // down
                15, // left
            ]
        },
        series: [{
            name: 'DC',
            type: 'pie',
            selectedMode: false,
            radius: [0, 0],
            label: {
                position: 'inner',
                fontSize: 16,
                color: '#000000'
            },
            labelLine: {
                show: false
            },
            right: '35%',
            data: [{
                value: 0,
                name: 'DC'
            }]
        },
        {
            name: '',
            type: 'pie',
            radius: ['35%', '60%'],
            label: {
                show: false,
                formatter: '{c}',
                // fontSize: 16,
                position: 'inside'
            },
            avoidLabelOverlap: false,

            labelLine: {
                show: false
            },
            right: '35%',
            itemStyle: {
                borderRadius: 1,
                borderColor: '#fff',
            },
            data: [
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.llvd : 0,
                    name: `LLVD(${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.llvd : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.overloaded : 0,
                    name: `Overload(${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.overloaded : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.load_disconnect : 0,
                    name: `Site Down(${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.load_disconnect : 0})`
                }

            ]
        }
        ]
    };


    const selector_timeline = useSelector((state) => state.timelineSlice)

      const strAscending = selector_timeline?.data?.alarms?.length > 0 && [...selector_timeline?.data?.alarms].sort((a, b) =>
        a.created_at < b.created_at ? 1 : -1,
    );
    return (
        <>

            <div className="row g-2 mt-2 mb-2 me-1 ms-1">
                <HomeGrid title={`Total Site`} value={alarms?.total_sites} route={'#'} color={`bg-dark text-white`} icon={`fa-solid fa-map`} class={`shadow-dark`} valueColor={'text-dark'} />
                <HomeGrid title={`Total Lock`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.total_site : 0} route={`/sites`} color={`bg-info text-white`} icon={`fa-solid fa-lock`} class={`shadow-info`} valueColor={'text-info'} />
                <HomeGrid title={`Total Online`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.online_device : 0} route={'/online_sites'} color={`bg-green`} icon={`fa-regular fa-signal`} class={`shadow-green`} valueColor={'text-success'} />
                <HomeGrid title={`Total Offline`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.offline_device : 0} route={'/offline_sites'} color={`bg-secondary text-white`} icon={`fa-duotone fa-signal-slash`} class={`shadow-black`} valueColor={'text-gray'} />
                {/* <HomeGrid title={`Sensor Disconnected`} value={equip_data?.total_sensor_disconnect ?? 0} route={'#'} color={`bg-primary text-white`} icon={`fa-duotone fa-sensor-triangle-exclamation`} class={`shadow-primary`} /> */}
                <HomeGrid title={`Door Open`} value={alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.door_open : 0} route={`rms_alarm_filter/12/fatch`} color={`bg-primary text-white`} icon={`fa-regular fa-door-open`} class={`shadow-primary`} />
                {/* <HomeGrid title={`Door Open (ACS)`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.door_status : 0} route={'alarms?type=7'} color={`bg-purple text-white`} icon={`fa-regular fa-door-open`} class={`shadow-purple`} />  */}
                <HomeGrid title={`Lock Open`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.lock_open_status : 0} route={'alarms?type=2'} color={`bg-orange text-white`} icon={`fa-regular fa-lock-open`} class={`shadow-orange`} valueColor={'text-warning'} /> 
                <HomeGrid title={`Security Breach`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.breach_status : 0} route={'alarms?type=6'} color={`bg-red text-white`} icon={`fa-regular fa-user-secret`} class={`shadow-danger`} />
                
                <HomeGrid title={`Mains Fail`} value={alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mains_fail : 0} route={'rms_alarm_filter/1/fatch'} color={`bg-danger text-white`} icon={`fa-regular fa-transformer-bolt `} class={`shadow-red`} />
                <HomeGrid title={`DC Low`} value={alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_low : 0} route={'rms_alarm_filter/2/fatch'} color={`bg-sapphire text-white`} icon={`fa-light fa-battery-quarter`} class={`shadow-sapphire`} />
                <HomeGrid title={`Overload`} value={alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.overloaded : 0} route={'rms_alarm_filter/25/fatch'} color={`bg-warning text-white`} icon={`fa-regular fa-chart-line-up`} class={`shadow-orange`} valueColor={'text-warning'} />
                <HomeGrid title={`Site Down`} value={alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.load_disconnect : 0} route={'rms_alarm_filter/26/fatch'} color={`bg-purple text-white`} icon={`fa-regular fa-chart-line-down`} class={`shadow-purple`} valueColor={'text-warning'} />
                <HomeGrid title={`PG Running`} value={alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.pg_status : 0} route={'rms_alarm_filter/9/fatch'} color={`bg-danger text-white`} icon={`fa-regular fa-chart-line-down`} class={`shadow-purple`} valueColor={'text-danger'} />
            
            </div>


            <div className="row g-2 me-1 ms-1">
                <div className="col-md-8 ">
                    <div className="card">
                        <div className="card-header bg-white border-bottom"> <i class="fa-thin fa-pie-chart" /> RMS Alarm Analytics
                        </div>
                        <div className="card-body p-0 m-0" style={{ padding: '0.6rem' }}>
                            <div className="row">
                                <div className="col-md-4" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <ReactEcharts ref={echarts_react_1} style={{ height: "50vh" }} option={option} />

                                </div>
                                <div className="col-md-4" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <ReactEcharts ref={echarts_react_2} style={{ height: "50vh" }} option={option2} />
                                </div>

                                <div className="col-md-4" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <ReactEcharts ref={echarts_react_3} style={{ height: "50vh" }} option={option3} />
                                </div>
                            </div>
                        </div>
                    </div>

                
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">

                    <div className="card ">
     

                        <div class="card-header  border-bottom card-title align-items-center bg-white justify-content-between d-flex">
                                    <h6><i class="fa-light fa-bar-chart me-1" />Realtime Alarm ({!selector_timeline?.loading && selector_timeline?.data?.alarms?.length})</h6>
                                    <div class="flex-shrink-0">
                                        <div class="app-search">
                                        <Link to={'/timeline'}>See All</Link>
                                        </div>
                                    </div>
                                </div>
                        <div className="card-body p-0 m-0" style={{ padding: '0.6rem' }}>
                            <div className="scroll-side-nav">
                                <div className="timeline me-1 ms-1">
                                    <div className="line text-muted" />
                                    {!selector_timeline?.loading && selector_timeline?.data?.alarms?.length > 0 && strAscending?.map((timeAarm, index) => (
                                        <Timeline data={timeAarm} />
                                    ))}


                                    {selector_timeline?.data?.has_page ?
                                        <div>
                                            <div class="btn-group ">
                                                <button className="btn btn-sm btn-info">Load More</button>
                                            </div>
                                        </div> : ''
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
         

            </div>
            
        </>
    )
}