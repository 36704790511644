export default function ACPower({row}){
    return (
        <>
        <div className="card-box" style={{ marginBottom: '10px' }}>
                            <h4 className="header-title mb-0" style={{ padding: '3px', fontSize: '12px', paddingBottom: '10px' }}>AC Power
                            </h4>
                            <hr style={{ marginTop: '0px', marginBottom: '3px' }} />
                            <table className="table table-striped table-bordered table-centered mb-0 text-uppercase mt-1 alarm_table_custom">
                                <thead className="toi-primary text-white">
                                    <tr>
                                        <th className="text-left">Source</th>
                                        <th className="text-center"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-left">Power Factor</td>
                                        <td className="text-center">
                                           {row.powerfactor} kW
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Frequency</td>
                                        <td className="text-center">
                                        {row.freq} Hz
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Total Actvie forward</td>
                                        <td className="text-center">
                                        {row.actimport/1000} kWh
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
        </>
    )
}