import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    name:'nur'
  },
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    USER_FETCH_START: (state, action) => {
      state.user = action.payload.user;
    }
  },
});

export const { USER_FETCH_START } = profileSlice.actions;
export default profileSlice.reducer;