import HelperClass from "../../CustomHook/HelperClass";
export default function PowerAvailability({ loading, get_data, nextPage, request }) {
    return (
        <>
            <div className="ms-1 me-1" id="power_history">
                <div className="table-responsive bg-white">

                    <table width="100%" border={1} cellPadding={0} cellSpacing={0} className="table table-bordered mt-2 text-center">
                        <thead className="text-center">
                            <tr>
                                <th height={20} colSpan={20} align="center" valign="center" className="col" style={{ fontSize: '18px', fontWeight: 'bold' }}> Power Availability Report
                                </th>
                            </tr>
                            <tr>
                                <th height={20} colSpan={20} align="center" valign="center" className="col" style={{ fontSize: '13px', fontWeight: 'bold' }}> Date:{request?.duration_type == 1 ? request?.start_date : `${request?.start_date} To ${request?.end_date}`}
                                </th>
                            </tr>
                            <tr>
                                <th></th>

                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>

                                <th align="center" valign="center" class="col" >Total</th>
                                <th colspan="2" align="center" valign="center" class="col" >
                                    Grid Power</th>
                                <th colspan="2" align="center" valign="center" class="col" >
                                    Generator</th>
                                <th colspan="2" align="center" valign="center" class="col" >
                                    Battery</th>
                            </tr>
                            <tr>
                                <th align="center" valign="center" class="col1" >{HelperClass().CUSTOMER_SITE} Site ID</th>

                                <th align="center" valign="center" class="col1" >Site ID</th>

                                <th align="center" valign="center" class="col1" >Start Time</th>
                                <th align="center" valign="center" class="col1" >End Time</th>
                                <th align="center" valign="center" class="col1" >Period</th>
                                <th align="center" valign="center" class="col1" >Run Hours (HH:MM:SS)</th>
                                <th align="center" valign="center" class="col1" >Run Hours (HH:MM:SS)</th>

                                <th align="center" valign="center" class="col1" >Avail(%)</th>
                                <th align="center" valign="center" class="col1" >Run Hours (HH:MM:SS)</th>

                                <th align="center" valign="center" class="col1" >Avail(%)</th>
                                <th align="center" valign="center" class="col1" >Run Hours (HH:MM:SS)</th>

                                <th align="center" valign="center" class="col1" >Avail(%)</th>

                            </tr>
                        </thead>
                        <tbody>
                        {!loading && get_data?.data?.length > 0 && get_data?.data?.map((site, index) => (
                            <tr>
                                <td>{site.easi_site_id}</td>
                                <td>{site.site_id}</td>
                                
                                <td>{site.start_time}</td>
                                <td>{site.end_time}</td>
                                <td>{site.period}</td>
                                <td>{site.total_run_hour}</td>
                                <td>{site.grid_run_hour}</td>
                                <td>{site.grid_availability}</td>
                                <td>{site.pg_run_hour}</td>
                                <td>{site.pg_availability}</td>
                                <td>{site.battery_run_hour}</td>
                                <td>{site.battery_availability}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                </div>
            </div>
        </>
    )
}