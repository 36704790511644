import HelperClass from "../../CustomHook/HelperClass";

export default function LoadDisconnect({ loading, get_data, nextPage, request }){
    return (
        <>
        <div className="ms-1 me-1" id="load_disconnect_history">
       <div className="table-responsive bg-white">
                   <table width="100%" border={1} cellPadding={0} cellSpacing={0} className="table table-bordered mt-2 text-center">
                       <thead className="text-center">
                           <tr>
                               <th height={20} colSpan={20} align="center" valign="center" className="col" style={{ fontSize: '18px', fontWeight: 'bold' }}> Site Down Report
                               </th>
                           </tr>
                           <tr>
                               <th height={20} colSpan={20} align="center" valign="center" className="col" style={{ fontSize: '13px', fontWeight: 'bold' }}> Date: {request?.duration_type == 1 ? request?.start_date : `${request?.start_date} To ${request?.end_date}`}
                               </th>
                           </tr>
                           <tr>
                              
                               <th align="center" valign="center" class="col1" >{HelperClass().CUSTOMER_SITE} Site ID</th>
                               <th align="center" valign="center" class="col1" >Site ID</th>
                               <th align="center" valign="center" class="col1" >Tenant Name</th>
                               <th align="center" valign="center" class="col1" >Start Time</th>
                               <th align="center" valign="center" class="col1" >End Time</th>
                               <th align="center" valign="center" class="col1" >Total Elapsed</th>
                           </tr>
                       </thead>
                       <tbody>
                       {!loading && get_data?.data?.length > 0 && get_data?.data?.map((site, index) => (
                           <tr>
                               
                               <td>{site.easi_site_id}</td>
                                   <td>{site.site_id}</td>
                                   <td>{site.tenant_name}</td>
                               
                                   <td>{site.alarm_start}</td>
                                   <td>{site.alarm_end}</td>
                               <td>{site.total_elaspsed}</td>
                           </tr>
                              ))}
                       </tbody>

                   </table>
               </div>

               {get_data?.has_page ?
                   <div class="float-md-end mb-1 mt-1">
                       <div class="btn-group ">
                           {get_data?.first_page ?
                               <a class="btn btn-primary btn-sm" style={{ cursor: 'no-drop' }}>Prev</a>
                               :
                               <a class="btn btn-primary btn-sm " onClick={() => nextPage(get_data?.prv_page_url)}>Prev</a>
                           }

                           {get_data?.has_more_page ?
                               <a class="btn btn-info btn-sm " onClick={() => nextPage(get_data?.next_page_url)}>Next</a>
                               :
                               <a class="btn btn-info btn-sm " style={{ cursor: 'no-drop' }}>Next</a>

                           }
                       </div>
                   </div> : ''
               }
               </div>
       </>
    )
}