
import { createSlice } from '@reduxjs/toolkit';

export const LockAlarmHistorySlice = createSlice({
    name: 'LockAlarmHistory',
    initialState: {
        loading: false,
        data: {

        },
        alarms:[],
        error: false,
    },
    reducers: {
        LOCK_ALARM_HISTORY_FETCH_START: (state) => {
            state.loading = true
        },
        LOCK_ALARM_HISTORY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.alarms = action.payload.alarms
        },
        LOCK_ALARM_HISTORY_LOAD_MORE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.alarms=[...state.alarms,...action.payload.alarms]
        },
        LOCK_ALARM_HISTORY_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { LOCK_ALARM_HISTORY_LOAD_MORE_FETCH_SUCCESS,LOCK_ALARM_HISTORY_FETCH_START, LOCK_ALARM_HISTORY_FETCH_SUCCESS, LOCK_ALARM_HISTORY_FETCH_ERROR } = LockAlarmHistorySlice.actions;
export default LockAlarmHistorySlice.reducer;