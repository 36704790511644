import React from 'react';

import JsTree from './JsTree';

export default function AccessPermission() {

    return (
        <>
            <div className="me-1 ms-1 mt-2">
                <div className="row g-2">
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-header toi-primary border-bottom">Permission Tree</div>
                            <div className="card-body" style={{ padding: '0.6rem' }}>
                                <JsTree />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header bg-white border-bottom">Users list of Global</div>
                            <div className="card-body" style={{ padding: '0.6rem' }}>
                                <div style={{height:'300px',overflow:'auto'}}>

                                <table width="100%" cellSpacing={0} cellPadding={0} className="table m-0 table-colored-bordered table-bordered-success text-center" id="myTable">
                                    <tbody>
                                        <tr className="toi-primary">
                                        <th scope="col" width="30%">Name</th>
                                        <th scope="col" width="20%">Phone</th>
                                        <th scope="col" width="20%">Access</th>
                                        <th scope="col" width="15%">Action</th>
                                    </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Boni
                                            </td>
                                            <td>
                                                <i className="fa fa-phone" /> 01749944197
                                            </td>
                                            <td className="text-center">
                                                <i className="fa fa-id-card text-blue me-1" title="RFID" />
                                                <i className="fa fa-laptop text-success" title="Web" />
                                            </td>
                                            <td className="text-center">

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                </div>
                                <div style={{height:'300px',overflow:'auto'}}>
                            <div className="card-header bg-white border-bottom">Permission list of Global</div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">

                    </div>
                </div>
            </div>
        </>
    )
}