import { useEffect, useState } from 'react';

const useCountdown = (targetDate) => {
  
   const countDownDate = Date.parse(targetDate);
  const [countDown, setCountDown] = useState(
     new Date().getTime()-countDownDate
  );

  useEffect(() => {
    const interval = setInterval(() => {
        
      setCountDown(new Date().getTime()-countDownDate);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const day = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hour =Math.floor(countDown / (1000 * 60 * 60));
  const mins = Math.floor(countDown / (1000 * 60));
  const secs = Math.floor(countDown / 1000);
  const days = day * 24;
  const hours = ((hour - day * 24))+days;
  const minutes = mins - hour * 60;
  const seconds = secs - mins * 60;

  return [days, hours, minutes, seconds];
};

export { useCountdown };