
import { createSlice } from '@reduxjs/toolkit';

export const TimelineSlice = createSlice({
    name: 'Timeline',
    initialState: {
        loading: false,
        data: {

        },
        error: false,
    },
    reducers: {
        TIMELINE_FETCH_START: (state) => {
            state.loading = true
        },
        TIMELINE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        TIMELINE_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { TIMELINE_FETCH_START, TIMELINE_FETCH_SUCCESS, TIMELINE_FETCH_ERROR } = TimelineSlice.actions;
export default TimelineSlice.reducer;