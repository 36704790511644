import { useCallback, useEffect, useRef } from "react";
import API from "../../Network/API";
import JobNav from "./JobNav";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { JOB_FETCH_MORE_SUCCESS, JOB_FETCH_SUCCESS } from "../../Reducer/JobSlice";
import Paginate from "../../CustomHook/Paginate";
import JobDetails from "./JobDetails";
import JobCreate from "./JobCreate";

export default function JobIndex() {
    const [loading, setLoading] = useState(true);
    const [nxtPage, setNextPage] = useState(null);
    const [jobId, setjobId] = useState(0);
    const [searchType, setSearchType] = useState(1);
    const dispatch = useDispatch();

    const [modalDialog, setModalDialog] = useState(false);
    const [show, setModalShow] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);


    useEffect(() => {

        if(page!=1){
            all_load()
        }
       
    }, [page]);



    const observer = useRef();
    const lastElementRef = useCallback((element) => { 
        //element is the react element being referenced
        
        // disconnect observer set on previous last element
        if (observer.current) observer.current.disconnect();
          
        // if there's no more data to be fetched, don't set new observer
        if (!hasMore) return;
          
        // set new observer
        observer.current = new IntersectionObserver((entries) => {
          // increase page number when element enters (is intersecting with) viewport.
          // This triggers the pagination hook to fetch more items in the new page
          if (entries[0].isIntersecting && hasMore) setPage((prev) => prev + 1);
        });
      
        // observe/monitor last element
        if (element) observer.current.observe(element);
      }, [hasMore]);


    const toggleModal = (jid) => {
        setjobId(jid)
        setModalDialog(!modalDialog)
    }


    const all_load=()=>{
        API.get(`/v1/oauth/jobs?page=${page}`).then((response) => {
            setHasMore(response.data.data.has_more_page)
            dispatch(JOB_FETCH_MORE_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });

    }


    useEffect(() => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/jobs?page=1').then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            
            setHasMore(response.data.data.has_more_page)
            dispatch(JOB_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });

    }, []);

 
    const searchSite = (site_code) => {
        API.get(`/v1/oauth/jobs?page=1&q=${site_code}&search_type=${searchType}`).then((response) => {
            dispatch(JOB_FETCH_SUCCESS(response.data.data))
            setHasMore(response.data.data.has_more_page)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });
    }


    const syncData = () => {
        setPage(1)
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/jobs?page=1').then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(JOB_FETCH_SUCCESS(response.data.data))
            setHasMore(response.data.data.has_more_page)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        });
    }

    const selector = useSelector((state) => state.jobReducer)

    return (
        <>

            <div className="row  mb-2 mt-2 me-1 ms-1">
                <div className="align-items-center d-flex">
                    <h6 className="main-card-title mb-0 flex-grow-1"> </h6>
                    <div className="flex-shrink-0">

                        <div className="input-group input-group-sm">
                            <select className="form-select" onChange={(e)=>setSearchType(e.target.value)}>
                                <option value={1}>Site ID</option>
                                <option value={2}>Card Number</option>
                                <option value={3}>Phone Number</option>
                            </select>
                            <input onChange={(e)=>searchSite(e.target.value)} type="text" class="form-control" placeholder="search............." />
                            <button  class="btn btn-primary" type="button"><i class="fa-light fa-search me-1" />Search</button>
                            {/* <button class="btn btn-info" type="button" ><i class="fa-duotone fa-filter me-1"></i>Quick Filters</button> */}
                            <button class="btn btn-success" type="button" onClick={()=>setModalShow(true)}><i class="fa-light fa-plus me-1" />Add Job</button>
                            <button class="btn btn-warning" type="button" onClick={() => syncData()}><i class="fa-light fa-refresh me-1" />Sync</button>
                        </div>


                    </div>
                </div>



            </div >

            <div className="row mb-2 mt-2 me-1 ms-1">
                <div className="col-xl-3 col-lg-3 mobile-hight-none">
                    <JobNav />
                </div>
                <div className="col-xl-9 col-lg-9">
                    <div className="table-responsive bg-white pe-1 ps-1 pt-1">
                        <table className="table table-bordered table-striped text-center">
                            <thead class="text-uppercase">
                                <tr>
                                    <th scope="col" class="text-center toi-primary">SL</th>
                                    <th scope="col" width="25%" class="text-center toi-primary">Job Name</th>
                                    <th scope="col" class="text-center toi-primary">Assign For</th>
                                    <th scope="col" class="text-center toi-primary">Organisation</th>
                                    <th scope="col" class="text-center toi-primary">Start Time</th>
                                    <th scope="col" class="text-center toi-primary">End Time</th>

                                    <th scope="col" class="text-center toi-primary">Status</th>
                                    <th scope="col" class="text-center toi-primary" width="15%">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {!selector?.loading && selector?.main_data?.map((row, index) => (
                                    <tr className="fs-11" key={index} ref={index === selector?.main_data.length - 1 ? lastElementRef : undefined}>
                                        <td >{index+1}</td>
                                        <td >{row.subject}</td>
                                        <td>{row.user_name}</td>
                                        <td>{row.organization_name}</td>
                                        <td>
                                            <p className="m-0 p-0">{row.start_date_txt}</p>
                                            <p className="m-0 p-0">{row.start_time_txt}</p>
                                        </td>
                                        <td>
                                            <p className="m-0 p-0"> {row.end_date_txt}</p>
                                            <p className="m-0 p-0"> {row.end_time_txt}</p>
                                        </td>
                                        <td>
                                            <span class={`badge ${row.status == 1 ? 'bg-success' : 'bg-danger'}`}>{row.status_name}</span>

                                        </td>

                                        <td>
                                            <div className="btn-group btn-group-sm">
                                                <button onClick={()=>toggleModal(row.id)} type="button" className="btn btn-sm btn-secondary waves-effect" id="btn">
                                                    <i className="fa fa-list mr-1" /> <span>Details</span> </button>
                                                <button type="button" className="btn btn-sm btn-info dropdown-toggle waves-effect" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fe-more-vertical font-18 align-middle" />
                                                </button>
                                                <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(103px, 35px, 0px)' }}>
                                                    <a className="dropdown-item" href="#"><i className="fe-edit mr-1" /> Edit</a>
                                                    <a className="dropdown-item" href="#" onclick="return confirm('Are you sure want to delete?')"><i className="mdi mdi-diameter-variant mr-1" /> Inactive</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                        {loading && <div className="text-center">Loading......</div>}
                      
                    </div>
                </div>
            </div>

            <JobDetails job_id={jobId} isModalVisible={modalDialog} handleShowB={() => setModalDialog(false)} />
            <JobCreate  isModalVisible={show} handleShowB={() => setModalShow(false)} />

        </>
    )
}