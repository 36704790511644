export default function Footer(){
    return (
        <>
        <div className="footer-wrap mt-2">
            <footer className="footer" style={{backgroundColor:'#f4f6f9'}}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 col-sm-12 text-dark">
                    Copyright © 2021 <a className="text-decoration-none" target="_blank" href="https://www.ajratech.com/">Ajra Technologies Ltd.</a>.
                  </div>
                  <div className="col-md-6 col-sm-12 text-md-end">
                   
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </>
    )
}