export default function BatteryLogReport({ loading, get_data, nextPage }) {
    return (
        <>
            <div className="ms-1 me-1" id="bms_history">
                <div className="table-responsive">
                    <table className="table table-bordered mt-2 bg-white" >
                        <thead>
                            <tr>
                                <th className="toi-primary text-center">Datetime</th>
                                <th className="toi-primary text-center">SITE ID</th>
                                <th className="toi-primary text-center">Battery SL</th>
                                <th className="toi-primary text-center">Battery Name</th>
                                <th className="toi-primary text-center">Pack Voltage(V)</th>
                                <th className="toi-primary text-center">Pack Current(A)</th>
                                <th className="toi-primary text-center">Power</th>
                                <th className="toi-primary text-center">SOC(%)</th>
                                <th className="toi-primary text-center">SOH(%)</th>
                                <th className="toi-primary text-center">Battery Capacity</th>
                                <th className="toi-primary text-center">Battery Temperature(°C)</th>
                                <th className="toi-primary text-center">Env Temperature(°C)</th>
                                <th className="toi-primary text-center">V1</th>
                                <th className="toi-primary text-center">V2</th>
                                <th className="toi-primary text-center">V3</th>
                                <th className="toi-primary text-center">V4</th>
                                <th className="toi-primary text-center">V5</th>
                                <th className="toi-primary text-center">V6</th>
                                <th className="toi-primary text-center">V7</th>
                                <th className="toi-primary text-center">V8</th>
                                <th className="toi-primary text-center">V9</th>
                                <th className="toi-primary text-center">V10</th>
                                <th className="toi-primary text-center">V11</th>
                                <th className="toi-primary text-center">V12</th>
                                <th className="toi-primary text-center">V13</th>
                                <th className="toi-primary text-center">V14</th>
                                <th className="toi-primary text-center">V15</th>
                                <th className="toi-primary text-center">NTC1(°C)</th>
                                <th className="toi-primary text-center">NTC2(°C)</th>
                                <th className="toi-primary text-center">NTC3(°C)</th>
                                <th className="toi-primary text-center">NTC4(°C)</th>

                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {!loading && get_data?.alarms?.map((row, index) => (
                                <tr key={index}>
                                   <td className="fs-11 text-dark text-center"><span style={{width:'100px'}}>{row.device_time}</span></td>
                                    <td className="fs-11 text-dark text-center">{row.site_id}</td>
                                    <td>{row.battery_sl}</td>
                                    <td>{row.name}</td>
                                    <td>{row.pack_voltage}</td>
                                    <td>{row.pack_current}</td>
                                    <td>{row.power}</td>
                                    <td>{row.soc}</td>
                                    <td>{row.soh}</td>
                                    <td>{row.full_capacity}</td>
                                    <td>{row.bat_temp}</td>
                                    <td>{row.env_temp}</td>
                                    <td>{row.v1}</td>
                                    <td>{row.v2}</td>
                                    <td>{row.v3}</td>
                                    <td>{row.v4}</td>
                                    <td>{row.v5}</td>
                                    <td>{row.v6}</td>
                                    <td>{row.v7}</td>
                                    <td>{row.v8}</td>
                                    <td>{row.v9}</td>
                                    <td>{row.v10}</td>
                                    <td>{row.v11}</td>
                                    <td>{row.v12}</td>
                                    <td>{row.v13}</td>
                                    <td>{row.v14}</td>
                                    <td>{row.v15}</td>
                                    <td>{row.ntc1}</td>
                                    <td>{row.ntc2}</td>
                                    <td>{row.ntc3}</td>
                                    <td>{row.ntc4}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {get_data?.has_page ?
                        <div class="float-md-end mb-1 mt-1">
                            <div class="btn-group ">
                                {get_data?.first_page ?
                                    <a class="btn btn-primary btn-sm" style={{ cursor: 'no-drop' }}>Prev</a>
                                    :
                                    <a class="btn btn-primary btn-sm " onClick={() => nextPage(get_data?.prv_page_url)}>Prev</a>
                                }

                                {get_data?.has_more_page ?
                                    <a class="btn btn-info btn-sm " onClick={() => nextPage(get_data?.next_page_url)}>Next</a>
                                    :
                                    <a class="btn btn-info btn-sm " style={{ cursor: 'no-drop' }}>Next</a>

                                }
                            </div>
                        </div> : ''
                    }
            </div>
        </>
    )
}