import { useState } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CellNav from "./CellNav";

export default function BatteryGraph(){
    const ddd = useParams();
    const [battery, setBattery] = useState([])
    const [rms, setRms] = useState()
    const [battery_chart, setChart] = useState()
    const echarts_react_1 = useRef();
    const echarts_react_2 = useRef();
    const echarts_react_3 = useRef();
    const dispatch = useDispatch()
    return (
        <>
              <div className="row">
                <CellNav id={3} site_id={ddd.id} battery_id={ddd.sl} />
            </div>

        </>
    )
}