import axios from 'axios';
import BaseUrl from './BaseUrl';

export default axios.create({
        baseURL: `${BaseUrl()}/api`,
        //   baseURL: `https://edotco.iotajra.com/api`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+localStorage.getItem("token")??""
    }
});