import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Select from 'react-select'
import { useEffect } from "react";
import API from "../../Network/API";
import CreatableSelect from 'react-select/creatable';
import { useDispatch } from "react-redux";
import { ADD_JOB_FETCH_SUCCESS, EDIT_JOB_FETCH_SUCCESS } from "../../Reducer/JobSlice";
export default function JobCreate(props) {
    const modalRef1 = useRef()
    const [data, setData] = useState()
    const [options, setOption] = useState([])
    const [options1, setOption1] = useState([])
    const [options2, setOption2] = useState([])
    const [options3, setOption3] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);
    const [selected_site, setSelectedSites] = useState([]);
    const [cluster_id, setClusterId] = useState("");
    const [zone_id, setZoneId] = useState("");
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {

        if (props.isModalVisible) {
            setData()
            const loaderElement = document.querySelector(".loading-mask");
            loaderElement.removeAttribute('hidden');
            loaderElement.setAttribute('visible', 'true');
            API.get(`/v1/oauth/jobs/create`).then((response) => {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
                setOption(response.data.data.clusters)
                setOption1(response.data.data.organizations)
            }).catch((error) => {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            });
            
        }

    }, [props.isModalVisible]);

    const searchZone = (e) => {
        setOption2([])
        if (!e) {
            setOption2([])
            return;
        }
        setSelectedOption(e);
        setClusterId(e.value)
        API.get(`/v1/oauth/ajax_cluster_zone/${e.value}`).then((response) => {
            setOption2(response.data.data.zone)
        }).catch((error) => {

        });
    }

    const searchZoneSite = (e) => {
        setSelectedSites([])
        if (!e) {
            setSelectedSites([])
            return;
        }
        setZoneId(e.value)

        API.get(`/v1/oauth/ajax_sites?zone_id=${e.value}`).then((response) => {
            setSelectedSites(response.data.data.sites)
        }).catch((error) => {

        });
    }

    const searchUser = (e) => {
        setOption3([])
        if (!e) {
            setOption3([])
            return;
        }
        API.get(`/v1/oauth/ajax_vendor_users/${e.value}`).then((response) => {
            setOption3(response.data.data.users)
        }).catch((error) => {

        });
    }

    const search_site = (e) => {

        API.get(`/v1/oauth/ajax_sites?q=${e.target.value}&zone_id=${zone_id}`).then((response) => {
            setSelectedSites(response.data.data.sites)
        }).catch((error) => {

        });
    }


    const ajax_user_info = (e) => {

        if (!e) {
            return;
        }
        API.get(`/v1/oauth/ajax_user_info?user_id=${e.value}`).then((response) => {
            setData(response.data.data.user)
        }).catch((error) => {

        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const site=[];
      
        const siteIds = event.target.site_id;
        if (siteIds.length > 0) {
            siteIds.forEach((srow, i) => {
                site.push(event.target.site_id[i].value) 
            })
        }

 
        const fd = new FormData();
        fd.append(`start_date`, event.target.start_date.value)
        fd.append(`start_time`, event.target.start_time.value)
        if(event.target.end_time){
            fd.append(`end_time`, event.target.end_time.value)
            fd.append(`end_date`, event.target.end_date.value)
        }
      
        fd.append(`job_validity_type`, event.target.job_validity_type.value)
        fd.append(`user_id`, event.target.user_id.value)
        fd.append(`subject`, event.target.subject.value)
        fd.append(`details`, event.target.details.value)
        fd.append(`card_number`, event.target.card_number.value)
        fd.append(`phone`, event.target.phone.value)
        fd.append(`site_id`, site)
        fd.append(`type`, event.target.type.value)
        fd.append(`cluster_id`, event.target.cluster_id.value)
        fd.append(`zone_id`, event.target.zone_id.value)
        fd.append(`password`, event.target.password.value)

        API.post('/v1/oauth/jobs/store', fd)
        .then(response => {
            setLoading(false);
             document.getElementById("add_country_form").reset()
             toast.success(response.data.message);
             dispatch(ADD_JOB_FETCH_SUCCESS(response.data.data))
             props.handleShowB();
        }).catch((error) => {
            setLoading(false);
            toast.error(error.response.data.message)
        });
    }



    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    return (
        <>
            <Modal isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-dialog-scrollable  modal-lg'} backdrop="static"
                ref={modalRef1} >
                <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                    Add Job
                </ModalHeader>
                <ModalBody className="bg-white">
                    <div className="meeting-schedule-popup">
                        <form onSubmit={handleSubmit} id="add_country_form">
                            <div className="row">
                                <div className="col-md-6">

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="control-label">Type</label>
                                            <select className="form-select" name="type">
                                                <option value={1}>Site</option>
                                                <option value={2}>Clsuter</option>
                                                <option value={3}>Zone</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="control-label">Cluster</label>
                                            <Select name="cluster_id" isClearable closeMenuOnSelect={true} onChange={searchZone} options={options} />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label className="control-label">Zone</label>
                                            <Select name="zone_id" isClearable onChange={searchZoneSite} closeMenuOnSelect={true} options={options2} />
                                        </div>

                                        <div className="form-group col-md-12">
                                            <label className="control-label">SITE ID<em className="text-danger">*</em></label>
                                            <CreatableSelect name="site_id" required onKeyDown={(e) => search_site(e)} isMulti closeMenuOnSelect={true} options={selected_site} />
                                        </div>


                                    </div>


                                    <div className="form-group">
                                        <label className="control-label">Job Name<em className="text-danger">*</em></label>
                                        <input className="form-control" required name="subject" type="text" />
                                    </div>

                                    <div className="form-group">
                                        <label className="control-label">Start Time<em className="text-danger">*</em></label>
                                        <div className="input-group input-group-sm">
                                            <input id="demo3" type="date" defaultValue="" name="start_date" className="form-control" required />
                                            <input id="demo3" type="time" defaultValue="" name="start_time" className="form-control" required />
                                        </div>
                                        <br /> <label><input onChange={handleCheckboxChange} type="checkbox" name="job_validity_type" id="end_continue" /> Continue <em>(if end time will be unlimited enable the continue)</em></label>
                                    </div>
                                    {isChecked ? '' :
                                        <div className="form-group">
                                            <label className="control-label ">End Time</label>
                                            <div className="input-group input-group-sm">
                                                <input type="date"  name="end_date" className="form-control" />
                                                <input type="time"  name="end_time" className="form-control" />
                                            </div>
                                        </div>
                                    }

                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Organization<em className="text-danger">*</em></label>
                                        <Select required isClearable onChange={searchUser} options={options1} />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">User<em className="text-danger">*</em></label>
                                        <Select name="user_id" required onChange={ajax_user_info} isClearable options={options3} />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Phone Number</label>
                                        <input defaultValue={data?.phone} className="form-control bg-light" readOnly type="text" id="phone" name="phone" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Card Number</label>
                                        <input defaultValue={data?.card_number} className="form-control bg-light" required readOnly type="text" id="card_number" name="card_number" />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label">Job Description</label>
                                        <textarea placeholder="Write a job description.(Maximum 250 character)" cols={3} rows={5} className="form-control" maxLength={250} name="details" defaultValue={""} />
                                    </div>

                                    <div className={`col-12 mb-2`}>
                                    <label htmlFor="password" className="form-label form-label-font-14">Password*</label>
                                    <input required type="password" className="rounded-0 form-control border-danger" name="password" />
                                    </div>
                                </div>
                            </div>
                            <ModalFooter className={'post-footer-comment-icon bg-light'}>

                                <button type="submit" className="btn btn-success rounded-pill"><i class="fa-light fa-paper-plane-top me-1"></i> Save{loading ? (
                                    <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                ) : (
                                    <span></span>
                                )}</button>

                            </ModalFooter>
                        </form>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}