import ReactEcharts from "echarts-for-react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../Network/API";
import { HOME_FETCH_SUCCESS } from "../Reducer/homeSlice";
import { useEffect } from "react";
import HomeGrid from "../CustomHook/HomeGrid";
export default function HomeV1() {
    const [currentTimeZone, setCurrentTimeZone] = useState('UTC+6');
    const echarts_react_1 = useRef();
    const echarts_react_2 = useRef();
    const echarts_react_3 = useRef();
    const echarts_react_4 = useRef();
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [hour,setHour]=useState(0)
    const [minute,setMinute]=useState(0)
    const [second,setSecond]=useState(0)
    const [typeAm,setTypeAm]=useState('')
    const [weekDay,setWeekDay]=useState('')

    useEffect(() => {
        rms_home()

        const intervalId = setInterval(() => {
            clockDoor()
          }, 1000);
      
          return () => {
            clearInterval(intervalId);
          };

    }, []);



    const rms_home = () => {
        const loaderElement = document.querySelector(".loading-mask");
        API.get('/v1/oauth/home_overview').then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
                // loaderElement.remove();
            }
            dispatch(HOME_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
        });
    }

    const selector = useSelector((state) => state.homeReducer)
    const alarms = selector?.data

    const option = {
        color: ["#ffa91c", "#fd3b48", "#f96a74", "#ffc61c","#33E6FF"],
        tooltip: {

            trigger: 'item',
            formatter: '{a} <br/>{b}',
            axisPointer: {
                animation: true
            },
            backgroundColor: "#333333e8",
            textStyle: {
                color: '#fff'
            }

        },
        legend: {
            top: '25%',
            right: '25%',
            orient: 'vertical',
            right: 'left',
            textStyle: {
                fontSize: 11
            },
            data: [
                `Mains Fail (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mains_fail : 0})`,
                `DC Low (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_low : 0})`,
                `MDB Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mdb_fault : 0})`,
                `Phase Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.phase_missing : 0})`,
                `PG Running (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.pg_status : 0})`,
            ],
            padding: [
                0, // up
                0, // right
                0, // down
                10, // left
            ]
        },
        series: [
            {
                name: 'Power',
                type: 'pie',
                selectedMode: 'single',
                radius: [0, 0],
                label: {
                    position: 'inner',
                    fontSize: 16,
                    color: '#000000'
                },
                labelLine: {
                    show: false
                },
                right: '35%',
                data: [{
                    value: 0,
                    name: 'Power'
                }]
            },
            {
                name: 'Power',
                type: 'pie',
                radius: ['35%', '60%'],
                label: {
                    show: false,
                    formatter: '{c}',
                    position: 'inside'
                },
                itemStyle: {
                    borderRadius: 1,
                    borderColor: '#fff',
                },
                labelLine: {
                    show: false
                },
                right: '35%',
                data: [{
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mains_fail : 0,
                    name: `Mains Fail (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mains_fail : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_low : 0,
                    name: `DC Low (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_low : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mdb_fault : 0,
                    name: `MDB Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mdb_fault : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.phase_missing : 0,
                    name: `Phase Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.phase_missing : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.pg_status : 0,
                    name: `PG Running (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.pg_status : 0})`
                }
                ]
            }
        ]
    };

    const option2 = {

        tooltip: {

            trigger: 'item',
            formatter: '{a} <br/>{b}',
            axisPointer: {
                animation: true
            },
            backgroundColor: "#333333e8",
            textStyle: {
                color: '#fff'
            }

        },
        legend: {
            top: '25%',
            orient: 'vertical',
            right: 'left',
            textStyle: {
                fontSize: 11
            },
            data: [
                `Smoke (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.smoke : 0})`,
                `DC Fan Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_fan_fault : 0})`,
                `Rectifier Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.rectifier_fault : 0})`,
                `Water Leakage (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.water_leakage : 0})`,
                `ODC Temp High (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.temp_high_sp : 0})`,
                `Door Open (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.door_open : 0})`,
            ],
            padding: [
                0, // up
                0, // right
                0, // down
                10, // left
            ]
        },
        series: [{
            name: 'Sensor',
            type: 'pie',
            selectedMode: 'single',
            radius: [0, 0],
            label: {
                position: 'inner',
                fontSize: 16,
                color: '#000000'
            },
            labelLine: {
                show: false
            },
            right: '35%',
            data: [{
                value: 0,
                name: 'Sensor'
            }]
        },
        {
            name: 'Sensor',
            type: 'pie',
            radius: ['35%', '60%'],
            label: {
                show: false,
                formatter: '{c}',
                position: 'inside'
            },
            itemStyle: {
                borderRadius: 1,
                borderColor: '#fff',
            },
            labelLine: {
                show: false
            },
            right: '35%',
            data: [
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.smoke : 0,
                    name: `Smoke (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.smoke : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_fan_fault : 0,
                    name: `DC Fan Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_fan_fault : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.rectifier_fault : 0,
                    name: `Rectifier Fault (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.rectifier_fault : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.water_leakage : 0,
                    name: `Water Leakage (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.water_leakage : 0})`
                },

                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.temp_high_sp : 0,
                    name: `ODC Temp High (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.temp_high_sp : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.door_open : 0,
                    name: `Door Open (${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.door_open : 0})`
                },
            ]
        }
        ]
    };

    const option3 = {
        color: ["#ffa91c", "#fd3b48", "#f96a74"],
        tooltip: {
            trigger: 'item',
            formatter: 'DC Energy Meter <br/>{b}',
            axisPointer: {
                animation: true
            },
            backgroundColor: "#333333e8",
            textStyle: {
                color: '#fff'
            }
        },
        legend: {
            top: '25%',
            left: '50%',
            orient: 'vertical',
            right: 'left',
            textStyle: {
                fontSize: 12
            },
            data: [
                `LLVD(${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.llvd : 0})`,
                `Overload(${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.overloaded : 0})`,
                `Site Down(${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.load_disconnect : 0})`,
            ],
            padding: [
                0, // up
                0, // right
                0, // down
                15, // left
            ]
        },
        series: [{
            name: 'DC',
            type: 'pie',
            selectedMode: false,
            radius: [0, 0],
            label: {
                position: 'inner',
                fontSize: 16,
                color: '#000000'
            },
            labelLine: {
                show: false
            },
            right: '35%',
            data: [{
                value: 0,
                name: 'DC'
            }]
        },
        {
            name: '',
            type: 'pie',
            radius: ['35%', '60%'],
            label: {
                show: false,
                formatter: '{c}',
                // fontSize: 16,
                position: 'inside'
            },
            avoidLabelOverlap: false,

            labelLine: {
                show: false
            },
            right: '35%',
            itemStyle: {
                borderRadius: 1,
                borderColor: '#fff',
            },
            data: [
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.llvd : 0,
                    name: `LLVD(${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.llvd : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.overloaded : 0,
                    name: `Overload(${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.overloaded : 0})`
                },
                {
                    value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.load_disconnect : 0,
                    name: `Site Down(${alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.load_disconnect : 0})`
                }

            ]
        }
        ]
    };

    // const doorOpenAlarm = alarms?.total_lock_alarm?.find(alarm => alarm.order_list == 7);
    const getAlarmValue = (alarmName) => {
        const alarm = alarms?.total_lock_alarm?.find(alarm => alarm.order_list == alarmName);
        return alarm ? alarm.value : "0";
    };

    const option1 = {
        color: ["#FF3131", "#64c5b1", "#ffc61c", "#34d3eb", "#0cc345", "#fd3b48", "#5553ce", "#fd7e14", "#ef1047"],

        tooltip: {

            trigger: 'item',
            formatter: '{a} <br/>{b}',
            axisPointer: {
                animation: true
            },
            backgroundColor: "#333333e8",
            textStyle: {
                color: '#fff'
            }

        },
        legend: {
            orient: 'vertical',
            left: 'right',

        },
        series: [{
            name: 'STATISTICS',
            type: 'pie',
            label: {
                show: false,
                formatter: '{c}',
                position: 'inside'
            },
            radius: ['80%'],
            top: "-20px",
            right: '35%',
            itemStyle: {
                borderRadius: 1,
                borderColor: '#ccc',
            },
            data: [
                {
                    value: getAlarmValue(7),
                    name: `Door Open(${getAlarmValue(7)})`,
                },
                {
                    value: getAlarmValue(1),
                    name: `Vibration(${getAlarmValue(1)})`,
                },
                {
                    value: getAlarmValue(4),
                    name: `Lock Fault(${getAlarmValue(4)})`,
                },
                {
                    value: getAlarmValue(10),
                    name: `Lock Close Failed(${getAlarmValue(10)})`,
                },
                {
                    value: getAlarmValue(3),
                    name: `Reader Fault(${getAlarmValue(3)})`,
                },
                {
                    value: getAlarmValue(6),
                    name: `Security Breach(${getAlarmValue(6)})`,
                },
                {
                    value: getAlarmValue(2),
                    name: `Lock Open(${getAlarmValue(2)})`,
                },
                {
                    value: getAlarmValue(9),
                    name: `Card Temper(${getAlarmValue(9)})`,
                },
                {
                    value: getAlarmValue(11),
                    name: `Door Open Timeout${getAlarmValue(11)}`,
                }
            ],

            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }]
    };
 const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });

    // The week days
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

 const clockDoor=()=>{
    var date = new Date();
    var day = date.getDay();
    var hrs = date.getHours();
    var mins = date.getMinutes();
    var secs = date.getSeconds();
    if (hrs > 12) {

        hrs = hrs - 12;
        setTypeAm('PM')
        
    } else {

        setTypeAm('AM')
    }

     // Pad the single digit units by 0
     hrs = hrs < 10 ? "0" + hrs : hrs;
     mins = mins < 10 ? "0" + mins : mins;
     secs = secs < 10 ? "0" + secs : secs;
    setWeekDay(weekDays[day])
    setHour(hrs)
    setMinute(mins)
    setSecond(secs)
 }
    return (
        <>
            <div className="container-fluid mt-2">
                <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                        <div className="card">
                            <div className="card-header bg-white border-bottom">Site Analytics <i class="fa-thin fa-circle-info" /></div>
                            <div className="card-body" style={{ padding: '0.6rem' }}>
                                <div className="row">
                                    <div className="col-md-2 border-left text-left">
                                        <p className="p-0 mb-0" style={{ fontWeight: 'bold', color: '#000', fontSize: '12px' }}>Site Status <i class="fa-thin fa-circle-info" /></p>
                                        <p className="p-0 mb-0" style={{ fontSize: '13px', letterSpacing: '1px', color: '#000' }}>Total Site:<span className="text-danger">{alarms?.total_sites}</span> </p>
                                        <p className="p-0 mb-0" style={{ fontSize: '13px', letterSpacing: '1px', color: '#000' }}>Total Lock:<span className="text-danger">{alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.total_site : 0}</span> </p>
                                    </div>
                                    <div className="col-md-2 border-left text-center">
                                        <p className="p-0 mb-0" style={{ fontWeight: 'bold', color: '#000', fontSize: '12px' }}>Total Online <i class="fa-thin fa-circle-info" /></p>
                                        <p className="p-0 mb-0" style={{ fontWeight: 600, color: '#1fad4b', fontSize: '24px' }}>
                                            {alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.online_device : 0}
                                        </p>
                                        <p className="p-0 mb-0" style={{ fontSize: '9px', letterSpacing: '1px' }}><a href="#" className="text-dark">View All <i class="fa-regular fa-chart-bar text-info" /></a></p>
                                    </div>
                                    <div className="col-md-2 border-left text-center">
                                        <p className="p-0 mb-0" style={{ fontWeight: 'bold', color: '#f34d4d', fontSize: '12px' }}>Total Offline <i class="fa-thin fa-circle-info" /></p>
                                        <p className="p-0 mb-0" style={{ fontWeight: 600, color: '#d4d3d3', fontSize: '24px' }}>
                                            {alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.offline_device : 0}
                                        </p>
                                        <p className="p-0 mb-0" style={{ fontSize: '9px', letterSpacing: '1px' }}><a href="#" className="text-dark">View All <i class="fa-regular fa-chart-bar text-info" /></a></p>
                                    </div>
                                    <div className="col-md-2 border-left text-center">
                                        <p className="p-0 mb-0" style={{ fontWeight: 'bold', color: '#000', fontSize: '12px' }}>Door Open(Lock) <i class="fa-thin fa-circle-info" /></p>
                                        <p className="p-0 mb-0" style={{ fontWeight: 600, color: '#f34d4d', fontSize: '24px' }}>
                                            {getAlarmValue(7)}
                                        </p>
                                        <p className="p-0 mb-0" style={{ fontSize: '9px', letterSpacing: '1px' }}><a href="#" className="text-dark">View All <i class="fa-regular fa-chart-bar text-info" /></a></p>
                                    </div>
                                    <div className="col-md-2 border-left text-center">
                                        <p className="p-0 mb-0" style={{ fontWeight: 'bold', color: '#000', fontSize: '12px' }}>Door Open(RMS) <i class="fa-thin fa-circle-info" /></p>
                                        <p className="p-0 mb-0" style={{ fontWeight: 600, color: '#f34d4d', fontSize: '24px' }}>
                                            0
                                        </p>
                                        <p className="p-0 mb-0 text-center" style={{ fontSize: '9px', letterSpacing: '1px' }}><a href="" className="text-dark">View All <i class="fa-regular fa-chart-bar text-info" /></a></p>
                                    </div>
                                    <div className="col-md-2 border-left text-left">
                                        <p className="p-0 mb-0" style={{ fontWeight: 'bold', color: '#000', fontSize: '12px' }}>Critical Alarm <i class="fa-thin fa-circle-info" /></p>
                                        <p className="p-0 mb-0" style={{ fontSize: '11px', letterSpacing: '1px', color: '#000' }}><a href="" className="text-dark">Mains Fail:<span className="text-danger">{alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mains_fail : 0}</span></a> </p>
                                        <p className="p-0 mb-0" style={{ fontSize: '11px', letterSpacing: '1px', color: '#000' }}><a href="" className="text-dark">LLVD: <span className="text-danger">{alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.llvd : 0}</span></a></p>
                                        <p className="p-0 mb-0" style={{ fontSize: '11px', letterSpacing: '1px', color: '#000' }}><a href="" className="text-dark">Security Breach: <span className="text-danger">{getAlarmValue(6)}</span></a></p>
                                        <p className="p-0 mb-0" style={{ fontSize: '11px', letterSpacing: '1px', color: '#000' }}><a href="" className="text-dark">PG Running: <span className="text-danger">{alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.pg_status : 0}</span></a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">

                        <div className="row g-2">
                            
                            <div className="card-box">
                                {/* The digital clock */}
                                <div id="clock">
                                    {/* Time units wrapper */}
                                    <span className="wrap-time">
                                        {/* Time unit - Day */}
                                        <span className="time-unit">
                                            <span className="large day">{weekDay}</span>
                                            <span className="small">DAY</span>
                                        </span>
                                        {/* Time unit - Hours */}
                                        <span className="time-unit">
                                            <span className="large hours">{hour}</span>
                                            <span className="small">HOURS</span>
                                        </span>
                                        <span className="separator">:</span>
                                        {/* Time unit - Minutes */}
                                        <span className="time-unit">
                                            <span className="large minutes">{minute}</span>
                                            <span className="small">MINUTES</span>
                                        </span>
                                        <span className="separator">:</span>
                                        {/* Time unit - Seconds */}
                                        <span className="time-unit">
                                            <span className="large seconds">{second}</span>
                                            <span className="small">SECONDS</span>
                                        </span>
                                        {/* Time unit - Period */}
                                        <span className="time-unit">
                                            <span className="large period">{typeAm}</span>
                                            <span className="small">PERIOD</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

                <div className="row mt-2">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header bg-white border-bottom">RMS Alarm Grapical Analytics <i class="fa-thin fa-circle-info" />
                            </div>
                            <div className="card-body" style={{padding:'0.6rem'}}>
                                <div className="row">
                                    <div className="col-md-4" style={{paddingLeft:'0px',paddingRight:'0px'}}>
                                        <ReactEcharts ref={echarts_react_1} style={{ height: "300px" }} option={option} />

                                    </div>
                                    <div className="col-md-4" style={{paddingLeft:'0px',paddingRight:'0px'}}>
                                        <ReactEcharts ref={echarts_react_2} style={{ height: "300px" }} option={option2} />
                                    </div>

                                    <div className="col-md-4" style={{paddingLeft:'0px',paddingRight:'0px'}}>
                                        <ReactEcharts ref={echarts_react_3} style={{ height: "300px" }} option={option3} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div className="card">
                            <div className="card-header bg-white border-bottom">Access Control Alarm Analytics <i class="fa-thin fa-circle-info" />
                            </div>
                            <div className="card-body" style={{padding:'0.6rem'}}>
                            <ReactEcharts notMerge ref={echarts_react_4} style={{ height: "300px" }} option={option1} />
                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}