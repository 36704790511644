import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import API from "../../Network/API";
import { useEffect } from "react";

export default function JobDetails(props) {
    const modalRef1 = useRef()
    const [data, setData] = useState()
    useEffect(() => {
        if (props.job_id != 0) {

            const loaderElement = document.querySelector(".loading-mask");
            loaderElement.removeAttribute('hidden');
            loaderElement.setAttribute('visible', 'true');
            API.get(`/v1/oauth/jobs/${props.job_id}/view`).then((response) => {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
                setData(response.data.data)
            }).catch((error) => {

                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            });

        }
    }, [props.job_id]);

    return (
        <>
            <Modal isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-dialog-scrollable  modal-lg'} backdrop="static"
                ref={modalRef1} >
                <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                    Job Details
                </ModalHeader>
                <ModalBody className="bg-white">
                    <table className="table jobTable">
                        <tr>
                            <th width={`20%`}>Job Name:</th>
                            <td>{data?.subject}</td>
                        </tr>
                        <tr >
                            <th>Phone Number:</th>
                            <td>{data?.phone_number}</td>
                        </tr>
                        <tr >
                            <th>Card Number:</th>
                            <td>{data?.card_number}</td>
                        </tr>
                        <tr >
                            <th>Assign For:</th>
                            <td>{data?.user_name}</td>
                        </tr>
                        <tr >
                            <th>Organisation:</th>
                            <td>{data?.organization_name}</td>
                        </tr>
                        <tr >
                            <th>Start & End Time:</th>
                            <td>{data?.start_date_txt} {data?.start_time_txt} - {data?.end_date_txt} {data?.end_time_txt}</td>
                        </tr>
                        <tr >
                            <th>Job Status:</th>
                            <td>
                                <span class={`badge ${data?.status == 1 ? 'bg-success' : 'bg-danger'}`}>{data?.status_name}</span>
                            </td>
                        </tr>
                    </table>

                    <div>
                        <table className="table jobTable">
                            <thead>
                                <tr>
                                    <th>SITE ID</th>
                                    <th>Card Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.cards.length > 0 && data?.cards.map((row, index) => (
                                    <tr>
                                        <td>{row.site_id}</td>
                                        <td><span class={`badge ${row.status == 1 && row.bit_status==2 ? 'bg-success' :row.status == 1 && row.bit_status==1 ? 'bg-warning': 'bg-danger'}`}>{row?.status_name}</span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </ModalBody>

            </Modal>
        </>
    )
}