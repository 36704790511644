import { Link } from "react-router-dom";

export default function BatteryInfo({rms}) {
    return (
        <>
        {rms?.battery_list?.length>0?
            <div className="card-box" style={{ marginBottom: '10px' }}>
                <h4 className="header-title mb-0" style={{ padding: '3px', fontSize: '12px', paddingBottom: '10px' }}>BMS Overview
                </h4>
                <hr style={{ marginTop: '0px', marginBottom: '3px' }} />
                <table className="table table-striped table-bordered table-centered mb-0 text-uppercase mt-1 alarm_table_custom">
                    <thead className="toi-primary text-white">
                        <tr>
                            <th className="text-left">Battery Name</th>
                            <th className="text-center">SoC</th>
                            <th className="text-center">Pack Voltage</th>
                            <th className="text-center">Bat Temp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rms?.battery_list?.length > 0 && rms?.battery_list?.map((row, index) => (
                            <tr>
                                <td className="text-left"> <Link to={`/site/${rms?.site_id}/bms/${row?.battery_sl}/dashboard`}>{row.battery_name}</Link></td>
                                <td className="text-center">{row.soc}%</td>
                                <td className="text-center">{row.pack_voltage}V</td>
                                <td className="text-center">{row.bat_temp}°C</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            :''}
        </>
    )
}