import Pusher from 'pusher-js';
import Echo from "laravel-echo";
export default function createSocketConnection() {
    if (!window.Echo) {
        const token = localStorage.getItem("token");
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: 'app-key-edotco',
            wsHost: 'edotco-ws.iotajra.com',
            wssHost: 'edotco-ws.iotajra.com',
            wsPort: 6001,
            wssPort: 443,
            cluster:'mt1',
            forceTLS: true,
            encrypted: true,
            disableStats: true,
            authEndpoint: 'https://edotco.iotajra.com/broadcasting/auth',
            auth: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        });
    }
}