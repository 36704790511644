

import { createSlice } from '@reduxjs/toolkit';

export const vendorSlice = createSlice({
    name: 'VENDOR',
    initialState: {
        loading: false,
        data: {

        },
        main_data: [],
        error: false,
    },
    reducers: {
        VENDOR_FETCH_START: (state) => {
            state.loading = true
        },
        VENDOR_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.main_data = action.payload.data
        },
        ADD_VENDOR_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = [action.payload,...state.main_data]
        },
        EDIT_VENDOR_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !==action.payload.id)
             state.main_data = [...newArray,action.payload]
        },
        VENDOR_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const {EDIT_VENDOR_FETCH_SUCCESS,ADD_VENDOR_SUCCESS, VENDOR_FETCH_START, VENDOR_FETCH_SUCCESS, VENDOR_FETCH_ERROR } = vendorSlice.actions;
export default vendorSlice.reducer;