

import { createSlice } from '@reduxjs/toolkit';

export const RmsStationSlice = createSlice({
    name:'RmsStation',
    initialState:{
        loading: false,
        data: {

        },
        sites:[],
        main_data: {

        },
        error: false,
    },
    reducers:{
        RMS_STATION_FETCH_START:(state)=>{
            state.loading=true
        },
        RMS_STATION_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.sites=action.payload.sites
        },
        RMS_STATION_FETCH_LOAD_MORE:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.sites=[...state.sites,...action.payload.sites]
        },
        EDIT_RMS_STATION_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.sites.filter(todo => todo.id !==action.payload.id)
             state.sites = [...newArray,action.payload]
        },
        RMS_STATION_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const {RMS_STATION_FETCH_LOAD_MORE, RMS_STATION_FETCH_START,RMS_STATION_FETCH_SUCCESS,RMS_STATION_FETCH_ERROR,EDIT_RMS_STATION_FETCH_SUCCESS }=RmsStationSlice.actions;
export default RmsStationSlice.reducer;