import { useEffect, useState, useRef } from "react";
import { SITE_ID_FETCH_SUCCESS } from "../../../Reducer/SiteIdSlice";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/dist";
import { ACM_ID_FETCH_SUCCESS } from "../../../Reducer/acmIDSlice";
import API from "../../../Network/API";
import ReactEcharts from "echarts-for-react";
import AcsNav from "./AcsNav";
export default function AcEnergyMeter() {
    const ddd = useParams();
    const dispatch = useDispatch()
    const [data, setData] = useState()
    const [voltage_data, setVoltageData] = useState()
    const [current_data, setCurrentData] = useState()
    const echarts_react_1 = useRef();
    const echarts_react_2 = useRef();
    useEffect(() => {
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
        dispatch(ACM_ID_FETCH_SUCCESS(ddd.acm_id))

        syncData()

    }, [ddd.id]);

    const syncData = () => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/acm_meter_app?site_id=' + ddd.id + '&acm_id=' + ddd.acm_id).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setData(response.data.data)
        }).catch((error) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });

        phase_voltage()
        phase_current()
    }

    const phase_voltage = () => {
        API.get('/v1/oauth/last_one_hour_voltage?site_id=' + ddd.id + '&acm_id=' + ddd.acm_id).then((response) => {
            setVoltageData(response.data.data)
        }).catch((error) => {

        });
    }

    const phase_current = () => {
        API.get('/v1/oauth/last_one_hour_current?site_id=' + ddd.id + '&acm_id=' + ddd.acm_id).then((response) => {
            setCurrentData(response.data.data)
        }).catch((error) => {

        });
    }


    const option = {

        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Phase-1', 'Phase-2', 'Phase-3']
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            data: voltage_data?.array_time
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} v'
            }
        },
        series: [
            {
                name: 'Phase-1',
                type: 'line',
                data: voltage_data?.array_voltage1
            },
            {
                name: 'Phase-2',
                type: 'line',
                data: voltage_data?.array_voltage2
            },
            {
                name: 'Phase-3',
                type: 'line',
                data: voltage_data?.array_voltage3
            },

        ]
    };
    const option1 = {

        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Phase-1', 'Phase-2', 'Phase-3']
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            data: current_data?.array_time
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} v'
            }
        },
        series: [
            {
                name: 'Phase-1',
                type: 'line',
                data: current_data?.array_voltage1
            },
            {
                name: 'Phase-2',
                type: 'line',
                data: current_data?.array_voltage2
            },
            {
                name: 'Phase-3',
                type: 'line',
                data: current_data?.array_voltage3
            },

        ]
    };
    return (
        <>
            <div className="row">
                <AcsNav id={1} site_id={ddd.id} acm_id={ddd.acm_id} />
            </div>
            <div className="row ms-1 me-1 mb-2">
                <div className="col-md-12">
                    <div class="align-items-center d-flex ">
                        <h6 class="main-card-title mb-0 flex-grow-1">
                            ACM :{data?.acm?.acm_name}
                            <p className="p-0 m-0" style={{ fontSize: '10px' }}>Last Update: {data?.acm?.device_time}</p>
                        </h6>
                        <div class="flex-shrink-0 text-end">
                            <button onClick={() => syncData('')} class="btn btn-warning  ms-2" type="button" ><i class="fa-light fa-refresh me-1" />Sync</button>

                        </div>
                    </div>

                </div>

                <div className="col-md-4" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-light"><i class="fa-light fa-circle-info me-1"></i>Phase Voltage</div>
                        <div className="card-body bg-white" style={{ height: '230px' }}>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <article class="d-flex justify-content-between mb-1">
                                        <div class="stat-cards-icon bg-main-color text-white">
                                            <i class="fa-sharp fa-solid fa-bolt"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class={`stat-cards-info__num p-0 m-0 text-red`}>{data?.acm?.avoltage} V</p>
                                            <p class="stat-cards-info__title p-0 m-0">Phase R</p>
                                        </div>
                                    </article>
                                    <article class="d-flex justify-content-between mb-1">
                                        <div class="stat-cards-icon bg-main-color text-white">
                                            <i class="fa-sharp fa-solid fa-bolt"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class={`stat-cards-info__num p-0 m-0 text-red`}>{data?.acm?.bvoltage} V</p>
                                            <p class="stat-cards-info__title p-0 m-0">Phase Y</p>
                                        </div>
                                    </article>
                                    <article class="d-flex justify-content-between mb-1">
                                        <div class="stat-cards-icon bg-main-color text-white">
                                            <i class="fa-sharp fa-solid fa-bolt"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class={`stat-cards-info__num p-0 m-0 text-red`}>{data?.acm?.cvoltage} V</p>
                                            <p class="stat-cards-info__title p-0 m-0">Phase B</p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-light"><i class="fa-light fa-circle-info me-1"></i>Phase Current</div>
                        <div className="card-body bg-white" style={{ height: '230px' }}>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <article class="d-flex justify-content-between mb-1">
                                        <div class="stat-cards-icon bg-main-color text-white">
                                            <i class="fa-sharp fa-regular fa-plug"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class={`stat-cards-info__num p-0 m-0 text-red`}>{data?.acm?.acur} (I)</p>
                                            <p class="stat-cards-info__title p-0 m-0">Phase R</p>
                                        </div>
                                    </article>
                                    <article class="d-flex justify-content-between mb-1">
                                        <div class="stat-cards-icon bg-main-color text-white">
                                            <i class="fa-sharp fa-regular fa-plug"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class={`stat-cards-info__num p-0 m-0 text-red`}>{data?.acm?.bcur} (I)</p>
                                            <p class="stat-cards-info__title p-0 m-0">Phase Y</p>
                                        </div>
                                    </article>
                                    <article class="d-flex justify-content-between mb-1">
                                        <div class="stat-cards-icon bg-main-color text-white">
                                            <i class="fa-sharp fa-regular fa-plug"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class={`stat-cards-info__num p-0 m-0 text-red`}>{data?.acm?.ccur} (I)</p>
                                            <p class="stat-cards-info__title p-0 m-0">Phase B</p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-light"><i class="fa-light fa-circle-info me-1"></i>Data Overview</div>
                        <div className="card-body bg-white" style={{ height: '230px' }}>
                            <div className="row">
                                <table class="table table-borderedles mb-0 ">

                                    <tbody>
                                        <tr>
                                            <th>AC Frequency</th>
                                            <td>{data?.acm?.freq} Hz</td>
                                        </tr>
                                        <tr>
                                            <th>Power Factor</th>
                                            <td>{data?.acm?.powerfactor}</td>

                                        </tr>

                                        <tr>
                                            <th>Total Actvie forward</th>
                                            <td>{data?.acm?.actimport} kWh</td>


                                        </tr>


                                        <tr>
                                            <th>Power on min</th>
                                            <td>{data?.acm?.poweronmin} Minute</td>


                                        </tr>
                                        <tr>
                                            <th>Power off min</th>
                                            <td>{data?.acm?.poweroffmin} Minute</td>


                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                            <b class="main-card-title mb-0 flex-grow-1"><i class="fa-light fa-bar-chart me-1"></i> Phase Voltage</b>
                            <div class="flex-shrink-0">
                                <div class="app-search">
                                    <div class="input-group">
                                        <div class="position-relative">
                                            Today last 1 Hour
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-white">
                            <div className="row">
                                <ReactEcharts ref={echarts_react_1} style={{ height: "300px" }} option={option} />
                            </div>
                        </div>
                    </div>
                </div>
              
                <div className="col-md-6" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                            <b class="main-card-title mb-0 flex-grow-1"><i class="fa-light fa-bar-chart me-1"></i> Phase Current</b>
                            <div class="flex-shrink-0">
                                <div class="app-search">
                                    <div class="input-group">
                                        <div class="position-relative">
                                            Today last 1 Hour
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-white">
                            <div className="row">
                                <ReactEcharts ref={echarts_react_2} style={{ height: "300px" }} option={option1} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}