
import { createSlice } from '@reduxjs/toolkit';

export const RmsActiveAlarmSlice = createSlice({
    name: 'rmsActiveAlarmSlice',
    initialState: {
        loading: false,
        data: {

        },
        error: false,
    },
    reducers: {
        RMS_ACTIVE_ALARM_FETCH_START: (state) => {
            state.loading = true
        },
        RMS_ACTIVE_ALARM_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        RMS_ACTIVE_ALARM_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { RMS_ACTIVE_ALARM_FETCH_START, RMS_ACTIVE_ALARM_FETCH_SUCCESS, RMS_ACTIVE_ALARM_FETCH_ERROR } = RmsActiveAlarmSlice.actions;
export default RmsActiveAlarmSlice.reducer;