
import { createSlice } from '@reduxjs/toolkit';

export const offlineIDSlice = createSlice({
    name: 'offlineID',
    initialState: {
        loading: false,
        data: {

        },
        error: false,
    },
    reducers: {
        OFFLINE_ID_FETCH_START: (state) => {
            state.loading = true
        },
        OFFLINE_ID_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        OFFLINE_ID_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { OFFLINE_ID_FETCH_START, OFFLINE_ID_FETCH_ERROR, OFFLINE_ID_FETCH_SUCCESS } = offlineIDSlice.actions;
export default offlineIDSlice.reducer;