import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { RMS_ALARM_ID_FETCH_SUCCESS } from "../../Reducer/rmsAlarmIdSlice";
import Agent from "../../Network/Agent";

export default function RmsActiveAlarm() {
    const ddd = useParams();
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(RMS_ALARM_ID_FETCH_SUCCESS(ddd.id))
        Agent.RMS_ALARM.rms_active_alarm(dispatch,ddd.id)
    }, [ddd.id]);

    const selector_rms_alarm_nav = useSelector((state) => state.rmsAlarmGroupReducer)
    const rmsActiveAlarm = useSelector((state) => state.rmsActiveAlarmHistoryReducer)
    return (
        <>
            <div className="row g-2 mt-2 mb-2">
                <div className="col-md-3">
                    <div className="scroll-side-rms">
                        {!selector_rms_alarm_nav?.loading && selector_rms_alarm_nav?.data?.rms_alarm_group?.map((rms_alarm, index) => (
                            <>
                                <ul className="list-group alarm-list mb-2 " key={index}>
                                    <li className=" list-group-item d-flex justify-content-between align-items-center align-items-center title">
                                        <h6 className="m-0 p-0">{rms_alarm.name}</h6>
                                    </li>
                                    {rms_alarm?.alarm?.map((alarm, index) => (
                                        <>
                                            <Link key={index} to={`/rms_alarm_filter/${alarm.order_list}/fatch`} htmlFor={`flexCheckDefaultRms${alarm.id}`} className="list-group-item d-flex clickable justify-content-between align-items-center align-items-center">
                                                <div className="left-list-group">
                                                    <div className="form-check pb-0 mb-0">
                                                        <label className="form-check-label" htmlFor={`flexCheckDefaultRms${alarm.id}`}>
                                                            {alarm.name}
                                                        </label>
                                                    </div>
                                                </div>
                                                <span className={`badge ${alarm.active_alarm_count > 0 ? 'badge-secondary-red' : 'badge-secondary'} rounded-pill`}>{alarm.active_alarm_count}</span>
                                            </Link>
                                        </>
                                    ))}
                                </ul>
                            </>
                        ))}
                    </div>
                </div>
                <div className="col-md-8">


                </div>
            </div>
        </>
    )
}