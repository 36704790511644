import { useDispatch, useSelector } from "react-redux";
import API from "../../Network/API";
import { DOOR_FETCH_ERROR, DOOR_FETCH_SUCCESS } from "../../Reducer/DoorSlice";
import { useEffect } from "react";
import AlarmTypeOnline from "../Lock/AlarmTypeOnline";
import AlarmTypeCard from "../Lock/AlarmTypeCard";
import Paginate from "../../CustomHook/Paginate";
import CountdownTimer from "../../Props/CountdownTimer";
import { useState } from "react";
import { DOOR_STATUS_FETCH_SUCCESS } from "../../Reducer/DoorStatusSlice";
import { DoorList } from "../../CustomMemo/DoorList";

export default function DoorOpen() {
    const [nxtPage, setNextPage] = useState(null);
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.doorReducer)
    const [isLoading, setLoading] = useState(false);

    const paginate = (pageNumber) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/all_door?page=' + pageNumber).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(DOOR_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            dispatch(DOOR_FETCH_ERROR(error))
        });
    };

    useEffect(() => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/all_door').then((response) => {
          if (loaderElement && !loaderElement.hasAttribute('hidden')) {
            loaderElement.setAttribute('hidden', 'true');
          }
          dispatch(DOOR_FETCH_SUCCESS(response.data.data))
          setLoading(false)
        }).catch((error) => {
          setLoading(true)
        });
    
    
        API.get('/v1/oauth/door_status').then((response) => {
    
          dispatch(DOOR_STATUS_FETCH_SUCCESS(response.data.data))
    
        }).catch((error) => {
    
        });
      }, []);


    useEffect(() => {
        if (nxtPage != null) {
            const loaderElement = document.querySelector(".loading-mask");
            loaderElement.removeAttribute('hidden');
            loaderElement.setAttribute('visible', 'true');
            API.get(nxtPage).then((response) => {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
                dispatch(DOOR_FETCH_SUCCESS(response.data.data))

            }).catch((error) => {
                dispatch(DOOR_FETCH_ERROR(error))
            });
        }

    }, [nxtPage]);

    const previousPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const nextPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const search = (site_code) => {
        API.get('/v1/oauth/door_status?site_id=' + site_code).then((response) => {
            dispatch(DOOR_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            dispatch(DOOR_FETCH_ERROR(error))
        });
    }

    return (
        <>
            <div className="row">
               <AlarmTypeOnline/>
               <AlarmTypeCard/>
            </div>

            <table className="table table-bordered table-striped m-0 table-sm bg-white">
                <thead className="text-uppercase">
                    <tr>
                        <th className="toi-primary">Last Updated</th>
                        <th width="10%" className="text-center toi-primary">Cluster</th>
                        <th width="10%" className="text-center toi-primary">Zone</th>
                        <th width="10%" className="text-center toi-primary">Site ID</th>
                        <th width="10%" className="text-center toi-primary">easi Site ID</th>
                        <th className="toi-primary">Elapsed Time</th>
                        <th className="text-center toi-primary">Network</th>
                        <th className="text-center toi-primary">Door</th>
                        <th className="text-center toi-primary">Lock</th>
                        <th className="text-center toi-primary">RFID</th>
                        <th className="text-center toi-primary">Vibration</th>
                        <th className="text-center toi-primary">Buzzer</th>
                    
                    </tr>
                </thead>
                <tbody>
                    {!selector?.loading && selector?.data?.sites?.map((site, index) => (
                       <DoorList status={`door`} site={site}/>
                    ))}

                </tbody>
            </table>

            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />

        </>
    );
}