import { Link } from "react-router-dom";
import API from "../../Network/API";
import toast from "react-hot-toast";
import { useState } from "react";

export default function PasswordChange() {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        const fd = new FormData();

        fd.append(`current_password`, event.target.current_password.value)
        fd.append(`password`, event.target.npassword.value)
        fd.append(`new_password`, event.target.new_password.value)

        API.post('/v1/oauth/password-change', fd)
            .then(response => {
                setLoading(false);
                setMessage([])
                toast.success(response.data.message);
            }).catch((error) => {
                setLoading(false);
                if (error.response.data.errors) {
                    // console.log(error.response.data.errors)
                    setMessage([error.response.data.errors]);
                } else {
                    setMessage([{ general: 'An error occurred while processing your request.' }]);
                }
                toast.error(error.response.data.message)
            });
    }

    return (
        <>
            <div className="row mt-5 mb-5">
                <div className="col-md-8 offset-md-2">
                    <div className="card">
                        <div className="card-header bg-white border-bottom">
                            <ul className="nav p-0 m-0">
                                <li className="nav-item">
                                    <Link className="nav-link text-dark" to={`/login-information`}><i className="fa-solid fa-user" /> Login Information</Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="#"><i className="fa-solid  fa-shield-alt" /> Change Password</a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <form method="POST" action="" acceptCharset="UTF-8" id="myForm" onSubmit={handleSubmit}>
                                <div className="form-group row">
                                    <label htmlFor="password" className="col-md-4 col-form-label text-md-right">Current Password <em className="text-danger">*</em></label>
                                    <div className="col-md-6">
                                        <input required id="password" type="password" className="form-control" name="current_password" autoComplete="current-password" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="password" className="col-md-4 col-form-label text-md-right">Password <em className="text-danger">*</em></label>
                                    <div className="col-md-6">
                                        <input required id="password" type="password" className="form-control" name="npassword" autoComplete="current-password" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="password" className="col-md-4 col-form-label text-md-right">Confirm Password <em className="text-danger">*</em></label>
                                    <div className="col-md-6">
                                        <input required id="new_password" type="password" className="form-control" name="new_password" autoComplete="new_password" />
                                    </div>
                                </div>

                                <div className="row">

                                    {message.length > 0 && (
                                        <div className="error-messages">
                                            <ul>
                                                {message.map((error, index) => (
                                                    <li key={index}>
                                                        {Object.values(error).map((err, k) => (
                                                            <span className="text-danger" key={k}>{err}</span>
                                                        ))}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-info waves-effect waves-light">Update Password {loading ? (
                                        <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                    ) : (
                                        <span></span>
                                    )}</button>
                                </div>
                            </form>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}