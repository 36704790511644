export default function TenantInfo({rms}){
    return (
        <>
          <div className="card-box" style={{ marginBottom: '10px' }}>
                        <h4 className="header-title mb-0" style={{ padding: '3px', fontSize: '12px', paddingBottom: '10px' }}>Tenant Info</h4>
                        <hr style={{ marginTop: '0px', marginBottom: '3px' }} />
                        <table className="table table-striped table-bordered table-centered mb-0  mt-1 alarm_table_custom">
                            <thead className="toi-primary text-white">
                                <tr>
                                    <th className="text-center">Tenant</th>
                                    <th className="text-center">On Air</th>
                                    <th className="text-center" title="Critical">CC(A)</th>
                                    <th className="text-center" title="Non-Critical">NC(A)</th>
                                    <th className="text-center" title="Voltage">Volt(V)</th>

                                </tr>
                            </thead>
                            <tbody>
                                {rms?.dc_energy_meter.length > 0 && rms?.dc_energy_meter.map((tenant, index) => (
                                    <tr className>
                                        <td>{tenant.tenant_name}</td>
                                        <td className="text-center">{tenant.on_air_date}</td>
                                        <td className="text-center">{tenant.acur}</td>
                                        <td className="text-center">{tenant.bcur}</td>
                                        <td className="text-center">{tenant.voltage}</td>
                                    </tr>
                                ))}

                                <tr className>
                                    <td>Unknown</td>
                                    <td />
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                    <td className="text-center">-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
        </>
    )
}