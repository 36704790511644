import { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { SITE_ID_FETCH_SUCCESS } from "../../../Reducer/SiteIdSlice";
import API from "../../../Network/API";
import ReactEcharts from "echarts-for-react";

export default function PgHome() {
    const echarts_react_1 = useRef();
    const ddd = useParams();
    const [rms, setRms] = useState()
    const [pg_run, setPgRun] = useState()
    const dispatch = useDispatch()
    const year = (new Date()).getFullYear();
    const yearLast = 2022;
    const years = Array.from(new Array(20), (val, index) => index + yearLast);
    const [monthId, setMonth] = useState();
    const [yearId, setYear] = useState(year);

    const months = [
        {
            'id': '01',
            'name': 'January',
        },
        {
            'id': '02',
            'name': 'February',
        },
        {
            'id': '03',
            'name': 'March',
        },
        {
            'id': '04',
            'name': 'April',
        },
        {
            'id': '04',
            'name': 'May',
        },
        {
            'id': '06',
            'name': 'June',
        },
        {
            'id': '07',
            'name': 'July',
        },
        {
            'id': '08',
            'name': 'August',
        },
        {
            'id': '09',
            'name': 'September',
        },
        {
            'id': '10',
            'name': 'October',
        },
        {
            'id': '11',
            'name': 'November',
        },
        {
            'id': '12',
            'name': 'December',
        }

    ];

    const getMonthNameById = (id) => {
        const month = months.find(month => month.id === id);
        return month ? month.name : null;
    };

    useEffect(() => {
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
    }, [ddd.id]);

    useEffect(() => {

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are 0-indexed
        setMonth(currentMonth < 10 ? '0' + currentMonth : currentMonth);

        API.get('/v1/oauth/pg_dashboard?site_id=' + ddd.id).then((response) => {
            setRms(response.data.data)
        }).catch((error) => {
        });

        run_hour_history()

    }, [ddd.id]);

    const run_hour_history = () => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;

        API.get(`/v1/oauth/daily_run_hour?site_id=${ddd.id}&month=${monthId == undefined ? currentMonth < 10 ? '0' + currentMonth : currentMonth : monthId}&year=${yearId}`).then((response) => {
            setPgRun(response.data.data)
        }).catch((error) => {
        });

    }

    const option3 = {
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: pg_run?.hour
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            },
            formatter: function (params) {
                var customTitle = 'Run Hour';

                // Initialize the content with the custom title
                var tooltipContent = '<div>' + customTitle + '</div>';

                // Iterate through data points and add day name and temperature to the content
                for (var i = 0; i < params.length; i++) {
                    var dayName = params[i].name;
                    var temperature = params[i].value;
                    tooltipContent += '<div>' + dayName + ': ' + temperature + ' hr</div>';
                }

                return tooltipContent;
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} hr'
            }
        },
        series: [
            {
                data: pg_run?.total_run,
                type: 'line',
                areaStyle: {
                    color: 'rgba(4, 184, 181, 0.2)',
                }
            }
        ]
    };


    return (
        <>
            <div className="row g-2">

                <div className="col-md-4" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-light"><i class="fa-light fa-circle-info me-1"></i>PG Run Hour</div>
                        <div className="card-body bg-white" style={{ height: '270px' }}>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <article class="d-flex justify-content-between">
                                        <div class="stat-cards-icon bg-main-color text-white">
                                            <i class={`fa-solid ${rms?.pg_status == 1 ? 'fa-check' : 'fa-power-off'}`}></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class="stat-cards-info__num p-0 m-0 text-red">{rms?.pg_status == 1 ? 'ON' : 'Off'}</p>
                                            <p class="stat-cards-info__title p-0 m-0">PG Status</p>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-12 mb-3">
                                    <article class="d-flex justify-content-between">
                                        <div class="stat-cards-icon bg-main-color text-white">
                                            <i class="fa-solid fa-hourglass"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class="stat-cards-info__num p-0 m-0">{rms?.today_run}</p>
                                            <p class="stat-cards-info__title p-0 m-0">Today Run hour</p>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-12 mb-3">
                                    <article class="d-flex justify-content-between">
                                        <div class="stat-cards-icon bg-main-color text-white">
                                            <i class="fa-solid fa-hourglass"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class="stat-cards-info__num p-0 m-0">{rms?.monthly_run}</p>
                                            <p class="stat-cards-info__title p-0 m-0">{rms?.month_name} Run hour</p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-8" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">
                            <b class="main-card-title mb-0 flex-grow-1"> <i class="fa-light fa-bar-chart me-2"></i>{getMonthNameById(monthId)},{yearId} Run Hour History</b>

                            <div class="flex-shrink-0">
                                <div class="app-search">
                                    <div class="input-group input-group-sm">

                                        <select required name="month_id" className="form-select" onChange={(e) => setMonth(e.target.value)}>
                                            <option value={``}>Choose.......</option>
                                            {months.map((month, index) => (
                                                <option key={index} value={month.id} selected={monthId == month.id ? true : false} >{month.name}</option>
                                            ))}
                                        </select>
                                        <select name="year" required className="form-select" onChange={(e) => setYear(e.target.value)}>
                                            {years?.map((value, index) => (
                                                <option key={`year${index}`} value={value} selected={year == value ? true : false}>{value}</option>
                                            ))}
                                        </select>
                                        <button onClick={() => run_hour_history()} className="btn btn-success"><i className="fa-light fa-search"></i></button>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="card-body bg-white p-0 m-0" >
                            <ReactEcharts lazyUpdate={true} notMerge={true} ref={echarts_react_1} style={{ height: "260px" }} option={option3} />

                        </div>
                    </div>
                </div>

                <div className="col-md-12" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-light"><i class="fa-light fa-circle-info me-1"></i>Sep,2023 DC Consumption History</div>
                        <div className="card-body bg-white" style={{ height: '260px' }}>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}