import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { SITE_ID_FETCH_SUCCESS } from "../../../../Reducer/SiteIdSlice";
import { BATTERY_ID_FETCH_SUCCESS } from "../../../../Reducer/batteryIDSlice";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import CellNav from "./CellNav";
import ReactEcharts from "echarts-for-react";
import API from "../../../../Network/API";
import CellCard from "./CellCard";
export default function CellHome() {
    const ddd = useParams();
    const [battery, setBattery] = useState([])
    const [rms, setRms] = useState()
    const [battery_chart, setChart] = useState()
    const echarts_react_1 = useRef();
    const echarts_react_2 = useRef();
    const echarts_react_3 = useRef();
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
        dispatch(BATTERY_ID_FETCH_SUCCESS(ddd.sl))
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');

        API.get(`/v1/oauth/bms_home/${ddd.id}/cell/${ddd.sl}`).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            // console.log(response.data.data.rms)
            setRms(response.data.data)
            setBattery(response.data.data.batteries)

        }).catch((error) => {

            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });

        last_soc_one_hour()
    }, [ddd.id]);

    const last_soc_one_hour=()=>{
      
        API.get(`/v1/oauth/battery_soc_last_one_hour?site_id=${ddd.id}&battery_sl_id=${ddd.sl}`).then((response) => {
            setChart(response.data.data)
        }).catch((error) => {

        });
    }

    const option =  {

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['SOC','Current']
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: battery_chart?.array_time
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'SOC',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: battery_chart?.array_soc
          }, {
            name: 'Current',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: battery_chart?.array_current
          }
        ]
      }

      const option2 =  {

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['Battery Temperature','Battery Voltage']
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: battery_chart?.array_time
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Battery Temperature',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: battery_chart?.array_temp
          },{
            name: 'Battery Voltage',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: battery_chart?.array_voltage
          }
        ]
      }

     const option3 = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
         
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['NTC1', 'NTC2', 'NTC3','NTC4','Voltage']
        },
        xAxis: [
          {
            type: 'category',
            data: battery_chart?.array_time,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'NTC4',
            min: 0,
            max: 60,
            interval: 10,
            axisLabel: {
              formatter: '{value} °C'
            }
          },
          {
            type: 'value',
            name: 'Voltage',
            min: 0,
            max: 60,
            interval: 10,
            axisLabel: {
              formatter: '{value} V'
            }
          }
        ],
        series: [
          {
            name: 'NTC1',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' °C';
              }
            },
            data: battery_chart?.array_ntc1
          },
          {
            name: 'NTC2',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' °C';
              }
            },
            data:battery_chart?.array_ntc2
          },
          {
            name: 'NTC3',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' °C';
              }
            },
            data:battery_chart?.array_ntc3
          },
          
          {
            name: 'Voltage',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' V';
              }
            },
            data: battery_chart?.array_voltage
          }
        ]
      };
    return (
        <>
            <div className="row">
                <CellNav id={1} site_id={ddd.id} battery_id={ddd.sl} />
            </div>

            <div className="row ms-1 me-1 mb-2">
                <div className="col-md-6" >
                    <div className="card bg-dark shadow-sm border-0 bg-cc mt-2" >

                        <div className="row" >
                            <div className="col-sm-6 col-md-6 mt-3  text-center">
                                <div className="lprogress" data-percentage={battery?.soc}>
                                    <span className="progress-left">
                                        <span className="progress-bar" />
                                    </span>
                                    <span className="progress-right">
                                        <span className="progress-bar" />
                                    </span>
                                    <div className="progress-value">
                                        <div className="text-white">
                                            <span>SoC</span><br />
                                            {battery?.soc}<br />
                                            <span>%</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between temp-icon" >
                                    <div class="st-left-temp d-flex" title="Battery temperature">
                                        <i class="fa-solid fa-temperature-high me-2 text-white"></i>
                                        <h3>{battery?.bat_temp}<span>°C</span> </h3>
                                    </div>
                                    <div class="st-right-temp d-flex" title="Environment temperature">
                                        <i class="fa-solid fa-temperature-high me-2 text-white"></i>
                                        <h3>{battery?.env_temp}<span>°C</span> </h3>
                                    </div>
                                </div>
                                <div class="last-time mb-2 text-white"><b>Last Update</b>, {battery?.device_time}</div>
                            </div>
                            <div className="col-md-6 col-sm-6 mt-3" style={{ height: '200px', overflow: 'auto' }}>
                                <div className="lebel mb-2 me-1 ms-1">
                                    <div className="d-flex justify-content-between">
                                        <div className="left-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white"> SoH</h3>
                                        </div>
                                        <div className="right-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white">  {battery?.soh}<span>%</span></h3>
                                        </div>
                                    </div>
                                    <div className="progress" style={{ height: `10px` }}>
                                        <div className="progress-bar" role="progressbar" aria-valuenow={battery?.soh} aria-valuemin={0} aria-valuemax={100} style={{ width: `${battery?.soh}%`, backgroundColor: 'orange' }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="lebel mb-2 me-1 ms-1">
                                    <div className="d-flex justify-content-between">
                                        <div className="left-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white"> Pack Voltage</h3>
                                        </div>
                                        <div className="right-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white">  {battery?.pack_voltage}<span>V</span></h3>
                                        </div>
                                    </div>
                                    <div className="progress" style={{ height: '10px' }}>
                                        <div className="progress-bar" role="progressbar" aria-valuenow={battery?.pack_voltage} aria-valuemin={0} aria-valuemax={100} style={{ width: `${battery?.pack_voltage}%`, backgroundColor: 'orange' }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="lebel mb-2 me-1 ms-1">
                                    <div className="d-flex justify-content-between">
                                        <div className="left-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white"> Pack Current</h3>
                                        </div>
                                        <div className="right-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white">  {battery?.pack_current}<span>A</span></h3>
                                        </div>
                                    </div>
                                    <div className="progress" style={{ height: '10px' }}>
                                        <div className="progress-bar" role="progressbar" aria-valuenow={battery?.pack_current} aria-valuemin={0} aria-valuemax={100} style={{ width: `${battery?.pack_current}%`, backgroundColor: 'orange' }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="lebel mb-2 me-1 ms-1">
                                    <div className="d-flex justify-content-between">
                                        <div className="left-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white"> Power</h3>
                                        </div>
                                        <div className="right-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white">  {battery?.power}<span>kW</span></h3>
                                        </div>
                                    </div>
                                    <div className="progress" style={{ height: '10px' }}>
                                        <div className="progress-bar" role="progressbar" aria-valuenow={battery?.power} aria-valuemin={0} aria-valuemax={100} style={{ width: `${battery?.power}%`, backgroundColor: 'orange' }}>
                                        </div>
                                    </div>
                                </div>

                                <div className="lebel mb-2 me-1 ms-1" title="Negative temperature coefficient">
                                    <div className="d-flex justify-content-between">
                                        <div className="left-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white"> NTC 1</h3>
                                        </div>
                                        <div className="right-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white"> {battery?.ntc1} <span>°C</span></h3>
                                        </div>
                                    </div>
                                    <div className="progress" style={{ height: '10px' }}>
                                        <div className="progress-bar" role="progressbar" aria-valuenow={battery?.ntc1} aria-valuemin={0} aria-valuemax={100} style={{ width: `${battery?.ntc1}%`, backgroundColor: 'orange' }}>
                                        </div>
                                    </div>
                                </div>

                                <div className="lebel mb-2 me-1 ms-1" title="Negative temperature coefficient">
                                    <div className="d-flex justify-content-between">
                                        <div className="left-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white"> NTC 2</h3>
                                        </div>
                                        <div className="right-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white"> {battery?.ntc2} <span>°C</span></h3>
                                        </div>
                                    </div>
                                    <div className="progress" style={{ height: '10px' }}>
                                        <div className="progress-bar" role="progressbar" aria-valuenow={battery?.ntc2} aria-valuemin={0} aria-valuemax={100} style={{ width: `${battery?.ntc2}%`, backgroundColor: 'orange' }}>
                                        </div>
                                    </div>
                                </div>

                                <div className="lebel mb-2 me-1 ms-1" title="Negative temperature coefficient">
                                    <div className="d-flex justify-content-between">
                                        <div className="left-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white"> NTC 3</h3>
                                        </div>
                                        <div className="right-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white"> {battery?.ntc3} <span>°C</span></h3>
                                        </div>
                                    </div>
                                    <div className="progress" style={{ height: '10px' }}>
                                        <div className="progress-bar" role="progressbar" aria-valuenow={battery?.ntc3} aria-valuemin={0} aria-valuemax={100} style={{ width: `${battery?.ntc3}%`, backgroundColor: 'orange' }}>
                                        </div>
                                    </div>
                                </div>

                                <div className="lebel mb-2 me-1 ms-1" title="Negative temperature coefficient">
                                    <div className="d-flex justify-content-between">
                                        <div className="left-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white"> NTC 4</h3>
                                        </div>
                                        <div className="right-temp d-flex">
                                            <h3 className="mb-0 p-0 text-white"> {battery?.ntc4} <span>°C</span></h3>
                                        </div>
                                    </div>
                                    <div className="progress" style={{ height: '10px' }}>
                                        <div className="progress-bar" role="progressbar" aria-valuenow={battery?.ntc4} aria-valuemin={0} aria-valuemax={100} style={{ width: `${battery?.ntc4}%`, backgroundColor: 'orange' }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row g-1">
                        <div className="col-md-4">
                            <CellCard bgColor={`green-tea-gradient`} icon={`fa-light fa-battery-full`} title={`Capacity`} value={`100Ah`}/>
                        </div>
                        <div className="col-md-4">
                            <CellCard bgColor={`bg-green`} icon={`fa-light fa-table-cells`} title={`Total cell`} value={`15`}/>
                        </div>

                        <div className="col-md-4">
                            <CellCard bgColor={`green-tea-gradient1`} icon={`fa-solid fa-charging-station`} title={`Status`} value={`${battery?.status==1?'Charging':'Discharging'}`}/>
                        </div>
                    
                        <div className="col-md-4">
                   
                            <CellCard bgColor={`bg-green-1`} icon={`fa-light fa-bolt`} title={`Avg Cell`} value={`${rms?.avg_volt}v`}/>
                        </div>
                        <div className="col-md-4">
                        
                            <CellCard bgColor={`bg-green-2`} icon={`fa-light fa-bolt`} title={`Max. Cell`} value={`${rms?.max_cell}v`}/>
                        </div>
                        <div className="col-md-4">
                    
                            <CellCard bgColor={`green-tea-gradient`} icon={`fa-duotone fa-timer text-dark`} title={`Rem. backup`} value={`${rms?.backup_time}`}/>
                        </div>
                    </div>
                </div>
              
                <div className="col-md-6">
                    <div className="card shadow-sm border-0 bg-cc mt-2" style={{ minHeight: '300px' }}>
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                            <b class="main-card-title mb-0 flex-grow-1">Battery SOC & Current</b>
                            <div class="flex-shrink-0">
                                <div class="app-search">
                                    <div class="input-group">
                                        <div class="position-relative">
                                            Today last 1 Hour
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-white">
                        <ReactEcharts ref={echarts_react_1} style={{ height: "260px" }} option={option} />
                        

                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow-sm border-0 bg-cc mt-2" style={{ minHeight: '300px' }}>
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                            <b class="main-card-title mb-0 flex-grow-1">Battery Voltage & Temperature</b>
                            <div class="flex-shrink-0">
                                <div class="app-search">
                                    <div class="input-group">
                                        <div class="position-relative">
                                            Today last 1 Hour
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-white">
                        <ReactEcharts ref={echarts_react_2} style={{ height: "260px" }} option={option2} />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="card shadow-sm border-0 bg-cc mt-2" style={{ minHeight: '300px' }}>
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                            <b class="main-card-title mb-0 flex-grow-1">NTC Temperature</b>
                            <div class="flex-shrink-0">
                                <div class="app-search">
                                    <div class="input-group">
                                        <div class="position-relative">
                                            Today last 1 Hour
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-white">
                        <ReactEcharts ref={echarts_react_3} style={{ height: "300px" }} option={option3} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}