import { useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { ADD_VENDOR_SUCCESS } from "../../Reducer/VendorSlice";
import { toast } from "react-hot-toast";
import API from "../../Network/API";
import { useDispatch } from "react-redux";
import { ADD_VENDOR_USER_SUCCESS } from "../../Reducer/VendorUserSlice";

export default function AddVendorUser(props) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        const fd = new FormData();
        fd.append(`organization_id`, event.target.organization_id.value)
        fd.append(`email`, event.target.email.value)
        fd.append(`name`, event.target.name.value)
        fd.append(`phone`, event.target.phone.value)
        fd.append(`card_number`, event.target.card_number.value)
        fd.append(`user_type`, 2)

        API.post('/v1/oauth/vendor_users/store', fd)
            .then(response => {
                setLoading(false);
                dispatch(ADD_VENDOR_USER_SUCCESS(response.data?.data))
                props.handleShowB();
                document.getElementById("add_country_form").reset()
                toast.success(response.data.message);
            }).catch((error) => {
                setLoading(false);
                // console.log(error)
                toast.error("error.response.data.message")
            });
    }
    return (
        <>

            <Modal
                isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
            >
                <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                    Add User
                </ModalHeader>
                <ModalBody className="bg-light">
                    <div className="meeting-schedule-popup">
                        <form onSubmit={handleSubmit} id="add_country_form">
                            <div className="form-group">
                                <label className="control-label">Organization <em className="text-danger">*</em></label>
                                <select required className="form-select" name="organization_id">
                                    <option value={``}>Choose.......</option>
                                    {props?.organigation?.length > 0 && props?.organigation?.map((row, index) => (
                                        <option value={row.id}>{row.name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-group">
                                <label className="control-label">Name<em className="text-danger">*</em></label>
                                <input className="form-control" required name="name" type="text" />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Phone<em className="text-danger">*</em></label>
                                <input className="form-control" required name="phone" type="text" />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Email<em className="text-danger">*</em></label>
                                <input className="form-control" required name="email" type="email" />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Card Number<em className="text-danger">*</em></label>
                                <input className="form-control" required name="card_number" type="text" />
                            </div>

                            <ModalFooter className={'post-footer-comment-icon bg-light'}>

                                <button type="submit" className="btn btn-success rounded-pill"><i class="fa-light fa-save me-1"></i> Save{loading ? (
                                    <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                ) : (
                                    <span></span>
                                )}</button>

                            </ModalFooter>
                        </form>
                    </div>

                </ModalBody>
            </Modal>
        </>
    )
}