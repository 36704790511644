
import { createSlice } from '@reduxjs/toolkit';

export const DoorStatusSlice = createSlice({
    name:'doorStatus',
    initialState:{
        loading: false,
        data: {

        },
        error: false,
    },
    reducers:{
        DOOR_STATUS_FETCH_START:(state)=>{
            state.loading=true
        },
        DOOR_STATUS_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
        },
        DOOR_STATUS_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { DOOR_STATUS_FETCH_START,DOOR_STATUS_FETCH_SUCCESS,DOOR_STATUS_FETCH_ERROR }=DoorStatusSlice.actions;
export default DoorStatusSlice.reducer;