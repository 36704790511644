export default function DcTenantTab(props){
return(
    <>
      <div className={`card mt-2 ${props.borderClass}`} >
                                <div className="row">

                                    <div className="col-md-4">
                                        <div style={{padding:'5px'}}>
                                            <div class={`ion_box  text-white box-shadow-11 ${props.bgColor}`}><i class="fa-regular fa-transformer-bolt "></i></div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                       
                                        <table className="text-left" width={`100%`}>
                                            <tr>
                                                <td className="text-center" style={{fontSize:'14px',fontWeight:'700'}} colSpan={2}>{props.name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontSize:'12px',fontWeight:'500'}}><b>Load</b></td>
                                                <td style={{fontSize:'11px',fontWeight:'500'}}>{props.load} W</td>
                                            </tr>
                                            <tr>
                                                <td style={{fontSize:'12px',fontWeight:'500'}}><b>Current</b></td>
                                                <td style={{fontSize:'12px',fontWeight:'500'}}>{props.current} A</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
    </>
)
}