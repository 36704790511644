import Env from "../../CustomHook/Env";
import HelperClass from "../../CustomHook/HelperClass";

export default function RectifierAvgReport({ loading, get_data, nextPage }){
    return (
        <>
        <div className="ms-1 me-1" id="rectifier_report">

             
<div className="table-responsive">
    <table className="table table-bordered mt-2 bg-white" >
        <thead>
            <tr>
                <th className="toi-primary text-center">Date</th>
                <th className="toi-primary text-center">SITE ID</th>
                <th className="toi-primary text-center">{Env.CUSTOMER_SITE} SITE ID</th>
                <th className="toi-primary text-center">Tenant Name</th>
                <th className="toi-primary text-center">Rectifier Name</th>
                <th className="toi-primary text-center">Avarage(kW)</th>
                <th className="toi-primary text-center">Maximum(kW)</th>

               
            </tr>
        </thead>
        <tbody>
            {!loading && get_data?.data?.map((row, index) => (
                <tr key={index}>
                    <td className="fs-11 text-dark text-center"><span style={{width:'100px'}}>{row.date}</span></td>
                    <td className="fs-11 text-dark text-center">{row.site_id}</td>
                    <td className="fs-11 text-dark text-center">{row.easi_site_id}</td>
                    <td className="fs-11 text-dark text-center">{row.tenant_name}</td>
                    <td className="fs-11 text-dark text-center">{row.rectifier_name}</td>
                    <td className="fs-11 text-dark text-center">{row.avg_kw}</td>
                    <td className="fs-11 text-dark text-center">{row.max_kw}</td>




                </tr>
            ))}
        </tbody>
    </table>

</div>

{get_data?.has_page ?
    <div class="float-md-end mb-1 mt-1">
        <div class="btn-group ">
            {get_data?.first_page ?
                <a class="btn btn-primary btn-sm" style={{ cursor: 'no-drop' }}>Prev</a>
                :
                <a class="btn btn-primary btn-sm " onClick={() => nextPage(get_data?.prv_page_url)}>Prev</a>
            }

            {get_data?.has_more_page ?
                <a class="btn btn-info btn-sm " onClick={() => nextPage(get_data?.next_page_url)}>Next</a>
                :
                <a class="btn btn-info btn-sm " style={{ cursor: 'no-drop' }}>Next</a>

            }
        </div>
    </div> : ''
}


</div>
        </>
    )
}