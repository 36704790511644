import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

export default function HomeRmsAlarmGrid() {

    const selector = useSelector((state) => state.homeReducer)
    const alarms = selector?.data

   
    return (
        <>
            <div className="card ms-1">
                <div class="card-header  border-bottom card-title"><i class="fa-light fa-circle-info me-1" /> RMS Alarm
                    Statistics
                </div>
                <div className="card-body" style={{ padding: '0px' }}>

                    <div className="table-responsive-lg tableFixHead">
                        <table className="table table-striped table-bordered table-centered mb-0 text-uppercase mt-0 alarm_table_custom">
                            <thead>
                                <tr>
                                    <th className="text-center toi-primary">Alarm Name</th>
                                    <th className="text-center toi-primary">Total</th>
                                    <th className="text-center toi-primary">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!selector?.loading && alarms.total_rms_alarm?.map((rms_alrm, index) => (
                                    <tr>
                                        <td className="text-center"><Link  to={`/rms_monitoring/alarm/${rms_alrm.order_list}`}>{rms_alrm.name}</Link></td>
                                        <td className="text-center">{rms_alrm.active_alarm_count}</td>
                                        <td className="text-center">
                                            <div className={`alarm_icon ${rms_alrm.active_alarm_count>0?'A3':'A1'}`}>&nbsp;</div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </>
    )

}