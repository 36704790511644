
import { createSlice } from '@reduxjs/toolkit';

export const rectifierIDSlice = createSlice({
    name: 'rectifierID',
    initialState: {
        loading: false,
        data: {

        },
        error: false,
    },
    reducers: {
        RECTIFIER_ID_FETCH_START: (state) => {
            state.loading = true
        },
        RECTIFIER_ID_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        RECTIFIER_ID_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { RECTIFIER_ID_FETCH_START, RECTIFIER_ID_FETCH_ERROR, RECTIFIER_ID_FETCH_SUCCESS } = rectifierIDSlice.actions;
export default rectifierIDSlice.reducer;