import { useEffect } from "react";
import { SITE_ID_FETCH_SUCCESS } from "../../../Reducer/SiteIdSlice";
import { RECTIFIER_ID_FETCH_SUCCESS } from "../../../Reducer/RectifierIDSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import RectifierNav from "./RectifierNav";

export default function SiteRectifierGraph(){
    const ddd = useParams();
    const dispatch = useDispatch()
    const [data, setData] = useState()

    useEffect(() => {
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
        dispatch(RECTIFIER_ID_FETCH_SUCCESS(ddd.rid))

    }, [ddd.id]);
    return (
        <>
         <div className="row">
                <RectifierNav id={2} site_id={ddd.id} acm_id={ddd.rid} />
        </div>

        </>
    )
}