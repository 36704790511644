import { MapContainer, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";

export default function Map(){
    return (
        <>
        <MapContainer center={[24.165843, 90.549038]} zoom={8} maxZoom={50} attribution={`Ajra`} scrollWheelZoom={false}>
  <TileLayer
    attribution='&copy; Ajra'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker position={[23.7745978,90.4219535]} title="ST30484">
  <Popup>Popup for Marker</Popup>
  </Marker>
</MapContainer>
        </>
    )
}