import { useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { ADD_VENDOR_SUCCESS, EDIT_VENDOR_FETCH_SUCCESS } from "../../Reducer/VendorSlice";
import { toast } from "react-hot-toast";
import API from "../../Network/API";
import { useDispatch } from "react-redux";

export default function EditVendor(props) {
    const [loading, setLoading] = useState(false);
    const dispatch=useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        const fd = new FormData();
        fd.append(`email`, event.target.email.value)
        fd.append(`name`, event.target.name.value)
        fd.append(`phone`, event.target.phone.value)
        fd.append('_method','PUT')
        API.post(`/v1/oauth/vendors/${props?.data?.id}/update`, fd)
            .then(response => {
                setLoading(false);               
                   dispatch(EDIT_VENDOR_FETCH_SUCCESS(response.data?.data))
                   props.handleShowB();
                  
                 toast.success(response.data.message);
            }).catch((error) => {
                setLoading(false);
                // console.log(error)
                 toast.error(error.response.data.message)
            });
    }
    return (
        <>

            <Modal
                isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
            >
                <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                    Edit Organisation
                </ModalHeader>
                <ModalBody className="bg-light">
                    <div className="meeting-schedule-popup">
                        <form onSubmit={handleSubmit} id="add_country_form">

                            <div className="form-group">
                                <label className="control-label">Name<em className="text-danger">*</em></label>
                                <input className="form-control" required name="name" type="text" defaultValue={props?.data?.name} />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Phone<em className="text-danger">*</em></label>
                                <input className="form-control" required name="phone" type="text" defaultValue={props?.data?.phone}/>
                            </div>
                            <div className="form-group">
                                <label className="control-label">Email<em className="text-danger">*</em></label>
                                <input className="form-control" required name="email" type="email" defaultValue={props?.data?.email}/>
                            </div>

                            <ModalFooter className={'post-footer-comment-icon bg-light'}>

                                <button type="submit" className="btn btn-success rounded-pill"><i class="fa-light fa-save me-1"></i> Save{loading ? (
                                    <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                ) : (
                                    <span></span>
                                )}</button>

                            </ModalFooter>
                        </form>
                    </div>

                </ModalBody>
            </Modal>
        </>
    )
}