

import { createSlice } from '@reduxjs/toolkit';

export const RmsAlarmTypeSlice = createSlice({
    name:'RmsAlarmTypeSlice',
    initialState:{
        loading: false,
        data: [],
        error: false,
    },
    reducers:{
        RMS_ALARM_TYPE_FETCH_START:(state)=>{
            state.loading=true
            state.data= []
        },
        RMS_ALARM_TYPE_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=[...state.data,action.payload]
        },
        RMS_ALARM_TYPE_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { RMS_ALARM_TYPE_FETCH_START,RMS_ALARM_TYPE_FETCH_SUCCESS,RMS_ALARM_TYPE_FETCH_ERROR }=RmsAlarmTypeSlice.actions;
export default RmsAlarmTypeSlice.reducer;