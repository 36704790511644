import { Link } from "react-router-dom";

export default function AcsNav(props){

    return (
        <>
         <ul className="dc_nav">
                    <li className={`cus-tab ${props.id==1?'active':''}`}><Link to={`/site/${props.site_id}/ac_energy_meter/${props.acm_id}`}>Overview</Link></li>
                    <li className={`cus-tab ${props.id==2?'active':''}`}><Link to={`/site/${props.site_id}/ac_energy_meter/${props.acm_id}/graph`}>Graph</Link></li>
                    {/* <li className={`cus-tab ${props.id==3?'active':''}`}><Link to={`/site/${props.site_id}/ac_energy_meter/${props.acm_id}/report`}>Report</Link></li> */}
              </ul>
        </>
    )
}