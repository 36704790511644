
import { createSlice } from '@reduxjs/toolkit';

export const acmIDSlice = createSlice({
    name: 'acmIDSlice',
    initialState: {
        loading: false,
        data: {

        },
        sites:[],
        error: false,
    },
    reducers: {
        ACM_ID_FETCH_START: (state) => {
            state.loading = true
        },
        ACM_ID_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        ACM_FETCH: (state, action) => {
            state.loading = false
            state.sites=action.payload.sites
        },
        ACM_ID_FETCH: (state, action) => {
            state.loading = false
            state.sites=[...state.sites,...action.payload.sites]
        },
        ACM_ID_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const {ACM_FETCH,ACM_ID_FETCH, ACM_ID_FETCH_START, ACM_ID_FETCH_ERROR, ACM_ID_FETCH_SUCCESS } = acmIDSlice.actions;
export default acmIDSlice.reducer;