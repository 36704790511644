import { createSlice } from '@reduxjs/toolkit';

export const DoorSlice = createSlice({
    name:'doorSlice',
    initialState:{
        loading: false,
        data: {

        },
        main_data:[],
        error: false,
    },
    reducers:{
        DOOR_FETCH_START:(state)=>{
            state.loading=true
        },
        DOOR_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.main_data=action.payload.sites
        },

        DOOR_FETCH_MORE_SUCCESS:(state,action)=>{
           state.loading=false
            state.data=action.payload
            state.main_data=[...state.main_data,...action.payload.sites]
        },

        EDIT_DOOR_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            const newArray = state.main_data.filter(todo => todo.id !==action.payload.id)
             state.main_data = [...newArray,action.payload]
        },
        DOOR_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const {DOOR_FETCH_MORE_SUCCESS, DOOR_FETCH_START,DOOR_FETCH_SUCCESS,DOOR_FETCH_ERROR,EDIT_DOOR_FETCH_SUCCESS }=DoorSlice.actions;
export default DoorSlice.reducer;