import { useEffect } from "react";
import { SITE_ID_FETCH_SUCCESS } from "../../../../Reducer/SiteIdSlice";
import DcTab from "../DcTab";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import API from "../../../../Network/API";
import ExportButton from "../../../../CustomHook/ExportButton";

export default function LoadDisconnect() {
    const [nxtPageAlarm, setNextPageAlarm] = useState(null);
    const [dateValue, setDateChange] = useState(new Date().toISOString().slice(0, 10))
    const [to_date, setToDateChange] = useState()
    const [get_data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const ddd = useParams();
    const dispatch = useDispatch()
    useEffect(() => {
        setLoading(true);
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))

        API.get('/v1/oauth/dc_load_disconnect?site_id=' + ddd.id).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setLoading(false);
            setData(response.data.data)
        }).catch((error) => {
            setLoading(false);
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });

    }, [ddd.id]);

   
    
      const nextPage = (nxtPage) => {
        setLoading(true);
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        var pagea = nxtPage + '&site_id='+ ddd.id+'&start_date=' + dateValue + '&end_date=' + to_date
        API.get(pagea).then((response) => {
          if (loaderElement) {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
              loaderElement.setAttribute('hidden', 'true');
            }
          }
          setLoading(false);
          setData(response.data.data)
    
        }).catch((error) => {
            setLoading(false);
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                  loaderElement.setAttribute('hidden', 'true');
                }
              }
        });
      }

      const search = () => {
        setLoading(true);
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        var pagea = '/v1/oauth/dc_load_disconnect?site_id='+ ddd.id+'&start_date=' + dateValue + '&end_date=' + to_date
        API.get(pagea).then((response) => {
          if (loaderElement) {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
              loaderElement.setAttribute('hidden', 'true');
            }
          }
          setLoading(false);
          setData(response.data.data)
    
        }).catch((error) => {
            setLoading(false);
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                  loaderElement.setAttribute('hidden', 'true');
                }
              }
        });
      }
    return (
        <>
            <div className="row">
                <DcTab id={4} site_id={ddd.id} />
            </div>
            <div className="row ms-1 me-1">
                <div className="card shadow-sm border-0 mt-2">
                    <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">
                        <b class="main-card-title mb-0 flex-grow-1">DC Load Disconnected</b>
                        <div class="flex-shrink-0">
                            <div class="">
                                <div class="input-group input-group-sm">

                                    <input value={dateValue} autoFocus={true} onChange={(e) => setDateChange(e.target.value)} type="date" class="form-control" autocomplete="off" />
                                    <span class="input-group-text">To</span>
                                    <input value={to_date} autoFocus={true} onChange={(e) => setToDateChange(e.target.value)} type="date" class="form-control" placeholder="" autocomplete="off" />
                                    <a href="#" onClick={()=>search()} class="input-group-text"><i className="fa-light fa-search" /></a>
                                    <ExportButton className="btn btn-info" tableId="load_disconnect" fileName="load_disconnect"/>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table table-bordered table-striped m-0 table-sm bg-white text-center" id="load_disconnect">
                            <thead className="text-uppercase">
                                <tr>
                                    <th className="toi-primary">Site ID</th>
                                    
                                    <th className="toi-primary">Tenant Name</th>
                                    <th className="toi-primary">Start Time</th>
                                    <th className="toi-primary">End Time</th>
                                    <th className="toi-primary">Peak Power</th>
                                    <th className="toi-primary">Elapsed Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading&&get_data?.alarms?.map((row,index)=>(
                                <tr>
                                    <td className="fs-11 text-dark">{row.site_id}</td>
                                    <td className="fs-11 text-dark">{row.tenant_name}</td>
                                   
                                    <td className="fs-11 text-dark">
                                        <p className="p-0 m-0">{row.alarm_date}</p>
                                        <p className="p-0 m-0">{row.alarm_time}</p>
                                    </td>
                                    <td className="fs-11 text-dark">
                                    <p className="p-0 m-0">{row.alarm_end_date}</p>
                                        <p className="p-0 m-0">{row.alarm_end_time}</p>
                                    </td>
                                    <td className="fs-11 text-dark">{row.peak_power}</td>
                                    <td className="text-dark elisp_time">{row.total_elaspsed}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>

                        {get_data?.has_page ?
              <div class="float-md-end mb-1 mt-1">
                <div class="btn-group ">
                  {get_data?.first_page ?
                    <a class="btn btn-primary btn-sm" style={{ cursor: 'no-drop' }}>Prev</a>
                    :
                    <a class="btn btn-primary btn-sm " onClick={() => nextPage(get_data?.prv_page_url)}>Prev</a>
                  }

                  {get_data?.has_more_page ?
                    <a class="btn btn-info btn-sm " onClick={() => nextPage(get_data?.next_page_url)}>Next</a>
                    :
                    <a class="btn btn-info btn-sm " style={{ cursor: 'no-drop' }}>Next</a>

                  }
                </div>
              </div> : ''
            }
                    </div>
                </div>

            </div>
        </>
    )
}