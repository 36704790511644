import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SITE_ID_FETCH_SUCCESS } from "../../../Reducer/SiteIdSlice";
import API from "../../../Network/API";
import { useState } from "react";
import { SITE_ASSET_FETCH_SUCCESS } from "../../../Reducer/SiteAssetSlice";
import ExportButton from "../../../CustomHook/ExportButton";
import AddAsset from "./AddAsset";

export default function SiteAsset() {
    const ddd = useParams();
    const dispatch = useDispatch()
    const [modalDialog, setModalDialog] = useState(false);
    const toggleModal = () => {
        setModalDialog(!modalDialog)
    }
    useEffect(() => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');

        API.get('/v1/oauth/site_assets?site_id=' + ddd.id).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            dispatch(SITE_ASSET_FETCH_SUCCESS(response.data.data))
            
        }).catch((error) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });

        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))

    }, [ddd.id]);

    const selector = useSelector((state) => state.siteAssetReducer)

    return (<>
        <div className="row ms-1 me-1">
            <div className="col-12" >
                <div className="card shadow-sm border-0 bg-cc mt-2">
                    <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                        <b class="main-card-title mb-0 flex-grow-1">Assets</b>
                        <div class="flex-shrink-0">

                            <div class="input-group input-group-sm">

                                <button type="button"  onClick={() => setModalDialog(!modalDialog)} className="btn btn-success"><i className="fa-light fa-plus"></i> Add Asset</button>
                              <ExportButton tableId={`asset`} fileName={'site_asset'} className={`btn btn-warning`}/>
                            </div>

                        </div>
                    </div>
                    <div className="card-body bg-white" >

                    <table className="table " id="asset">
                        <thead>
                            <tr className="toi-primary">
                                <th>Serial Number</th>
                                <th>Asset Number</th>
                                <th>PRODUCT TYPE</th>
                                <th>QTY</th>
                                <th>Installation Date</th>
                                <th>Model</th>
                                <th>Brand</th>
                                <th>Supplier</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!selector?.loading && selector?.main_data?.map((row,index)=>(
                            <tr>
                                <td>{row.asset_code}</td>
                                <td>{row.asset_number}</td>
                                <td>{row.asset_name}</td>
                                <td>{row.asset_qty}</td>
                                <td>{row.installation_date}</td>
                                <td>{row.model}</td>
                                <td>{row.brand}</td>
                                <td>{row.supplier}</td>
                            </tr>
                              ))}
                        </tbody>
                    </table>

                    <AddAsset site_id={ddd?.id} isModalVisible={modalDialog} handleShowB={() => setModalDialog(false)} />

                    </div>
                </div>
            </div>
        </div>
    </>)
}