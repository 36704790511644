import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACM_FETCH, ACM_ID_FETCH, ACM_ID_FETCH_SUCCESS } from "../../Reducer/acmIDSlice";
import API from "../../Network/API";
import HelperClass from "../../CustomHook/HelperClass";
import { Link } from "react-router-dom";
import Env from "../../CustomHook/Env";

export default function AcmIndex() {
    const [nxtPage, setNextPage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [main_data, setMainData] = useState([]);
    const [site_status, setStatus] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        all_door_load();
    }, []);

    useEffect(() => {

        if(page!=1){
            all_door_load_more()
        }
       
    }, [page]);

    const all_door_load_more = () => {
        setLoading(true)
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get(`/v1/oauth/acm_meters?page=${page}`).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(ACM_ID_FETCH(response.data.data))
            setLoading(false)
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    }

    const all_door_load = () => {
        setLoading(true)
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get(`/v1/oauth/acm_meters?page=${page}`).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(ACM_FETCH(response.data.data))
            setLoading(false)
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    }

    const searchSite = (name) => {
        setLoading(true)
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get(`/v1/oauth/acm_meters?site_id=${name}`).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(ACM_ID_FETCH(response.data.data))
            setLoading(false)
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    }

    const observer = useRef();
    const lastElementRef = useCallback((element) => {
        //element is the react element being referenced

        // disconnect observer set on previous last element
        if (observer.current) observer.current.disconnect();

        // if there's no more data to be fetched, don't set new observer
        if (!hasMore) return;

        // set new observer
        observer.current = new IntersectionObserver((entries) => {
            // increase page number when element enters (is intersecting with) viewport.
            // This triggers the pagination hook to fetch more items in the new page
            if (entries[0].isIntersecting && hasMore) setPage((prev) => prev + 1);
        });

        // observe/monitor last element
        if (element) observer.current.observe(element);
    }, [hasMore]);

    const selector = useSelector((state) => state.acmIDReducer)
    const strAscending = selector?.sites.length > 0 && [...selector?.sites].sort((a, b) =>
        a.site_id > b.site_id ? 1 : -1,
    );

    console.log(selector?.sites)
    return (
        <>
            <div className="row text-center  mb-2 mt-2 me-1 ms-1">


                <div className="d-flex justify-content-end">
                    <div class="input-group input-group-sm max-with-searbar">
                        <input type="text" onChange={(e) => searchSite(e.target.value)} class="form-control" placeholder="search by site id...." />
                        <button class="btn btn-success" type="button"><i class="fa-light fa-search" /></button>
                    </div>
                    <button class="btn btn-warning  ms-2" type="button" onClick={() => all_door_load()}><i class="fa-light fa-refresh me-1" />Sync</button>




                </div>

            </div>
            <div className="table-responsive" >

                <table className="table rms_table table-bordered bg-white table-striped m-0 text-center">
                    <thead className="text-uppercase">
                        <tr>
                            <th className="toi-primary w-150">Last Updated</th>
                            <th className="text-center toi-primary">Cluster</th>
                            <th className="text-center toi-primary">Zone</th>
                            <th className="text-center toi-primary">Site ID</th>
                            <th className="text-center toi-primary">{Env.CUSTOMER_SITE} Site ID</th>
                            <th className="text-center toi-primary">Network</th>
                            <th className="text-center toi-primary">ACM SL</th>
                            <th className="text-center toi-primary">PHASE(R)</th>
                            <th className="text-center toi-primary">PHASE(Y)</th>
                            <th className="text-center toi-primary">PHASE(B)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!selector?.loading && selector?.sites.length > 0 && strAscending?.map((site, index) => (
                            <tr className={`${site.device_live_status == 0 ? 'offline-tr' : ''}`} ref={index === selector?.sites.length - 1 ? lastElementRef : undefined}>
                                <td className="">
                                    <p className="m-0 p-0" style={{ fontSize: '12px', width: '150px' }}>{site.device_time}</p>

                                </td>
                                <td className="text-center">
                                    {site.cluster_name}
                                </td>
                                <td className="text-center">
                                    {site.zone_name}
                                </td>

                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.site_id}</Link>
                                </td>
                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.customer_site_id}</Link>
                                
                                </td>
                                <td className="text-center">
                                    {site.device_live_status === 1 ?
                                        <div className="signal-bars mt1 sizing-box good four-bars">
                                            <span>
                                                <img src="/assets/icon/6.svg" className="tower-height" alt="" />
                                            </span>
                                            <div className={`first-bar bar ${site.network_val >= 1 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`second-bar bar ${site.network_val >= 2 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`third-bar bar ${site.network_val >= 3 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fourth-bar bar ${site.network_val >= 4 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fifth-bar bar ${site.network_val >= 5 ? '' : 'null-color'}`}>
                                            </div>
                                        </div>
                                        :
                                        <i class="fa-solid fa-wifi-slash offline-rms-icon" title="Site not updating" />
                                    }
                                </td>

                                <td className="text-center">
                          
                            <Link className="fw-bold" to={`/site/${site.site_id}/ac_energy_meter/${site.acm_id}`}>{site.sl_id}</Link>
                                
                        </td>

                        <td className="text-center">
                            {site.avoltage} V
                        </td>

                        <td className="text-center">
                            {site.bvoltage} V
                        </td>

                        <td className="text-center">
                            {site.cvoltage} V
                        </td>

                            </tr>

                        ))}
                    </tbody>
                </table>
            </div>
            {loading && <div className="text-center">Loading......</div>}
        </>
    )

}