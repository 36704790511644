export default function JobNav(){
    return (
        <>
       
        <ul className="list-group list-group-flush">
          <li className="list-group-item  active " style={{cursor: 'pointer'}}>
            <div className="d-flex bd-highlight ">
              <div className="px-2 flex-grow-1 bd-highlight border-right"><a >All Job</a></div>
              <div className="px-2 bd-highlight "><span className="badge badge-secondary">0</span></div>
            </div>
          </li>
          <li className="list-group-item " style={{cursor: 'pointer'}} >
            <div className="d-flex bd-highlight">
              <div className="px-2 flex-grow-1 bd-highlight border-right"><a >Active Job</a></div>
              <div className="px-2 bd-highlight "><span className="badge badge-secondary">0</span></div>
            </div>
          </li>
          <li className="list-group-item " style={{cursor: 'pointer'}} >
            <div className="d-flex bd-highlight">
              <div className="px-2 flex-grow-1 bd-highlight  border-right"><a >Expired Job</a></div>
              <div className="px-2 bd-highlight"><span className="badge badge-secondary">0</span></div>
            </div>
          </li>
          <li className="list-group-item " style={{cursor: 'pointer'}} >
            <div className="d-flex bd-highlight">
              <div className="px-2 flex-grow-1 bd-highlight border-right"><a >Inactive Job</a></div>
              <div className="px-2 bd-highlight"><span className="badge badge-secondary">0</span></div>
            </div>
          </li>
        </ul>
      
        </>
    )
}