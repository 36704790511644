import { useDispatch } from "react-redux";
import { SITE_ID_FETCH_SUCCESS } from "../../../Reducer/SiteIdSlice";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactEcharts from "echarts-for-react";

import { useRef } from "react";
import API from "../../../Network/API";

export default function SiteTemperature() {
    const ddd = useParams();
    const echarts_react_1 = useRef();
    const echarts_react_2 = useRef();
    const echarts_react_3 = useRef();
    const [rms, setRms] = useState()
    const [rms_log, setRmsLog] = useState()
    const [temp_log, setTempLog] = useState()
    const dispatch = useDispatch()

    const year = (new Date()).getFullYear();
    const yearLast = 2022;
    const years = Array.from(new Array(20), (val, index) => index + yearLast);
    const [monthId, setMonth] = useState();
    const [yearId, setYear] = useState(year);

    const months = [
        {
            'id': '01',
            'name': 'January',
        },
        {
            'id': '02',
            'name': 'February',
        },
        {
            'id': '03',
            'name': 'March',
        },
        {
            'id': '04',
            'name': 'April',
        },
        {
            'id': '04',
            'name': 'May',
        },
        {
            'id': '06',
            'name': 'June',
        },
        {
            'id': '07',
            'name': 'July',
        },
        {
            'id': '08',
            'name': 'August',
        },
        {
            'id': '09',
            'name': 'September',
        },
        {
            'id': '10',
            'name': 'October',
        },
        {
            'id': '11',
            'name': 'November',
        },
        {
            'id': '12',
            'name': 'December',
        }

    ];

    const getMonthNameById = (id) => {
      const month = months.find(month => month.id === id);
      return month ? month.name : null;
  };

    useEffect(() => {
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are 0-indexed
        setMonth(currentMonth < 10 ? '0' + currentMonth : currentMonth);

      API.get('/v1/oauth/temp_dashboard?site_id=' + ddd.id).then((response) => {
          setRms(response.data.data)
      }).catch((error) => {
      });

      API.get('/v1/oauth/latest_temp?site_id=' + ddd.id).then((response) => {
        setRmsLog(response.data.data)
      }).catch((error) => {
      });

      run_hour_history()

    }, [ddd.id]);





    const option = {
        series: [
          {
            type: 'gauge',
            center: ['50%', '60%'],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 60,
            splitNumber: 12,
            itemStyle: {
              color: '#FFAB91'
            },
            progress: {
              show: true,
              width: 15
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                width: 15
              }
            },
            axisTick: {
              distance: -38,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            splitLine: {
              distance: -45,
              length: 10,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            axisLabel: {
              distance: -15,
              color: '#999',
              fontSize: 15
            },
            anchor: {
              show: false
            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              width: '60%',
              lineHeight: 40,
              borderRadius: 8,
              offsetCenter: [0, '-15%'],
              fontSize: 25,
              fontWeight: 'bolder',
              formatter: '{value} °C',
              color: 'inherit'
            },
            data: [
              {
                value: rms?.temperature
              }
            ]
          },
          {
            type: 'gauge',
            center: ['50%', '60%'],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 60,
            itemStyle: {
              color: '#FD7347'
            },
            progress: {
              show: true,
              width: 8
            },
            pointer: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            detail: {
              show: false
            },
            data: [
              {
                value: rms?.temperature
              }
            ]
          }
        ]
      };


      const optionAvg = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: temp_log?.time
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Avg Temperature',
            type: 'bar',
            emphasis: {
              focus: 'series'
            },
            data: temp_log?.avg_data
          },
          {
            name: 'Maximum Temperature',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: temp_log?.max_data
          },
          
        ]
      };


     const option3 = {
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: rms_log?.time
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            },
            formatter: function(params) {
                var customTitle = 'Temperature';
    
                // Initialize the content with the custom title
                var tooltipContent = '<div>' + customTitle + '</div>';
    
                // Iterate through data points and add day name and temperature to the content
                for (var i = 0; i < params.length; i++) {
                    var dayName = params[i].name;
                    var temperature = params[i].value;
                    tooltipContent += '<div>' + dayName + ': ' + temperature + '°C</div>';
                }
    
                return tooltipContent;
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value} °C'
            }
          },
        series: [
            {
                data: rms_log?.value,
                type: 'line',
                areaStyle: {
                    color: 'rgba(4, 184, 181, 0.2)',
                }
            }
        ]
    };

    const run_hour_history = () => {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;

      API.get(`/v1/oauth/daily_site_temp?site_id=${ddd.id}&month=${monthId == undefined ? currentMonth < 10 ? '0' + currentMonth : currentMonth : monthId}&year=${yearId}`).then((response) => {
        setTempLog(response.data.data)
      }).catch((error) => {
      });

  }

    return (
        <>

            <div className="row ms-1 me-1 g-2">
                <div className="col-md-4" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-light"><i class="fa-light fa-circle-info me-1"></i>Temperature</div>
                        <div className="card-body bg-white" >
                        <ReactEcharts lazyUpdate={true} notMerge={true} ref={echarts_react_1} style={{ height: "240px" }} option={option} />


                        </div>
                    </div>
                </div>

                <div className="col-md-8" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-light"><i class="fa-light fa-circle-info me-1"></i>Last 1 hour temperature</div>
                        <div className="card-body bg-white p-0 m-0" >
                       
                        <ReactEcharts lazyUpdate={true} notMerge={true} ref={echarts_react_3} style={{ height: "290px" }} option={option3} />


                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="card shadow-sm border-0 bg-cc mt-2">

                       
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">
                            <b class="main-card-title mb-0 flex-grow-1"> <i class="fa-light fa-bar-chart me-2"></i>{getMonthNameById(monthId)},{yearId} AVG & Max Temperatures</b>

                            <div class="flex-shrink-0">
                                <div class="app-search">
                                    <div class="input-group input-group-sm">

                                        <select required name="month_id" className="form-select" onChange={(e) => setMonth(e.target.value)}>
                                            <option value={``}>Choose.......</option>
                                            {months.map((month, index) => (
                                                <option key={index} value={month.id} selected={monthId == month.id ? true : false} >{month.name}</option>
                                            ))}
                                        </select>
                                        <select name="year" required className="form-select" onChange={(e) => setYear(e.target.value)}>
                                            {years?.map((value, index) => (
                                                <option key={`year${index}`} value={value} selected={year == value ? true : false}>{value}</option>
                                            ))}
                                        </select>
                                        <button onClick={() => run_hour_history()} className="btn btn-success"><i className="fa-light fa-search"></i></button>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="card-body bg-white" >
                       
                        <ReactEcharts lazyUpdate={true} notMerge={true} ref={echarts_react_2} style={{ height: "300px" }} option={optionAvg} />


                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}