export default function RectifierCard({row}){
    return (
        <>
        <div className="card-box" style={{ marginBottom: '10px' }}>
                        <h4 className="header-title mb-0" style={{ padding: '3px', fontSize: '12px', paddingBottom: '10px' }}>Rectifier ({row.rectifier_name})
                        </h4>
                        <hr style={{ marginTop: '0px', marginBottom: '3px' }} />
                        <table className="table table-striped table-bordered table-centered mb-0  mt-1 alarm_table_custom">
                            <thead className="toi-primary text-white">
                                <tr>
                                    <th className="text-left text-uppercase">Source</th>
                                    <th className="text-center"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-left text-uppercase">DC Voltage</td>
                                    <td className="text-center">
                                        {row.dc_voltage} V
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left text-uppercase">DC Current</td>
                                    <td className="text-center">
                                    {row.dc_current} A
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left text-uppercase">Cum DC Power</td>
                                    <td className="text-center">
                                    {row.cum_dc_power} kWh
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left text-uppercase">Cum AC Power</td>
                                    <td className="text-center">
                                    {row.cum_ac_power} kWh
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left text-uppercase">DC Active Power</td>
                                    <td className="text-center">
                                    {row.dc_active_power} kWh
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
        </>
    )
}