export default function RmsStationSearch({search}) {
    return (
        <>
            <div className="row mb-1 mt-1">
                <div className="d-flex justify-content-end" style={{alignItems:'baseline'}}>
                    <div className="search-box me-2 position-relative">
                        <input type="text" className="form-control border-radius-20" id="searchResultList" placeholder="Search site id..." onChange={(e)=>search(e.target.value)}/>
                        <i className="fa-light fa-search search-icon" />
                    </div>
                    <div className="filter me-2">
                        <a className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fa-solid fa-bars-filter"/> Filter
                        </a>
                        <ul className="dropdown-menu mx-0 shadow w-220px">
                            
                        </ul>
                    </div>
                    <div className="filter me-2">
                        <a className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fa-solid fa-table-columns"/> Show Fields
                        </a>
                        <ul className="dropdown-menu mx-0 shadow w-220px">
                        <li>
                                <a className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    UPDATED AT
                                    <div className="form-check form-switch mb-0 ">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    SITE ID
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    EASI SITE ID
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    NETWORK
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    DOOR OPEN
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </a>
                            </li>
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    MODULE FAULT
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    MAINS FAIL
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    MDB FAULT
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    PHASE FAULT
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    DC LOW
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    LLVD
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    ODC TEMP
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    FAN FAULT
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    SMOKE
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    WATER LEAKAGE
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    AVIATION LIGHT
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    FUSE FAULT
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-item d-flex gap-2 align-items-center justify-content-between" href="#">
                                    PG
                                    <div className="form-check form-switch mb-0">
                                        <input className="form-check-input" type="checkbox" role="switch" />
                                    </div>
                                </div>
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}