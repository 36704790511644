
import { createSlice } from '@reduxjs/toolkit';

export const AcsActiveAlarmSlice = createSlice({
    name: 'acsActiveAlarmSlice',
    initialState: {
        loading: false,
        data: {

        },
        error: false,
    },
    reducers: {
        ACS_ACTIVE_ALARM_FETCH_START: (state) => {
            state.loading = true
        },
        ACS_ACTIVE_ALARM_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        ACS_ACTIVE_ALARM_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { ACS_ACTIVE_ALARM_FETCH_START, ACS_ACTIVE_ALARM_FETCH_SUCCESS, ACS_ACTIVE_ALARM_FETCH_ERROR } = AcsActiveAlarmSlice.actions;
export default AcsActiveAlarmSlice.reducer;