import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function LoginInformation(){
    const { user } = useSelector((state) => state.profileReducer);
    return (
        <>
         <div className="row mt-5 mb-5">
        <div className="col-md-8 offset-md-2">
          <div className="card">
            <div className="card-header bg-white border-bottom">
              <ul className="nav p-0 m-0">
                <li className="nav-item">
                  <a className="nav-link active" href="#"><i className="fa fa-user" /> Login Information</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/password-change`}><i className="fas fa-shield-alt" /> Change Password</Link>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <label htmlFor="password" className="col-md-4 col-form-label text-md-right">Organization Name</label>
                <div className="col-md-6">
                  <input disabled type="text" className="form-control" defaultValue={`${user?.vendor_name}`} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="password" className="col-md-4 col-form-label text-md-right">Name</label>
                <div className="col-md-6">
                  <input disabled type="text" className="form-control" defaultValue={`${user?.name}`} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="password" className="col-md-4 col-form-label text-md-right">Email</label>
                <div className="col-md-6">
                  <input disabled type="text" className="form-control" defaultValue={`${user?.email}`} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="password" className="col-md-4 col-form-label text-md-right">Role</label>
                <div className="col-md-6">
                  <input disabled type="text" className="form-control" defaultValue={`${user?.role_name}`} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="password" className="col-md-4 col-form-label text-md-right">Last Login At</label>
                <div className="col-md-6">
                  <input disabled type="text" className="form-control" defaultValue={`${user?.last_login_at}`} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="password" className="col-md-4 col-form-label text-md-right">Last Login IP</label>
                <div className="col-md-6">
                  <input disabled type="text" className="form-control" defaultValue={`${user?.last_login_ip}`} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}