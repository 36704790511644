import { useState } from "react";
import { toast } from "react-hot-toast";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import API from "../Network/API";
import { useDispatch } from "react-redux";
import { EDIT_DOOR_FETCH_SUCCESS } from "../Reducer/DoorSlice";
import { useRef } from "react";
import { ALARM_SITE_CODE_FETCH_SUCCESS, EDIT_ALARM_DOOR_FETCH_SUCCESS } from "../Reducer/AlarmSiteCodeSlice";
export default function UnlockDoor(props) {
    const [loading, setLoading] = useState(false);
    const [step,setSteps]=useState(2)
    const modalRef1 = useRef()
    const dispatch=useDispatch();

    const setStep=(event)=>{
        if (event.target.checked) {
            setSteps(1)
        }else{
            setSteps(2)
        }
       
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        const fd = new FormData();
        if (event.target.requst_type.checked) {

        } else {
            if (event.target.company_name.value == '') {
                toast.error('Organization name was required')
                return 0;
            }
            if (event.target.name.value == '') {
                toast.error('Name was required')
                return 0;
            }

            if (event.target.phone.value == '') {
                toast.error('Phone number was required')
                return 0;
            }

            if (event.target.job.value == '') {
                toast.error('Job was required')
                return 0;
            }

            fd.append(`organization`, event.target.company_name.value)
            fd.append(`name`, event.target.name.value)
            fd.append(`phone`, event.target.phone.value)
        }
        fd.append(`site_id`, props.site_id)
        fd.append(`job`, event.target.job.value)
        fd.append(`password`, event.target.password.value)
        fd.append('requst_type', event.target.requst_type.checked ? 1 : 2)

        API.post('/v1/oauth/unlock_door', fd)
            .then(response => {
                setLoading(false);
                // console.log(response.data.data)
                  dispatch(EDIT_DOOR_FETCH_SUCCESS(response.data.data))
                  dispatch(EDIT_ALARM_DOOR_FETCH_SUCCESS(response.data.data))
                  props.handleShowB();
                setSteps(2)
                 document.getElementById("add_country_form").reset()
                 toast.success(response.data.message);
            }).catch((error) => {
                setLoading(false);
                toast.error(error.response.data.message)
            });
    }
    return (
        <>
            <Modal
                isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
                ref={modalRef1} >
                <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                    Door Unlock
                </ModalHeader>
                <ModalBody className="bg-light">
                    <div className="meeting-schedule-popup">
                        <form onSubmit={handleSubmit} id="add_country_form">
                            <div className="row mb-3">
                                <div className="col-12 mb-2">
                                    <div class="form-check form-switch">
                                        <input onChange={(e)=>setStep(e)} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="requst_type" />
                                        <label class="form-check-label" for="flexSwitchCheckDefault">Unlock for me</label>
                                    </div>
                                </div>

                                <div className={`col-12 mb-2 ${step==1?'d-none':''}`}>
                                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Organization</label>
                                    <input type="text" className="rounded-0 form-control" name="company_name" />
                                </div>
                                <div className={`col-12 mb-2 ${step==1?'d-none':''}`}>
                                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Name</label>
                                    <input type="text" className="rounded-0 form-control" name="name" />
                                </div>
                                <div className={`col-12 mb-2 ${step==1?'d-none':''}`}>
                                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Phone Number</label>
                                    <input type="text" className="rounded-0 form-control" name="phone" />
                                </div>

                                <div className={`col-12 mb-2`}>
                                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Job Details*</label>
                                    <textarea required type="text" className="rounded-0 form-control" name="job" />
                                </div>

                                <div className={`col-12 mb-2`}>
                                    <label htmlFor="password" className="form-label form-label-font-14">Password*</label>
                                    <input required type="password" className="rounded-0 form-control" name="password" />
                                </div>

                            </div>
                            <ModalFooter className={'post-footer-comment-icon bg-light'}>

                                <button type="submit" className="btn btn-success rounded-pill"><i class="fa-light fa-paper-plane-top me-1"></i> Unlock{loading ? (
                                    <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                ) : (
                                    <span></span>
                                )}</button>

                            </ModalFooter>
                        </form>
                    </div>

                </ModalBody>
            </Modal>
        </>
    )
}