import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DOOR_FETCH_ERROR, DOOR_FETCH_SUCCESS } from "../../Reducer/DoorSlice";
import API from "../../Network/API";
import { useEffect } from "react";
import Paginate from "../../CustomHook/Paginate";
import { Link } from "react-router-dom";

import OfflineNav from "./OfflineNav";

export default function OfflineSite() {
    const [isLoading, setLoading] = useState(false);
    const [nxtPage, setNextPage] = useState(null);
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.doorReducer)
    const selector_status = useSelector((state) => state.doorStatusReducer)
    const [modalDialog, setModalDialog] = useState(false);
    const toggleModal = () => {
        setModalDialog(!modalDialog)
    }

    const paginate = (pageNumber) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/offline_sites?page=' + pageNumber).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(DOOR_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            dispatch(DOOR_FETCH_ERROR(error))
        });
    };

    useEffect(() => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/offline_sites').then((response) => {
          if (loaderElement && !loaderElement.hasAttribute('hidden')) {
            loaderElement.setAttribute('hidden', 'true');
          }
          dispatch(DOOR_FETCH_SUCCESS(response.data.data))
          setLoading(false)
        }).catch((error) => {
          setLoading(true)
          if (loaderElement && !loaderElement.hasAttribute('hidden')) {
            loaderElement.setAttribute('hidden', 'true');
          }
        });

    }, []);

    const previousPage = (nxtPage) => {
        nextPrv(nxtPage)
    }

    const nextPage = (nxtPage) => {
        nextPrv(nxtPage)
    }

    const nextPrv = (page) => {
        API.get(page).then((response) => {
            dispatch(DOOR_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            dispatch(DOOR_FETCH_ERROR(error))
        });
    }

    const searchSite = (site_code) => {
        API.get('/v1/oauth/offline_sites?site_id='+site_code).then((response) => {
            dispatch(DOOR_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            dispatch(DOOR_FETCH_ERROR(error))
        });
    }


    const syncData=()=>{
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/offline_sites').then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(DOOR_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            dispatch(DOOR_FETCH_ERROR(error))
        });
    }



    return (
        <>
            <div className="row">
                <div className="row text-center  mb-2 mt-2">


                    <div className="d-flex justify-content-end">
                        <div class="input-group input-group-sm max-with-searbar">
                            <input type="text" onChange={(e)=>searchSite(e.target.value)} class="form-control" placeholder="search by site id...." />
                            <button class="btn btn-success" type="button"><i class="fa-light fa-search" /></button>
                       </div>
                        <button class="btn btn-warning  ms-2 me-2" type="button" onClick={()=>syncData()}><i class="fa-light fa-refresh me-1" />Sync</button>


                      

                        <OfflineNav data={selector?.data} active={1}/>

                    </div>

                </div>
            </div>

            <div className="table-responsive">

                <table className="table table-bordered table-striped m-0 table-sm bg-white text-center">
                    <thead className="text-uppercase">
                        <tr>
                            <th className="toi-primary">Last Updated</th>
                            <th className="toi-primary">Cluster</th>
                            <th className="toi-primary">Zone</th>
                            <th  className="text-center toi-primary" width={`10%`}>Site ID</th>
                            <th className="text-center toi-primary" width={`15%`}>easi Site ID</th>
                            <th className="text-center toi-primary">Site Name</th>
                            <th className="text-center toi-primary">Network</th>
                            
                        
                        </tr>
                    </thead>
                    <tbody>
                        {!selector?.loading && selector?.data?.sites?.map((site, index) => (
                            <tr key={index} className={`${site.device_live_status == 0 ? 'offline-tr' : ''}`}>
                                <td className="">
                                    {site.update_time}
                                </td>
                                <td className="">
                                    {site.cluster?.name}
                                </td>
                                <td className="">
                                {site.zone?.name}
                                </td>
                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.site_id}</Link>
                                </td>
                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.customer_site_id}</Link>
                                </td>
                                <td className="update_time_79">
                                {site.site_name}
                                </td>
                                <td className="text-center">
                                    {site.device_live_status === 1 ?
                                        <div className="signal-bars mt1 sizing-box good four-bars">
                                            <span>
                                                <img src="/assets/icon/6.svg" className="tower-height" alt="" />
                                            </span>
                                            <div className={`first-bar bar ${site.network_val >= 1 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`second-bar bar ${site.network_val >= 2 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`third-bar bar ${site.network_val >= 3 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fourth-bar bar ${site.network_val >= 4 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fifth-bar bar ${site.network_val >= 5 ? '' : 'null-color'}`}>
                                            </div>
                                        </div>
                                        :
                                        <i class="fa-solid fa-wifi-slash offline-rms-icon" title="Site not updating" />
                                    }
                                </td>
                                
    
                            
                            </tr>
                        ))}

                    </tbody>
                </table>

            </div>
            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />
          
        </>
    );

}