import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import API from "../../Network/API";
import Select from 'react-select'
import { toast } from "react-hot-toast";
import RmsAlarmReport from "./RmsAlarmReport";
import exportTableToExcel from "../../CustomHook/exportTableToExcel";
import exportTableToPdf from "../../CustomHook/exportTableToPdf";
import { useRef } from "react";
import AcmHistoryReport from "./AcmHistoryReport";
import BatteryLogReport from "./BatteryLogReport";
import DcEnergyConsumptionReport from "./DcEnergyConsumptionReport";
import DcAvailavilityReport from "./DcAvailavilityReport";
import DcEnergyMeterReport from "./DcEnergyMeterReport";
import DcKwReport from "./DcKwReport";
import DcKwReportMonthly from "./DcKwReportMonthly";
import PgRunReport from "./PgRunReport";
import LoadDisconnect from "./LoadDisconnect";
import OverloadReport from "./OverloadReport";
import PowerAvailability from "./PowerAvailability";
import DcKWHReport from "./DcKWhReport";
import OfflineReport from "./OfflineReport";
import AcmReport from "./AcmReport";
import RectifierAvgReport from "./RectifierAvgReport";
export default function ReportIndex() {
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const [nxtPage, setNextPage] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isLoading1, setLoading1] = useState(false);
    const [main_data, setMainData] = useState([]);
    const [site_status, setStatus] = useState([]);
    const [file_name, setFileName] = useState('report.csv');
    const [options, setOption] = useState([])
    const [options1, setOption1] = useState([])
    const [options2, setOption2] = useState([])
    const [options3, setOption3] = useState([])
    const [options4, setOption4] = useState([])
    const [selected_site, setSelectedSites] = useState([]);
    const dispatch = useDispatch();
    const [report_type, setReportType] = useState(0);
    const [cluster_id, setClusterId] = useState('');
    const [zone_id, setZoneId] = useState('');
    const [site_id, setSiteId] = useState('');
    const [tenant_id, setTenantId] = useState('');
    const [duration_type, setDurationType] = useState(1);
    const [start_date, setStartDate] = useState(new Date().toISOString().slice(0, 10));
    const [end_date, setEndDate] = useState('');
    const [rms_alarm_id, setRMSAlarmId] = useState([]);
    const [request, setRequest] = useState([]);
    const selectRef = useRef();
    const dateRef = useRef();
    const [resetKey, setResetKey] = useState(0);
    useEffect(() => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/report_index').then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            setOption(response.data.data.clusters)
            setOption1(response.data.data.report_types)
            setOption3(response.data.data.rms_alarms)
            setOption4(response.data.data.tenants)
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    }, []);

    const searchZone = (e) => {
        setOption2([])
        if (!e) {
            setOption2([])
            return;
        }
        setClusterId(e?.value)
        API.get(`/v1/oauth/ajax_cluster_zone/${e.value}`).then((response) => {
            setOption2(response.data.data.zone)
        }).catch((error) => {

        });
    }

    const searchZoneSite = (e) => {
        setSelectedSites([])
        if (!e) {
            setSelectedSites([])
            return;
        }
        setZoneId(e?.value)
        API.get(`/v1/oauth/ajax_sites?zone_id=${e.value}`).then((response) => {
            setSelectedSites(response.data.data.sites)
        }).catch((error) => {

        });
    }

    const search_site = (e) => {

        API.get(`/v1/oauth/ajax_sites?q=${e.target.value}`).then((response) => {
            setSelectedSites(response.data.data.sites)
        }).catch((error) => {

        });
    }

    const RMSAlarmId = (e) => {
        setRMSAlarmId(e)
    }



    const export_alarm = async () => {
        setLoading1(true)
        if (report_type == 0) {
            toast.error("Please select report type")
            setLoading(false)
            return;
        }

        if (duration_type == 2 && end_date == "") {
            toast.error("Please select End Date")
            setLoading1(false)
            return;
        }

    
        const fd = new FormData();
        fd.append(`report_type`, report_type)
        fd.append(`cluster`, cluster_id == undefined ? '' : cluster_id)
        fd.append(`zone`, zone_id == undefined ? '' : zone_id)
        fd.append(`site`, site_id == undefined ? '' : site_id)
        fd.append(`duration`, duration_type)
        // fd.append(`alarm_id`, rms_alarm_id)
        fd.append(`alarm_mode`, 1)
        fd.append(`start_date`, start_date)
        fd.append(`end_date`, end_date)
        fd.append(`tenant_id`, tenant_id == undefined ? '' : tenant_id)
        rms_alarm_id.map((rw, k) => {
            fd.append(`alarms[${k}]`, rw.value)
        })
        setRequest({
            start_date: start_date,
            end_date: end_date,
            duration_type: duration_type,
        })
        const response = await API.post('/v1/oauth/export_report', fd, {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setLoadingPercentage(percentCompleted);
            },
            responseType: 'blob',
        })
            .then(response => {
                setLoading1(false);
               
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(response.data);
                downloadLink.download = file_name;
                downloadLink.click();
                

            }).catch((error) => {
                setLoading1(false);
                toast.error(error.response.data.message)
            });

    }


    const search_alarm = () => {

        setLoading(true)
        if (report_type == 0) {
            toast.error("Please select report type")
            setLoading(false)
            return;
        }

        // if (report_type == 4 && duration_type==2 && tenant_id=="") {
        //     toast.error("Please select tenant")
        //     setLoading(false)
        //     return;
        // }

        if (duration_type == 2 && end_date == "") {
            toast.error("Please select End Date")
            setLoading(false)
            return;
        }


        const fd = new FormData();
        fd.append(`report_type`, report_type)
        fd.append(`cluster`, cluster_id == undefined ? '' : cluster_id)
        fd.append(`zone`, zone_id == undefined ? '' : zone_id)
        fd.append(`site`, site_id == undefined ? '' : site_id)
        fd.append(`duration`, duration_type)
        // fd.append(`alarm_id`, rms_alarm_id)
        fd.append(`alarm_mode`, 1)
        fd.append(`start_date`, start_date)
        fd.append(`end_date`, end_date)
        fd.append(`tenant_id`, tenant_id == undefined ? '' : tenant_id)
        rms_alarm_id.map((rw, k) => {
            fd.append(`alarms[${k}]`, rw.value)
        })
        setRequest({
            start_date: start_date,
            end_date: end_date,
            duration_type: duration_type,
        })
        API.post('/v1/oauth/search_report', fd, {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setLoadingPercentage(percentCompleted);
            },
        })
            .then(response => {
                setLoading(false)
                setMainData(response.data.data)
            }).catch((error) => {
                setLoading(false);
                toast.error(error.response.data.message)
            });
    }

    const nextPage = ($page) => {
        const fd = new FormData();
        fd.append(`report_type`, report_type)
        fd.append(`cluster`, cluster_id)
        fd.append(`zone`, zone_id)
        fd.append(`site`, site_id)
        fd.append(`duration`, duration_type)
        // fd.append(`alarm_id`, rms_alarm_id)
        fd.append(`alarm_mode`, 1)
        fd.append(`start_date`, start_date)
        fd.append(`end_date`, end_date)
        fd.append(`tenant_id`, tenant_id == undefined ? '' : tenant_id)

        rms_alarm_id.map((rw, k) => {
            fd.append(`alarms[${k}]`, rw.value)
        })
        API.post($page, fd)
            .then(response => {
                setMainData(response.data.data)
            }).catch((error) => {

                toast.error(error.response.data.message)
            });
    }



    const resetForm = () => {
        selectRef.current.select?.clearValue();
        setReportType(0);
        setClusterId('');
        setZoneId('');
        setSiteId('');
        setTenantId('');
        setDurationType(1);
        setStartDate(new Date().toISOString().slice(0, 10));
        setResetKey((prevKey) => prevKey + 1);
        setEndDate('');
        setRMSAlarmId([]);

    }
    const handleExportToExcel = () => {
        if (report_type == 1) {
            exportTableToExcel('RMS_Alarm', 'RMS_Alarm');
        } else if (report_type == 2) {

            exportTableToExcel('bms_history', 'BMS_HISTORY');
        }
        else if (report_type == 7) {

            exportTableToExcel('acm_history', 'ACM_Report');
        }
        else if (report_type == 3) {

            exportTableToExcel('dc_enercy_consum_history', 'DC_ENERGY_CONSUMPTION');
        }
        else if (report_type == 14) {

            exportTableToExcel('dc_availavility_history', 'DC_Availavilty');
        }
        else if (report_type == 6) {

            exportTableToExcel('dc_energy_history', 'DC_Energy_Meter');
        }
        else if (report_type == 5) {

            exportTableToExcel('pg_history', 'PG_RUN_Report');
        }
        else if (report_type == 9) {

            exportTableToExcel('load_disconnect_history', 'Site_Down_Report');
        }
        else if (report_type == 10) {

            exportTableToExcel('overload_history', 'Overload_Report');
        }
        else if (report_type == 13) {

            exportTableToExcel('power_history', 'Power_Availability_Report');
        }
        else if (report_type == 4) {

            exportTableToExcel('dc_kw_history', 'Dc_kW_Report');
        }
        else if (report_type == 12) {

            exportTableToExcel('offline_history', 'offline_history');
        }
        else if (report_type == 17) {

            exportTableToExcel('rectifier_report', 'rectifier_report');
        }
    }

    const handleExportToPdf = () => {
        if (report_type == 1) {
            exportTableToPdf('RMS_Alarm', 'RMS_Alarm.pdf');
        }

        else if (report_type == 2) {

        }
    }

    const Print = () => {
        //console.log('print');  
        let printContents = document.getElementById('RMS_Alarm').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }
    return (
        <>
            <div className="container-fluid mb-2 mt-2">
                <div className="row g-1">
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="form-group col-md-12 mb-1">
                                            <label>Report Type<em className="text-danger">*</em></label>
                                            <Select key={resetKey} ref={selectRef} defaultValue={report_type} onChange={(e) => setReportType(e?.value)} name="report_type" isClearable closeMenuOnSelect={true} options={options1} />
                                        </div>
                                        <div className="form-group col-md-12 mb-1">
                                            <label>Cluster</label>
                                            <Select key={resetKey} onChange={searchZone} name="cluster" isClearable closeMenuOnSelect={true} options={options} />
                                        </div>
                                        <div className="form-group col-md-12 mb-1">
                                            <label>Zone</label>
                                            <Select key={resetKey} name="zone" isClearable onChange={searchZoneSite} closeMenuOnSelect={true} options={options2} />
                                        </div>
                                        <div className="form-group col-md-12 mb-1">
                                            <label className="control-label">SITE ID</label>
                                            <Select key={resetKey} isClearable name="site" required onChange={(e) => setSiteId(e?.value)} onKeyDown={(e) => search_site(e)} closeMenuOnSelect={true} options={selected_site} />
                                        </div>
                                        {report_type == 1 ?
                                            <div className="form-group col-md-12 mb-1">
                                                <label>RMS Alarm</label>
                                                <Select key={resetKey} isMulti placeholder={`All`} name="alarm_id" onChange={(e) => RMSAlarmId(e)} options={options3} />
                                            </div>
                                            : ''}

                                        <div className="form-group col-md-12 mb-1">
                                            <label className="control-label">Duration</label>
                                            <select key={resetKey} onChange={(e) => setDurationType(e.target.value)} className="form-select" name="duration">
                                                <option value={1}>Daily</option>
                                                <option value={2}>Date Range</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-12 mb-1">
                                            <label className="control-label">Start Date </label>
                                            <input key={resetKey} ref={dateRef} defaultValue={start_date} onChange={(e) => setStartDate(e.target.value)} type="date" name="start_date" className="form-control" />
                                        </div>
                                        {duration_type == 2 ?
                                            <div className="form-group col-md-12 mb-1">
                                                <label className="control-label">End Date</label>
                                                <input key={resetKey} defaultValue={end_date} onChange={(e) => setEndDate(e.target.value)} type="date" name="end_date" className="form-control" />
                                            </div>
                                            : ''
                                        }
                                        {duration_type == 2 && report_type == 4 ?
                                            <div className="form-group col-md-12 mb-1">
                                                <label>Tenant</label>
                                                <Select onChange={(e) => setTenantId(e?.value)} key={resetKey} name="tenant_id" isClearable closeMenuOnSelect={true} options={options4} />
                                            </div> : ''}
                                    </div>
                                    <div className="mt-2">
                                        <button onClick={() => search_alarm()} type="button" className="btn btn-success"><i class="fa-light fa-search me-1"></i> Search{isLoading ? (
                                            <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                        ) : (
                                            <span></span>
                                        )}</button>
                                        <button onClick={() => export_alarm()} type="button" className="btn btn-info ms-2"><i class="fa-light fa-file-export me-1"></i> Export{isLoading1 ? (
                                            <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                        ) : (
                                            <span></span>
                                        )}</button>
                                        <button onClick={() => resetForm()} type="button" className="btn btn-danger ms-2"><i class="fa-light fa-refresh me-1"></i> Reset</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="card">
                            <div class="align-items-center d-flex p-2">
                                <h6 class="main-card-title mb-0 flex-grow-1">
                                    Report

                                </h6>
                                <div class="flex-shrink-0 text-end">
                                    <button className="btn btn-sm btn-light dropdown">
                                        <a className="" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">Download</a>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{}}>
                                            <li><a class="dropdown-item" href="#" onClick={() => handleExportToExcel()}><i class="fa-duotone fa-download"></i> Export</a></li>
                                            {/* <li><a class="dropdown-item" href="#" onClick={() => Print()}><i class="fa-duotone fa-print"></i> Print</a></li> */}
                                            {/* <li><a class="dropdown-item" href="#" onClick={() => handleExportToPdf()}><i class="fa-duotone fa-file-pdf"></i> Download as PDF</a></li> */}
                                        </ul>
                                    </button>

                                </div>
                            </div>
                            <div className="card-body" style={{ height: '500px', backgroundColor: '#b9b9b9', overflow: 'auto' }}>
                                {report_type == 1 ?
                                    <RmsAlarmReport loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                    : report_type == 7 ?
                                        <AcmReport loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                        : report_type == 2 ?
                                            <BatteryLogReport loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                            : report_type == 3 ?
                                                <DcEnergyConsumptionReport loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                                : report_type == 14 ?
                                                    <DcAvailavilityReport loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                                    : report_type == 6 ?
                                                        <DcEnergyMeterReport loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                                        : report_type == 4 ?
                                                            <DcKwReport loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                                            //  :duration_type == 2 && report_type == 4 ?
                                                            //  <DcKwReportMonthly loading={isLoading} get_data={main_data} nextPage={nextPage} request={request}/> 

                                                            : report_type == 5 ?
                                                                <PgRunReport loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                                                : report_type == 9 ?
                                                                    <LoadDisconnect loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                                                    : report_type == 10 ?
                                                                        <OverloadReport loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                                                        : report_type == 13 ?
                                                                            <PowerAvailability loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                                                            : report_type == 8 ?
                                                                                <DcKWHReport loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                                                                : report_type == 12 ?
                                                                                    <OfflineReport loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />
                                                                                    : report_type == 17 ?
                                                                                    <RectifierAvgReport loading={isLoading} get_data={main_data} nextPage={nextPage} request={request} />

                                                                                    :
                                                                                    ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}