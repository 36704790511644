import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../Network/API";
import { useEffect } from "react";
import Paginate from "../../CustomHook/Paginate";
import { Link } from "react-router-dom";
import { DC_ENERGY_FETCH_LOAD_MORE, DC_ENERGY_FETCH_SUCCESS } from "../../Reducer/DcEnergyMeterSlice";

export default function DcEnergy() {
    const [nxtPage, setNextPage] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [main_data, setMainData] = useState([]);
    const [site_status, setStatus] = useState([]);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => { 
        syncData('');
     }, []);

     useEffect(() => {

        if(page!=1){
            all_load()
        }
       
    }, [page]);
    

     const observer = useRef();
     const lastElementRef = useCallback((element) => { 
         //element is the react element being referenced
         
         // disconnect observer set on previous last element
         if (observer.current) observer.current.disconnect();
           
         // if there's no more data to be fetched, don't set new observer
         if (!hasMore) return;
           
         // set new observer
         observer.current = new IntersectionObserver((entries) => {
           // increase page number when element enters (is intersecting with) viewport.
           // This triggers the pagination hook to fetch more items in the new page
           if (entries[0].isIntersecting && hasMore) setPage((prev) => prev + 1);
         });
       
         // observe/monitor last element
         if (element) observer.current.observe(element);
       }, [hasMore]);

    const syncData = (name) => {
        setLoading(true)
        API.get('/v1/oauth/dc_energy_meter?page=1&site_id='+name).then((response) => {
            setLoading(false);
            setPage(1)
            setHasMore(response.data.data.has_more_page)
            dispatch(DC_ENERGY_FETCH_SUCCESS(response.data.data))
            setStatus(response.data.data.site_status)
            setLoading(false)
        }).catch((error) => {
            setLoading(false);
        });
    }

    const all_load = () => {
        setLoading(true)
        API.get(`/v1/oauth/dc_energy_meter?page=${page}`).then((response) => {
            setLoading(false);
            setHasMore(response.data.data.has_more_page)
            dispatch(DC_ENERGY_FETCH_LOAD_MORE(response.data.data))
            setStatus(response.data.data.site_status)
        }).catch((error) => {
            setLoading(false)
        });
    }

    const selector = useSelector((state) => state.dcEnergyMeterReducer)
    const strAscending = selector?.sites.length > 0 && [...selector?.sites].sort((a, b) =>
        a.site_id > b.site_id ? 1 : -1,
    );

    return (
        <>
            <div className="row text-center  mb-2 mt-2 me-1 ms-1">
                <div className="d-flex justify-content-end">
                    <div class="input-group input-group-sm max-with-searbar">
                        <input onChange={(e)=>syncData(e.target.value)} type="text" class="form-control" placeholder="search by site id...." />
                        <button class="btn btn-success" type="button"><i class="fa-light fa-search" /></button>
                    </div>
                    <button onClick={()=>syncData('')} class="btn btn-warning  ms-2" type="button" ><i class="fa-light fa-refresh me-1" />Sync</button>


                    <div class="dropdown custom-dropdown-grop-btn ms-2">
                        <Link to={`/rms_alarms?type=25`} class="btn btn-outline-primary bg-white text-dark " type="button" aria-expanded="false">
                            <i class="fa-duotone fa-bell"></i> Overloaded (<span className="badge bg-danger">{site_status.length>0 ? site_status[0]?.overload:0}</span>)
                        </Link>

                    </div>

                    <div class="dropdown custom-dropdown-grop-btn ms-2">
                        <Link to={`/rms_alarms?type=26`} class="btn btn-outline-info text-dark bg-white " type="button"  aria-expanded="false">
                            <i class="fa-duotone fa-bell"></i> Load Disconnect (<span className="badge bg-danger">{site_status.length>0 ? site_status[0]?.load_disconnect:0}</span>)
                        </Link>
                    </div>

                </div>

                <div className="table-responsive mt-2">
                    <table className="table table-bordered table-striped m-0 table-sm bg-white text-center">
                        <thead className="text-uppercase">
                            <tr>
                                <th className="toi-primary" width={`10%`}>Last Updated</th>
                                <th className="text-center toi-primary" width={`7%`}>Cluster</th>
                                <th className="text-center toi-primary" width={`7%`}>Zone</th>
                                <th className="text-center toi-primary" width={`5%`}>Site ID</th>
                                <th className="text-center toi-primary" width={`7%`}>easi Site ID</th>
                                <th className="text-center toi-primary">Network</th>
                                <th className="text-center toi-primary">Node</th>
                                <th className="text-center toi-primary">Tenant</th>
                                <th className="text-center toi-primary">CC(A)</th>
                                <th className="text-center toi-primary">NC(A)</th>
                                <th className="text-center toi-primary">Current(A)</th>
                                <th className="text-center toi-primary">Voltage(V)</th>
                                <th className={`text-center toi-primary `}>Load(W)</th>
                            </tr>
                        </thead>
                        <tbody>
                      {!selector?.loading && selector?.sites.length > 0 && strAscending?.map((site, index) => (
                            <tr className={`${site.device_live_status == 0 ? 'offline-tr' : ''}`}  ref={index === selector?.sites.length - 1 ? lastElementRef : undefined}>
                                <td>
                                    {site.update_time}
                                </td>
                                <td>
                                    {site.cluster_name}
                                </td>
                                <td >
                                    {site.zone_name}
                                </td>

                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.site_id}</Link>
                                </td>
                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.customer_site_id}</Link>
                                </td>

                                <td className="text-center">
                                    {site.device_live_status === 1 ?
                                        <div className="signal-bars mt1 sizing-box good four-bars">
                                            <span>
                                                <img src="/assets/icon/6.svg" className="tower-height" alt="" />
                                            </span>
                                            <div className={`first-bar bar ${site.network_val >= 1 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`second-bar bar ${site.network_val >= 2 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`third-bar bar ${site.network_val >= 3 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fourth-bar bar ${site.network_val >= 4 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fifth-bar bar ${site.network_val >= 5 ? '' : 'null-color'}`}>
                                            </div>
                                        </div>
                                        :
                                        <i class="fa-solid fa-wifi-slash offline-rms-icon" title="Site not updating" />
                                    }
                                </td>
                                <td className="font-bold">
                                    {site.node}
                                </td>
                                <td className="font-bold">
                                    {site.tenant_name}
                                </td>
                                <td className="font-bold">
                                    {site.acur}
                                </td>
                                <td className="font-bold">
                                    {site.bcur}
                                </td>
                                <td className="font-bold">
                                    {site.current}
                                </td>
                                <td className="font-bold">
                                    {site.voltage}
                                </td>
                            
                                <td className={`font-bold ${site.is_danger==1?'text-danger':''}`}>
                                    {site.load}
                                </td>
                                </tr>
                                  ))}
                        </tbody>
                    </table>
                    {isLoading && <div className="text-center">Loading......</div>}
                </div>
            </div>
        </>
    )
}