
import { createSlice } from '@reduxjs/toolkit';

export const siteCodeSlice = createSlice({
    name:'siteCode',
    initialState:{
        loading: false,
        data: {

        },
        error: false,
    },
    reducers:{
        SITE_CODE_FETCH_START:(state)=>{
            state.loading=true
        },
        SITE_CODE_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
        },
        SITE_CODE_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { SITE_CODE_FETCH_START,SITE_CODE_FETCH_ERROR,SITE_CODE_FETCH_SUCCESS }=siteCodeSlice.actions;
export default siteCodeSlice.reducer;