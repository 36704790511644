import React from 'react';
import CountdownTimer from '../Props/CountdownTimer';
export function DoorList({ site,status }) { 
    return (
        <>
          <tr className={`${site.device_live_status == 0 ? 'offline-tr' : ''}`}>
                            <td className="update_time_79">
                                {site.update_time}
                            </td>
                            <td className="text-center">
                                {site.cluster?.name}
                            </td>
                            <td className="text-center">
                                {site.zone?.name}
                            </td>
                            <td className="text-center">
                                {site.site_id}
                            </td>
                            <td className="text-center">
                                {site.customer_site_id}
                            </td>
                            <td id="row_79" data-value="1970-01-01 00:00:00" className="text-center elisp_time rows_79" style={{ borderRight: '1px solid #cccccc!important' }}>
                                {site.door_status == 1 ?
                                    <CountdownTimer targetDate={site.door_open_time} />
                                    : '00:00:00'}
                            </td>
                            <td className="text-center">
                                {site.device_live_status === 1 ?
                                    <div className="signal-bars mt1 sizing-box good four-bars">
                                        <span>
                                            <img src="/assets/icon/6.svg" className="tower-height" alt="" />
                                        </span>
                                        <div className={`first-bar bar ${site.network_val >= 1 ? '' : 'null-color'}`}>
                                        </div>
                                        <div className={`second-bar bar ${site.network_val >= 2 ? '' : 'null-color'}`}>
                                        </div>
                                        <div className={`third-bar bar ${site.network_val >= 3 ? '' : 'null-color'}`}>
                                        </div>
                                        <div className={`fourth-bar bar ${site.network_val >= 4 ? '' : 'null-color'}`}>
                                        </div>
                                        <div className={`fifth-bar bar ${site.network_val >= 5 ? '' : 'null-color'}`}>
                                        </div>
                                    </div>
                                    :
                                    <i class="fa-solid fa-wifi-slash offline-rms-icon" title="Site not updating" />
                                }
                            </td>
                            <td className="text-center">
                                {site.door_status == 1 && site.breach_status == 0 ?

                                    <img src="/assets/icon/door-yello.png" alt="" className="img-size" title="Door Open" />
                                    :
                                    site.breach_status == 1 ?
                                        <img src="/assets/icon/door-red.png" alt="" className="img-size" title="Security Breach" />
                                        :
                                        site.door_open_timeout == 1 && site.breach_status == 0 ?
                                            <img src="/assets/icon/door-red.png" alt="" className="img-size" title="Door Open Timeout" />
                                            :
                                            <img src="/assets/icon/door-green.png" alt="" className="img-size" title="Door Closed" />

                                }
                            </td>
                            <td className="text-center">

                                {site.lock_fault == 0 && site.lock_open_status == 1 ?

                                    <img src="/assets/icon/lock-yello.png" alt="" className="img-size" title="Lock Open" />
                                    :
                                    site.lock_fault == 1 ?
                                        <img src="/assets/icon/lock-red.png" alt="" className="img-size" title="Lock Fault" />
                                        :
                                        site.lock_close_fail == 1 ?
                                            <img src="/assets/icon/lock-red.png" alt="" className="img-size" title="Lock Close Failed" />
                                            :
                                            <img src="/assets/icon/lock-green.png" alt="" className="img-size" title="Lock Closed" />

                                }
                            </td>
                            <td className="text-center">
                                {site.card_reader_fault == 0 && site.card_temper_alart == 1 ?

                                    <img src="/assets/icon/lock-yello.png" alt="" className="img-size" title="Card Temper Alert" />
                                    :
                                    site.card_reader_fault == 1 && site.card_temper_alart == 0 ?
                                        <img src="/assets/icon/lock-red.png" alt="" className="img-size" title="Card Reader Fault" />
                                        :
                                        <img src="/assets/icon/rfid-green.png" alt="" className="img-size" title="" />

                                }

                            </td>
                            <td className="text-center">
                                {site.vib_sens_status == 1 ?
                                    <i class="fa-solid fa-sensor-on text-red" style={{ fontSize: '18px' }} />
                                    :
                                    <i class="fa-solid fa-sensor-on text-success" style={{ fontSize: '18px' }} />
                                }

                            </td>
                            <td className="text-center">
                                {site.buz_status == 1 ?
                                    <span className="fa-solid fa-bullhorn text-red" title="Normal" style={{ fontSize: '18px' }} />
                                    :
                                    <span className="fa-solid fa-bullhorn text-success" title="Normal" style={{ fontSize: '18px' }} />
                                }

                            </td>
                           
                        </tr>
        </>
       
    );
}
export const MemoDoorList = React.memo(DoorList);