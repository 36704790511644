import { useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { ADD_VENDOR_SUCCESS, EDIT_VENDOR_FETCH_SUCCESS } from "../../Reducer/VendorSlice";
import { toast } from "react-hot-toast";
import API from "../../Network/API";
import { useDispatch } from "react-redux";
import { EDIT_VENDOR_USER_FETCH_SUCCESS } from "../../Reducer/VendorUserSlice";

export default function EditVendorUser(props) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        const fd = new FormData();
        fd.append(`organization_id`, event.target.organization_id.value)
        fd.append(`email`, event.target.email.value)
        fd.append(`name`, event.target.name.value)
        fd.append(`phone`, event.target.phone.value)
        fd.append(`card_number`, event.target.card_number.value)
        fd.append(`status`, event.target.status.value)
        fd.append('_method', 'PUT')
        API.post(`/v1/oauth/vendor_users/${props?.data?.id}/update`, fd)
            .then(response => {
                setLoading(false);
                dispatch(EDIT_VENDOR_USER_FETCH_SUCCESS(response.data?.data))
                props.handleShowB();
                toast.success(response.data.message);
            }).catch((error) => {
                setLoading(false);
                toast.error(error.response.data.message)
            });
    }
    return (
        <>

            <Modal
                isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
            >
                <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                    Edit User
                </ModalHeader>
                <ModalBody className="bg-light">
                    <div className="meeting-schedule-popup">
                        <form onSubmit={handleSubmit} id="add_country_form">
                            <div className="form-group">
                                <label className="control-label">Organization <em className="text-danger">*</em></label>
                                <select required className="form-select" name="organization_id">
                                    <option value={``}>Choose.......</option>
                                    {props?.organigation?.length > 0 && props?.organigation?.map((row, index) => (
                                        <option key={index} selected={row.id == props?.data?.organization_id ? true : false} value={row.id}>{row.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="control-label">Name<em className="text-danger">*</em></label>
                                <input className="form-control" required name="name" type="text" defaultValue={props?.data?.name} />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Phone<em className="text-danger">*</em></label>
                                <input className="form-control" required name="phone" type="text" defaultValue={props?.data?.phone} />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Email<em className="text-danger">*</em></label>
                                <input className="form-control" required name="email" type="email" defaultValue={props?.data?.email} />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Card Number<em className="text-danger">*</em></label>
                                <input className="form-control" required name="card_number" type="text" defaultValue={props?.data?.card_number} />
                            </div>
                            <div class="form-group filled">
                                <label class="control-label">Status <em class="text-danger">*</em></label>
                                <select class="form-control" name="status" required>
                                   
                                    <option value="0" selected={0 == props?.data?.status ? true : false} >Inactive</option>
                                    <option value="1" selected={1 == props?.data?.status ? true : false} >Active</option></select>
                            </div>
                            <ModalFooter className={'post-footer-comment-icon bg-light'}>

                                <button type="submit" className="btn btn-success rounded-pill"><i class="fa-light fa-save me-1"></i> Save{loading ? (
                                    <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                ) : (
                                    <span></span>
                                )}</button>

                            </ModalFooter>
                        </form>
                    </div>

                </ModalBody>
            </Modal>
        </>
    )
}