import { useEffect } from "react";
import API from "../../Network/API";
import { toast } from "react-hot-toast";
import { useState } from "react";

export default function NotificationSetting() {
    const [loading, setLoading] = useState(false);
    const [lock, setLock] = useState();
    const [rms, setRms] = useState();
    
    useEffect(() => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/notification_configuration').then((response) => {
              if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                  loaderElement.setAttribute('hidden', 'true');
                }
              }

            setLock(response.data.data.lock)
            setRms(response.data.data.lock.rms_notification_config)
          
        }).catch((error) => {

        });
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        const fd = new FormData();

        fd.append(`door_unlock_email`, event.target.door_unlock_email.checked ? 1 : 0)
        fd.append(`door_unlock_sms`, event.target.door_unlock_sms.checked ? 1 : 0)
        fd.append(`door_unlock_web`, event.target.door_unlock_web.checked ? 1 : 0)
        fd.append(`door_close_email`, event.target.door_close_email.checked ? 1 : 0)
        fd.append(`door_close_sms`, event.target.door_close_sms.checked ? 1 : 0)
        fd.append(`door_close_web`, event.target.door_close_web.checked ? 1 : 0)
        fd.append(`lock_close_email`, event.target.lock_close_email.checked ? 1 : 0)
        fd.append(`lock_close_sms`, event.target.lock_close_sms.checked ? 1 : 0)
        fd.append(`lock_close_web`, event.target.lock_close_web.checked ? 1 : 0)
        fd.append(`lock_fault_email`, event.target.lock_fault_email.checked ? 1 : 0)
        fd.append(`lock_fault_sms`, event.target.lock_fault_sms.checked ? 1 : 0)
        fd.append(`lock_fault_web`, event.target.lock_fault_web.checked ? 1 : 0)
        fd.append(`security_breach_email`, event.target.security_breach_email.checked ? 1 : 0)
        fd.append(`security_breach_sms`, event.target.security_breach_sms.checked ? 1 : 0)
        fd.append(`security_breach_web`, event.target.security_breach_web.checked ? 1 : 0)
        fd.append(`door_open_time_out_email`, event.target.door_open_time_out_email.checked ? 1 : 0)
        fd.append(`door_open_time_out_sms`, event.target.door_open_time_out_sms.checked ? 1 : 0)
        fd.append(`door_open_time_out_web`, event.target.door_open_time_out_web.checked ? 1 : 0)
        fd.append(`vibration_email`, event.target.vibration_email.checked ? 1 : 0)
        fd.append(`vibration_sms`, event.target.vibration_sms.checked ? 1 : 0)
        fd.append(`vibration_web`, event.target.vibration_web.checked ? 1 : 0)
        fd.append(`card_temper_email`, event.target.card_temper_email.checked ? 1 : 0)
        fd.append(`card_temper_sms`, event.target.card_temper_sms.checked ? 1 : 0)
        fd.append(`card_temper_web`, event.target.card_temper_web.checked ? 1 : 0)
        fd.append(`reader_fault_email`, event.target.reader_fault_email.checked ? 1 : 0)
        fd.append(`reader_fault_sms`, event.target.reader_fault_sms.checked ? 1 : 0)
        fd.append(`reader_fault_web`, event.target.reader_fault_web.checked ? 1 : 0)
        fd.append(`mains_fail_email`, event.target.mains_fail_email.checked ? 1 : 0)
        fd.append(`mains_fail_sms`, event.target.mains_fail_sms.checked ? 1 : 0)
        fd.append(`mains_fail_web`, event.target.mains_fail_web.checked ? 1 : 0)
        fd.append(`mdb_fail_email`, event.target.mdb_fail_email.checked ? 1 : 0)
        fd.append(`mdb_fail_sms`, event.target.mdb_fail_sms.checked ? 1 : 0)
        fd.append(`mdb_fail_web`, event.target.mdb_fail_web.checked ? 1 : 0)
        fd.append(`dc_low_email`, event.target.dc_low_email.checked ? 1 : 0)
        fd.append(`dc_low_sms`, event.target.dc_low_sms.checked ? 1 : 0)
        fd.append(`dc_low_web`, event.target.dc_low_web.checked ? 1 : 0)
        fd.append(`smoke_email`, event.target.smoke_email.checked ? 1 : 0)
        fd.append(`smoke_sms`, event.target.smoke_sms.checked ? 1 : 0)
        fd.append(`smoke_web`, event.target.smoke_web.checked ? 1 : 0)
        fd.append(`dc_fan_email`, event.target.dc_fan_email.checked ? 1 : 0)
        fd.append(`dc_fan_sms`, event.target.dc_fan_sms.checked ? 1 : 0)
        fd.append(`dc_fan_web`, event.target.dc_fan_web.checked ? 1 : 0)
        fd.append(`water_leakage_email`, event.target.water_leakage_email.checked ? 1 : 0)
        fd.append(`water_leakage_sms`, event.target.water_leakage_sms.checked ? 1 : 0)
        fd.append(`water_leakage_web`, event.target.water_leakage_web.checked ? 1 : 0)
        fd.append(`rectifier_email`, event.target.rectifier_email.checked ? 1 : 0)
        fd.append(`rectifier_sms`, event.target.rectifier_sms.checked ? 1 : 0)
        fd.append(`rectifier_web`, event.target.rectifier_web.checked ? 1 : 0)
        fd.append(`aviation_email`, event.target.aviation_email.checked ? 1 : 0)
        fd.append(`aviation_sms`, event.target.aviation_sms.checked ? 1 : 0)
        fd.append(`aviation_web`, event.target.aviation_web.checked ? 1 : 0)
        fd.append(`critical_disconnect_email`, event.target.critical_disconnect_email.checked ? 1 : 0)
        fd.append(`critical_disconnect_sms`, event.target.critical_disconnect_sms.checked ? 1 : 0)
        fd.append(`critical_disconnect_web`, event.target.critical_disconnect_web.checked ? 1 : 0)
        fd.append(`overload_email`, event.target.overload_email.checked ? 1 : 0)
        fd.append(`overload_sms`, event.target.overload_sms.checked ? 1 : 0)
        fd.append(`overload_web`, event.target.overload_web.checked ? 1 : 0)
        fd.append(`load_disconnect_email`, event.target.load_disconnect_email.checked ? 1 : 0)
        fd.append(`load_disconnect_sms`, event.target.load_disconnect_sms.checked ? 1 : 0)
        fd.append(`load_disconnect_web`, event.target.load_disconnect_web.checked ? 1 : 0)
        fd.append(`batt_load_fuse_email`, event.target.batt_load_fuse_email.checked ? 1 : 0)
        fd.append(`batt_load_fuse_sms`, event.target.batt_load_fuse_sms.checked ? 1 : 0)
        fd.append(`batt_load_fuse_web`, event.target.batt_load_fuse_web.checked ? 1 : 0)
        fd.append(`temperature_fail_email`, event.target.temperature_fail_email.checked ? 1 : 0)
        fd.append(`temperature_fail_sms`, event.target.temperature_fail_sms.checked ? 1 : 0)
        fd.append(`temperature_fail_web`, event.target.temperature_fail_web.checked ? 1 : 0)
        fd.append(`llvd_fail_email`, event.target.llvd_fail_email.checked ? 1 : 0)
        fd.append(`llvd_fail_sms`, event.target.llvd_fail_sms.checked ? 1 : 0)
        fd.append(`llvd_fail_web`, event.target.llvd_fail_web.checked ? 1 : 0)
        fd.append(`phase_fail_email`, event.target.phase_fail_email.checked ? 1 : 0)
        fd.append(`phase_fail_sms`, event.target.phase_fail_sms.checked ? 1 : 0)
        fd.append(`phase_fail_web`, event.target.phase_fail_web.checked ? 1 : 0)


        API.post('/v1/oauth/save_notification_configuration', fd)
            .then(response => {
                setLoading(false);
                toast.success(response.data.message);
            }).catch((error) => {
                setLoading(false);
                toast.error(error.response.data.message)
            });
    }

    return (
        <>
            <div className="row mt-2">
                <div className="col-xl-10 col-lg-10 offset-md-1">
                    <form method="post" acceptCharset="UTF-8" id="myForm" onSubmit={handleSubmit}>

                        <div className="d-flex justify-content-between mb-2">
                            <h4 />
                            <button type="submit" className="btn btn-info waves-effect waves-light">Save Notification Settings</button>
                        </div>
                        <div className="card-box">
                            <table className="mt-2 ncustom-tble" width="100%" border={0} cellSpacing={0} cellPadding={0}>
                                <tbody><tr>
                                    <th width="30%">ACS Alarm Notifications</th>
                                    <th align="center" className="td-center">Email</th>
                                    <th align="center" className="td-center">SMS</th>
                                    <th align="center" className="td-center">Web</th>
                                    <th width="50%" />
                                </tr>
                                    <tr>
                                        <td>Door Unlock Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {parseInt(lock?.door_unlock_alert_email_notify) === 1?
                                                <input defaultChecked type="checkbox" id="door_unlock_email" name="door_unlock_email" />
                                                :
                                                <input  type="checkbox" id="door_unlock_email" name="door_unlock_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                                {parseInt(lock?.door_unlock_alert_sms_notify) === 1 ?
                                                    <input defaultChecked type="checkbox" id="door_unlock_sms" name="door_unlock_sms" />
                                                    :
                                                    <input type="checkbox" id="door_unlock_sms" name="door_unlock_sms" />

                                                }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                                {lock?.door_unlock_alert_web_notify == 1 ?
                                                    <input defaultChecked type="checkbox" id="door_unlock_web" name="door_unlock_web" />
                                                    :
                                                    <input type="checkbox" id="door_unlock_web" name="door_unlock_web" />
                                                }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Door Close Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                                {lock?.door_close_alert_email_notify ===1?
                                                <input defaultChecked type="checkbox" id="door_close_email" name="door_close_email" />
                                                :
                                                <input  type="checkbox" id="door_close_email" name="door_close_email" />
                                                }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                                {lock?.door_close_alert_sms_notify ===1 ?
                                                <input defaultChecked type="checkbox" id="door_close_sms" name="door_close_sms" />
                                                :
                                                <input  type="checkbox" id="door_close_sms" name="door_close_sms" />
                                                }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.door_close_alert_web_notify ==1 ?
                                                <input defaultChecked type="checkbox" id="door_close_web" name="door_close_web" />
                                              :
                                                <input  type="checkbox" id="door_close_web" name="door_close_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Lock Close Fail Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.lc_fail_alert_email_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="lock_close_email" name="lock_close_email" />
                                               :
                                                <input type="checkbox" id="lock_close_email" name="lock_close_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.lc_fail_alert_sms_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="lock_close_sms" name="lock_close_sms" />
                                               :
                                                <input type="checkbox" id="lock_close_sms" name="lock_close_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.lc_fail_alert_web_notify === 1 ?
                                                <input type="checkbox" defaultChecked id="lock_close_web" name="lock_close_web" />
                                                :
                                                <input type="checkbox"  id="lock_close_web" name="lock_close_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Lock Fault Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.lc_fault_alert_email_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="lock_fault_email" name="lock_fault_email" />
                                                :
                                                <input type="checkbox" id="lock_fault_email" name="lock_fault_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.lc_fault_alert_sms_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="lock_fault_sms" name="lock_fault_sms" />
                                                :
                                                <input type="checkbox" id="lock_fault_sms" name="lock_fault_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.lc_fault_alert_web_notify === 1 ?
                                                <input type="checkbox" defaultChecked id="lock_fault_web" name="lock_fault_web" />
                                                :
                                                <input type="checkbox"  id="lock_fault_web" name="lock_fault_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Security Breach Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.sb_alert_email_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="security_breach_email" name="security_breach_email" />
                                                :
                                                <input type="checkbox" id="security_breach_email" name="security_breach_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.sb_alert_sms_notify === 1 ?
                                                <input type="checkbox" id="security_breach_sms" name="security_breach_sms" />
                                                :
                                                <input type="checkbox" id="security_breach_sms" name="security_breach_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.sb_alert_web_notify === 1 ?
                                                <input type="checkbox" defaultChecked id="security_breach_web" name="security_breach_web" />
                                               :
                                                <input type="checkbox"  id="security_breach_web" name="security_breach_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Door Open Timeout Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.door_open_timeout_email_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="door_open_time_out_email" name="door_open_time_out_email" />
                                               : 
                                                <input type="checkbox" id="door_open_time_out_email" name="door_open_time_out_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.door_open_timeout_sms_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="door_open_time_out_sms" name="door_open_time_out_sms" />
                                                :
                                                <input type="checkbox" id="door_open_time_out_sms" name="door_open_time_out_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.door_open_timeout_web_notify === 1 ?
                                                <input type="checkbox" defaultChecked id="door_open_time_out_web" name="door_open_time_out_web" />
                                                :
                                                <input type="checkbox"  id="door_open_time_out_web" name="door_open_time_out_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Vibration Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.vibration_email_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="vibration_email" name="vibration_email" />
                                                :
                                                <input type="checkbox" id="vibration_email" name="vibration_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.vibration_sms_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="vibration_sms" name="vibration_sms" />
                                                :
                                                <input type="checkbox" id="vibration_sms" name="vibration_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.vibration_web_notify === 1 ?
                                                <input defaultChecked type="checkbox"  id="vibration_web" name="vibration_web" />
                                                :
                                                <input type="checkbox"  id="vibration_web" name="vibration_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Card Temper Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.card_tamper_email_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="card_temper_email" name="card_temper_email" />
                                                :
                                                <input type="checkbox" id="card_temper_email" name="card_temper_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.card_tamper_sms_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="card_temper_sms" name="card_temper_sms" />
                                                :
                                                <input type="checkbox" id="card_temper_sms" name="card_temper_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.card_tamper_web_notify === 1 ?
                                                <input type="checkbox" defaultChecked id="card_temper_web" name="card_temper_web" />
                                                :
                                                <input type="checkbox"  id="card_temper_web" name="card_temper_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Reader Fault Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.reader_fault_email_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="reader_fault_email" name="reader_fault_email" />
                                               :
                                                <input type="checkbox" id="reader_fault_email" name="reader_fault_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.reader_fault_sms_notify === 1 ?
                                                <input defaultChecked type="checkbox" id="reader_fault_sms" name="reader_fault_sms" />
                                                :
                                                <input type="checkbox" id="reader_fault_sms" name="reader_fault_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {lock?.reader_fault_web_notify === 1 ?
                                                <input type="checkbox" defaultChecked id="reader_fault_web" name="reader_fault_web" />
                                                :
                                                <input type="checkbox"  id="reader_fault_web" name="reader_fault_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                </tbody></table>
                        </div>
                        <div className="card-box">
                            <table className="mt-2 ncustom-tble" width="100%" border={0} cellSpacing={0} cellPadding={0}>
                                <tbody><tr>
                                    <th width="30%">RMS Alarm Notifications</th>
                                    <th align="center" className="td-center">Email</th>
                                    <th align="center" className="td-center">SMS</th>
                                    <th align="center" className="td-center">Web</th>
                                    <th width="50%" />
                                </tr>
                                    <tr>
                                        <td>Mains Fail Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.mains_fail_email === 1 ?
                                                <input defaultChecked type="checkbox" id="mains_fail_email" name="mains_fail_email" />
                                                :
                                                <input type="checkbox" id="mains_fail_email" name="mains_fail_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.mains_fail_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="mains_fail_sms" name="mains_fail_sms" />
                                                :
                                                <input type="checkbox" id="mains_fail_sms" name="mains_fail_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.mains_fail_web === 1 ?
                                                <input defaultChecked type="checkbox" id="mains_fail_web" name="mains_fail_web" />
                                                :
                                                <input type="checkbox" id="mains_fail_web" name="mains_fail_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>MDB Module Fault Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.mdb_fail_email === 1 ?
                                                <input defaultChecked type="checkbox" id="mdb_fail_email" name="mdb_fail_email" />
                                                :
                                                <input type="checkbox" id="mdb_fail_email" name="mdb_fail_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.mdb_fail_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="mdb_fail_sms" name="mdb_fail_sms" />
                                               :
                                                <input type="checkbox" id="mdb_fail_sms" name="mdb_fail_sms" />
                                            }
                                            <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.mdb_fail_web === 1 ?
                                                <input defaultChecked type="checkbox" id="mdb_fail_web" name="mdb_fail_web" />
                                                :
                                                <input type="checkbox" id="mdb_fail_web" name="mdb_fail_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>DC Low Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.dc_low_email === 1 ?
                                                <input defaultChecked type="checkbox" id="dc_low_email" name="dc_low_email" />
                                                :
                                                <input type="checkbox" id="dc_low_email" name="dc_low_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.dc_low_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="dc_low_sms" name="dc_low_sms" />
                                                :
                                                <input type="checkbox" id="dc_low_sms" name="dc_low_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.dc_low_web === 1 ?
                                                <input defaultChecked type="checkbox" id="dc_low_web" name="dc_low_web" />
                                                :
                                                <input type="checkbox" id="dc_low_web" name="dc_low_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Smoke Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.smoke_email === 1 ?
                                                <input defaultChecked type="checkbox" id="smoke_email" name="smoke_email" />
                                                :
                                                <input type="checkbox" id="smoke_email" name="smoke_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.smoke_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="smoke_sms" name="smoke_sms" />
                                               :
                                                <input type="checkbox" id="smoke_sms" name="smoke_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.smoke_web === 1 ?
                                                <input defaultChecked type="checkbox" id="smoke_web" name="smoke_web" />
                                                :
                                                <input type="checkbox" id="smoke_web" name="smoke_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>DC Fan Fault Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.dc_fan_email === 1 ?
                                                <input defaultChecked type="checkbox" id="dc_fan_email" name="dc_fan_email" />
                                               :
                                                <input type="checkbox" id="dc_fan_email" name="dc_fan_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.dc_fan_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="dc_fan_sms" name="dc_fan_sms" />
                                               :
                                                <input type="checkbox" id="dc_fan_sms" name="dc_fan_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.dc_fan_web === 1 ?
                                                <input defaultChecked type="checkbox" id="dc_fan_web" name="dc_fan_web" />
                                                :
                                                <input  type="checkbox" id="dc_fan_web" name="dc_fan_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Water Leakage Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.water_leakage_email === 1 ?
                                                <input defaultChecked type="checkbox" id="water_leakage_email" name="water_leakage_email" />
                                                :
                                                <input type="checkbox" id="water_leakage_email" name="water_leakage_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.water_leakage_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="water_leakage_sms" name="water_leakage_sms" />
                                                :
                                                <input type="checkbox" id="water_leakage_sms" name="water_leakage_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.water_leakage_web === 1 ?
                                                <input defaultChecked type="checkbox" id="water_leakage_web" name="water_leakage_web" />
                                               :
                                                <input type="checkbox" id="water_leakage_web" name="water_leakage_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Rectifier Module Fault Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.rectifier_email === 1 ?
                                                <input defaultChecked type="checkbox" id="rectifier_email" name="rectifier_email" />
                                                :
                                                <input type="checkbox" id="rectifier_email" name="rectifier_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.rectifier_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="rectifier_sms" name="rectifier_sms" />
                                                :
                                                <input type="checkbox" id="rectifier_sms" name="rectifier_sms" />
                                            } 
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.rectifier_web === 1 ?
                                                <input defaultChecked type="checkbox" id="rectifier_web" name="rectifier_web" />
                                               :
                                                <input type="checkbox" id="rectifier_web" name="rectifier_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Aviation Light Fault Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.aviation_email === 1 ?
                                                <input defaultChecked type="checkbox" id="aviation_email" name="aviation_email" />
                                               :
                                                <input type="checkbox" id="aviation_email" name="aviation_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.aviation_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="aviation_sms" name="aviation_sms" />
                                                :
                                                <input type="checkbox" id="aviation_sms" name="aviation_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.aviation_web === 1 ?
                                                <input defaultChecked type="checkbox" id="aviation_web" name="aviation_web" />
                                                :
                                                <input type="checkbox" id="aviation_web" name="aviation_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Critical Disconnect Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.critical_disconnect_email === 1 ?
                                                <input defaultChecked type="checkbox" id="critical_disconnect_email" name="critical_disconnect_email" />
                                                :
                                                <input type="checkbox" id="critical_disconnect_email" name="critical_disconnect_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.critical_disconnect_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="critical_disconnect_sms" name="critical_disconnect_sms" />
                                                :
                                                <input type="checkbox" id="critical_disconnect_sms" name="critical_disconnect_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.critical_disconnect_web === 1 ?
                                                <input defaultChecked type="checkbox" id="critical_disconnect_web" name="critical_disconnect_web" />
                                                :
                                                <input type="checkbox" id="critical_disconnect_web" name="critical_disconnect_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Overload Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.overload_email === 1 ?
                                                <input defaultChecked type="checkbox" id="overload_email" name="overload_email" />
                                                :
                                                <input type="checkbox" id="overload_email" name="overload_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.overload_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="overload_sms" name="overload_sms" />
                                                :
                                                <input type="checkbox" id="overload_sms" name="overload_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.overload_web === 1 ?
                                                <input defaultChecked type="checkbox" id="overload_web" name="overload_web" />
                                                :
                                                <input type="checkbox" id="overload_web" name="overload_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Load Disconnect Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.load_disconnect_email === 1 ?
                                                <input defaultChecked type="checkbox" id="load_disconnect_email" name="load_disconnect_email" />
                                                :
                                                <input type="checkbox" id="load_disconnect_email" name="load_disconnect_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.load_disconnect_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="load_disconnect_sms" name="load_disconnect_sms" />
                                                :
                                                <input type="checkbox" id="load_disconnect_sms" name="load_disconnect_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.load_disconnect_web === 1 ?
                                                <input defaultChecked type="checkbox" id="load_disconnect_web" name="load_disconnect_web" />
                                                :
                                                <input type="checkbox" id="load_disconnect_web" name="load_disconnect_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Batt/Load Fuse Falt Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.batt_load_fuse_email === 1 ?
                                                <input defaultChecked type="checkbox" id="batt_load_fuse_email" name="batt_load_fuse_email" />
                                                :
                                                <input type="checkbox" id="batt_load_fuse_email" name="batt_load_fuse_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.batt_load_fuse_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="batt_load_fuse_sms" name="batt_load_fuse_sms" />
                                                :
                                                <input type="checkbox" id="batt_load_fuse_sms" name="batt_load_fuse_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.batt_load_fuse_web === 1 ?
                                                <input defaultChecked type="checkbox" id="batt_load_fuse_web" name="batt_load_fuse_web" />
                                                :
                                                <input type="checkbox" id="batt_load_fuse_web" name="batt_load_fuse_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Temperature High Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.temperature_fail_email === 1 ?
                                                <input defaultChecked type="checkbox" id="temperature_fail_email" name="temperature_fail_email" />
                                              :
                                                <input type="checkbox" id="temperature_fail_email" name="temperature_fail_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.temperature_fail_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="temperature_fail_sms" name="temperature_fail_sms" />
                                                :
                                                <input type="checkbox" id="temperature_fail_sms" name="temperature_fail_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.temperature_fail_web === 1 ?
                                                <input defaultChecked type="checkbox" id="temperature_fail_web" name="temperature_fail_web" />
                                                :
                                                <input type="checkbox" id="temperature_fail_web" name="temperature_fail_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>LLVD Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.llvd_fail_email === 1 ?
                                                <input defaultChecked type="checkbox" id="llvd_fail_email" name="llvd_fail_email" />
                                               :
                                                <input type="checkbox" id="llvd_fail_email" name="llvd_fail_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.llvd_fail_sms === 1 ?
                                                <input defaultChecked type="checkbox" id="llvd_fail_sms" name="llvd_fail_sms" />
                                               :
                                                <input type="checkbox" id="llvd_fail_sms" name="llvd_fail_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.llvd_fail_web === 1 ?
                                                <input defaultChecked type="checkbox" id="llvd_fail_web" name="llvd_fail_web" />
                                                :
                                                <input type="checkbox" id="llvd_fail_web" name="llvd_fail_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Phase Missing Alert </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.phase_fail_email === 1 ?
                                                <input type="checkbox" id="phase_fail_email" name="phase_fail_email" />
                                                :
                                                <input type="checkbox" id="phase_fail_email" name="phase_fail_email" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.phase_fail_sms === 1 ?
                                                <input type="checkbox" id="phase_fail_sms" name="phase_fail_sms" />
                                                :
                                                <input type="checkbox" id="phase_fail_sms" name="phase_fail_sms" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td className="td-center">
                                            <label className="switch">
                                            {rms?.phase_fail_web === 1 ?
                                                <input type="checkbox" id="phase_fail_web" name="phase_fail_web" />
                                                :
                                                <input type="checkbox" id="phase_fail_web" name="phase_fail_web" />
                                            }
                                                <span className="slider round" />
                                            </label>
                                        </td>
                                        <td>
                                            <p>If true,You will get email,sms &amp; web alert notification.</p>
                                        </td>
                                    </tr>
                                </tbody></table>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}